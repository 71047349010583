import React from "react";
import { AgentApplicantsViewsStyles } from "../AgentApplicantsView.styles";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
} from "@material-ui/core";
import FormControl from "../../../molecules/FormControl/FormControl";
import { CloseIcon } from "../../../atoms/SvgIcons/SvgIcons";
import { SearchOutlined } from "@material-ui/icons";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";

const AgentSearchBox = (props) => {
  const {setSearchedApplicant} = props
  const { t } = useTranslation("AgentLeadPageTrans");
  const classes = AgentApplicantsViewsStyles();
  const formikRef = React.useRef();
  const [value, setValue] = React.useState('');

  const initialValues = {
    searchApplicant: "",
  };

  const onSubmit = (values, { setSubmitting }) => {
    console.log(values)
    setSubmitting(false);
    setSearchedApplicant(values.searchApplicant)
  };

  const debounceFn = React.useMemo(() => debounce(handleDebounce, 500), []);

  function handleDebounce(inputValue) {
    setSearchedApplicant(inputValue)
  };

  function handleChange(event) {
    setValue(event.target.value);
    debounceFn(event.target.value);
  };

  function handleClear(val) {
    setValue(val);
    debounceFn(val);
  };


  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      innerRef={formikRef}
    >
      {({ submitForm, values }) => (
        <Grid container component={Paper} alignItems="center" spacing={2} style={{margin:0}} >
          <Grid item className={classes.searchBoxCol}>
            <FormControl
              control="input"
              variant="outlined"
              placeholder="mobile no. / application no."
              name="searchApplicant"
              type="text"
              value={value}
              onChange={handleChange}
              id="searchApplicant"
              className={`${classes.filterInputBox} searchBox`}
              margin="dense"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {
                      value ? (
                        <IconButton
                          edge="end"
                          size="small"
                          title="clear search"
                          onClick={() => handleClear('')}
                        >
                          <CloseIcon />
                        </IconButton>
                      ) : (
                        <IconButton disabled edge="end" size="small">
                          <SearchOutlined />
                        </IconButton>
                      )
                    }
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          {/* <Grid item sm={"auto"} xs={12}>
            <Button
              size="small"
              variant="contained"
              color="primary"
              type="submit"
              onClick={submitForm}
            >
              {t("Search")}
            </Button>
          </Grid> */}
        </Grid>
      )}
    </Formik>
  );
};

export default AgentSearchBox;
