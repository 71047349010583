import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import withWidth from "@material-ui/core/withWidth";
import Typography from "@material-ui/core/Typography";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Switch from '@material-ui/core/Switch';
import { AgentAnlyDashboardStyles } from "../AgentAnlyDashboard.styles";
import ApplicationsCountCard from "../ApplicationsCountCard/ApplicationsCountCard";
import ClearIcon from '@material-ui/icons/Clear';
// import ReservationCountCard from "../ReservationCountCard/ReservationCountCard";
// import ProgressChartCard from "../ProgressChartCard/ProgressChartCard";
// import ProjectOverviewCard from "../ProjectOverviewCard/ProjectOverviewCard";
// import RecentActivityCard from "../RecentActivityCard/RecentActivityCard";
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { useSelector, useDispatch } from "react-redux";
import { agentApplicantsSelector, getAgentApplicantsAnalDashboard } from "../../../../redux/features/agent/AgentAnalDashboardSlice";
import moment from "moment";
import Loading from "../../../atoms/Loading/Loading";
import AlertBox from "../../../atoms/AlertBox/AlertBox";
import { Button, Checkbox, Chip, FormControl, IconButton, Input, InputAdornment, InputLabel, ListItemText, MenuItem, Select, Slide, Snackbar, useTheme } from "@material-ui/core";
import { GetApp, Refresh } from "@material-ui/icons";
import { ApiEndPoint } from "../../../../utils/Common";
import { agentProfileSelector, clearGetAgentData, getAgentList, getRolesForDropdown } from "../../../../redux/features/agent/AgentProfileSlice";
import ApplicantAnalyticsTable from "../ApplicantAnalyticsTable/ApplicantAnalyticsTable";
import AgentAnalyticsTable from "../AgentAnalyticsTable/AgentAnalyticsTable";
import { Alert } from "@material-ui/lab";

function TransitionLeft(props) {
  return <Slide {...props} direction="right" />;
}


const ApplicantsAnalyticsDashboard = (props) => {
  const { t } = useTranslation("AnalyDashboardPageTrans");
  const dispatch = useDispatch();
  const {
    isFetchingAgentApplicantsAnalDashboard,
    isSuccessResAgentApplicantsAnalDashboard,
    isErrorAgentApplicantsAnalDashboard,
    errorMsgAgentApplicantsAnalDashboard,
    agentApplicantsAnalDashboardData,
  } = useSelector(agentApplicantsSelector);
  const {
    isFetchingAgentList,
    isFetchingAgentProfile,
    agentListData,
    agentProfileData,
    isSuccessResAgentProfile,
    isFetchingRoles,
    isSuccessRoles,
    isErrorRoles,
    errorMsgRoles,
    rolesData,
  } = useSelector(agentProfileSelector)
  // const rolesData = [{ value: "CM", label: "Cluster Manager", count: '6' }, { value: "ASM", label: "Sales Manager", count: '8' }, { value: "Agent", label: "Customer Relationship Executive", count: '24' }]

  const classes = AgentAnlyDashboardStyles();
  const [selectedPeriod, setSelectedPeriod] = useState("all");
  const [avtiveSchemeIs, setAvtiveSchemeIs] = useState(false);
  const [selectedFromDate, setSelectedFromDate] = useState("");
  const [selectedToDate, setSelectedToDate] = useState("");
  const [selectedProject, setSelectedProject] = useState("");
  const [pdfLoading, setPdfLoading] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [ReportsNotFound, setReportsNotFound] = React.useState(false);
  const [roles, setRoles] = useState("");
  const [roleList, setRoleList] = React.useState([]);
  const [selectAllRole, setSelectAllRole] = React.useState(true);
  const [personName, setPersonName] = useState([]);
  const [agentList, setAgentList] = React.useState([]);
  const [selectAllAgent, setSelectAllAgent] = React.useState(true);

  const handleClickDisabled = () => {
    if (roles === "") {
      setOpenSnackbar(true);
    }
  };

  useEffect(() => {
    if (isSuccessResAgentProfile) {
      dispatch(getRolesForDropdown(agentProfileData?.AgentDetails[0]?.source));
    }
    return () => {
      dispatch(clearGetAgentData())
    }
  }, [isSuccessResAgentProfile]);

  useEffect(() => {
    if (roles && isSuccessResAgentProfile) {
      const requestData = {
        roles: roles,
        source: agentProfileData?.AgentDetails[0]?.source
      }
      dispatch(getAgentList(requestData));
    }
  }, [roles, isSuccessResAgentProfile]);

  useEffect(() => {
    if (isSuccessRoles && rolesData?.length > 0) {
      setRoleList([...rolesData])
    }
  }, [isSuccessRoles])

  useEffect(() => {
    if (agentListData?.length > 0) {
      setAgentList([{ Agentcode: "All", FirstName: "Select All" }, ...agentListData])
    }
  }, [agentListData])

  const handleChange = (event) => {

    const value = event.target.value;

    if (value.includes('All')) {

      if (selectAllAgent) {
        setPersonName([])
        setSelectAllAgent(false);
      } else {
        handleSelectAll()
        setSelectAllAgent(true);
      }

    } else {
      setPersonName(value.filter(option => option !== 'All'));
      setSelectAllAgent(false);
    }
  };

  const handleSelectAll = () => {
    const allValues = agentList
      .filter((option) => option.Agentcode !== 'All')
      .map((option) => option.Agentcode);
    setPersonName(allValues);
  };

  const handleChangeRole = (event) => {
    const value = event.target.value;
    setRoles(value);
    setSelectAllAgent(true)
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 350,
      },
    },
  };

  const handleFromDateChange = (date) => {
    setSelectedPeriod("");
    setSelectedFromDate(date);
  };
  const handleToDateChange = (date) => {
    setSelectedPeriod("");
    setSelectedToDate(date);
  };

  const downloadApplicantReport = () => {

    let start_date = moment(selectedFromDate).format("YYYY-MM-DD");
    let end_date = moment(selectedToDate).format("YYYY-MM-DD");
    let params = "";

    if (start_date != "Invalid date" && end_date != "Invalid date") {
      params += "?start_date=" + start_date + "&end_date=" + end_date;
    }
    setPdfLoading(true);
    let fileUrl = `${ApiEndPoint}/AgentApplicants/GetApplicantsRecord/${localStorage.getItem('agentcode')}${params}`;
    fetch(fileUrl, {
      method: "GET",
      headers: {
        Authorization: localStorage.getItem("agentjwtToken"),
      },
    }).then((response) => response.blob()).then((blob) => {
      setPdfLoading(false);
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'ApplicantsRecord.csv';
      document.body.append(link);
      link.click();
      link.remove();
      // in case the Blob uses a lot of memory
      setTimeout(() => URL.revokeObjectURL(link.href), 300);
    }).catch(function (error) {
      setPdfLoading(false);
      alert("Applicants Record not found");
    });
  };

  const download = () => {
    if (agentApplicantsAnalDashboardData.applicantWiseData?.length === 0) {
      setReportsNotFound(true)
    } else {

      let filename = "report.csv"
      // const csv = convertJSONtoCSV(agentApplicantsAnalDashboardData?.agentWiseData)
      const csv = convertJSONtoCSV(agentApplicantsAnalDashboardData?.applicantWiseData)
      const blob = new Blob([csv], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }
  }

  function convertJSONtoCSV(data) {
    const headers = Object.keys(data[0]);
    const csv = [headers.join(',')];

    data.forEach((item) => {
      const values = headers.map((header) => item[header]);
      csv.push(values.join(','));
    });

    return csv.join('\n');
  }


  useEffect(() => {
    // let params = "time_range=date_range&from_date=2021-08-09&to_date=2021-08-10";
    let params = "time_range=";
    let start_date = moment(selectedFromDate).format("YYYY-MM-DD");
    let end_date = moment(selectedToDate).format("YYYY-MM-DD");
    if (selectedPeriod === "all") {
      params += "";
    } else if (selectedPeriod === "this_year") {
      params += "this_year";
    } else if (selectedPeriod === "last_week") {
      params += "last_week";
    } else if (selectedPeriod === "last_2_week") {
      params += "last_2_week";
    } else if (selectedPeriod === "last_month") {
      params += "last_month";
    }
    else {
      if (start_date != "Invalid date" && end_date != "Invalid date") {
        params += "date_range&start_date=" + start_date + "&end_date=" + end_date;
      }
    }
    if (selectedProject) {
      params += "&project_id=" + selectedProject;
    }
    let thisYear = new Date().getFullYear();
    params += "&project_overview_application_status=1&bargraph_year=" + thisYear;
    if (avtiveSchemeIs) {
      params += "&activescheme=0&schemeid=1";
    } else {
      params += "&activescheme=1&schemeid=false";
    }
    if (personName.length) {
      params += "&agent_ids=" + personName
    }
    if (personName.length === 0 && !roles) {
      params += "&agent_ids=" + localStorage.getItem('agentcode')
    }
    localStorage.setItem("analDashboardApiParam", params);
    dispatch(getAgentApplicantsAnalDashboard());
  }, [dispatch, t, selectedPeriod, selectedFromDate, selectedToDate, selectedProject, avtiveSchemeIs, personName]);

  React.useEffect(() => {
    if (agentList.length > 0 && selectAllAgent) {
      handleSelectAll()
    }
  }, [selectAllAgent, agentList])

  const handlePeriodChange = (event, newPeriod) => {
    if (newPeriod !== null) {
      setSelectedFromDate(null);
      setSelectedToDate(null);
      setSelectedPeriod(newPeriod);
    }
  };

  const switchHandleChange = (event) => {
    setAvtiveSchemeIs(!avtiveSchemeIs);
  };

  const clearFilter = () => {
    setAgentList([])
    setPersonName([])
    setRoles("")
    setSelectAllAgent(true)
  }

  return (
    <div className={classes.root}>
      {(isFetchingAgentApplicantsAnalDashboard || isFetchingAgentProfile) && <Loading isOpen={isFetchingAgentApplicantsAnalDashboard || isFetchingAgentList || isFetchingAgentProfile} />}
      {isErrorAgentApplicantsAnalDashboard && (
        <AlertBox severity="error">{errorMsgAgentApplicantsAnalDashboard}</AlertBox>
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
        TransitionComponent={TransitionLeft}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setOpenSnackbar(false)}
          severity="warning"
        >
          Please select a role first
        </Alert>
      </Snackbar>
      {/* <Grid container spacing={width === "xs" || width === "sm" ? 0 : 3}> */}
      <Box component={Paper} className={classes.container}>
        <Grid container alignItems="center">
          <Grid item xs>
            <Typography variant="h6" color="primary">{(personName.length === 0 && !roles) ? "My" : "Team's"} Analytics Dashboard</Typography>
            {/* <Typography><small>{t("analyticsDashboard.subTitle")}</small></Typography> */}
          </Grid>

          {/* {Object.keys(agentProfileData).length !== 0 && agentProfileData?.AgentDetails[0].UserType !== 'Agent' && agentProfileData?.AgentDetails[0].UserType !== '' && roles !== "" &&
            <IconButton color="primary" title="Clear Filter" size="small" onClick={clearFilter}>
              <ClearIcon />
            </IconButton>} */}
          {Object.keys(agentProfileData).length != 0 && agentProfileData?.AgentDetails[0].UserType !== 'Agent' && agentProfileData?.AgentDetails[0].UserType !== '' &&
            <Grid item>
              <Box>
                <FormControl className={classes.formControl} >
                  <InputLabel id="roles-label">Select Role</InputLabel>
                  <Select
                    labelId="roles"
                    id="roles"
                    // multiple
                    value={roles}
                    onChange={handleChangeRole}
                    // input={<Input />}
                    // renderValue={(selected) => {
                    //   const extractedNames = roleList
                    //     .filter(rolesData => selected.includes(rolesData.value))
                    //     .map(rolesData => rolesData.label);

                    //   return extractedNames.join(', ')
                    // }}
                    style={{ width: "180px", marginRight: 10 }}
                    color="primary"
                    endAdornment={
                      roles && (
                        <InputAdornment position="end">
                          <IconButton title='Clear Filter' onClick={clearFilter}>
                            <ClearIcon
                              color="primary"
                            />
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                  >
                    {roleList.map((role, i) => (
                      <MenuItem key={i} value={role.value}>
                        {`${role.label} ${role?.count ? `(${role?.count})` : ""}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          }
          {
            Object.keys(agentProfileData).length !== 0 && agentProfileData?.AgentDetails[0].UserType !== 'Agent' && agentProfileData?.AgentDetails[0].UserType !== '' &&
            <Grid item>
              <Box>
                <FormControl className={classes.formControl} >
                  <InputLabel id="demo-mutiple-checkbox-label">Select Account</InputLabel>
                  <Select
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    multiple
                    value={personName}
                    onChange={handleChange}
                    input={<Input />}
                    disabled={roles === ""}
                    renderValue={(selected) => {

                      const extractedNames = agentList
                        .filter(agentData => selected.includes(agentData.Agentcode))
                        .map(agentData => agentData.FirstName);

                      return extractedNames.join(', ')
                    }}
                    MenuProps={MenuProps}
                    style={{ width: "180px", marginRight: 10 }}
                    color="primary"
                    onClick={handleClickDisabled}
                  >
                    {agentList.map((agent, i) => (
                      <MenuItem key={i} value={agent.Agentcode} style={{ backgroundColor: agent.Agentcode === 'All' ? "blue" : "" }} >
                        <Checkbox color="primary" style={{ color: agent.Agentcode === 'All' ? "white" : "" }} checked={agent.Agentcode === 'All' ? selectAllAgent : personName.indexOf(agent.Agentcode) > -1} />
                        <ListItemText style={{ color: agent.Agentcode === 'All' ? "white" : "" }} primary={<span style={{ fontWeight: agent.Agentcode === 'All' ? "bold" : "" }}>{(agent.Agentcode === 'All' ? '' : ` ${agent.Agentcode} - `) + `${agent.FirstName}` + (agent.Agentcode === 'All' ? '' : ` (${agent?.TotalApplicants})`)}</span>} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          }
          <Grid item>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                id="from-date-picker-dialog"
                label={t("dateFilter.fromDateLabel")}
                format="MMM-dd-yyyy"
                value={selectedFromDate}
                onChange={handleFromDateChange}
                className={classes.datePickerCont}
                maxDate={selectedToDate || new Date()}
                invalidDateMessage=""
                InputLabelProps={{ shrink: true }}
              />
              <KeyboardDatePicker
                id="to-date-picker-dialog"
                label={t("dateFilter.toDateLabel")}
                format="MMM-dd-yyyy"
                value={selectedToDate}
                onChange={handleToDateChange}
                className={classes.datePickerCont}
                minDate={selectedFromDate}
                maxDate={new Date()}
                invalidDateMessage=""
                InputLabelProps={{ shrink: true }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item style={{ alignSelf: 'center' }}>
            <Box paddingRight={2}>
              <ToggleButtonGroup
                value={selectedPeriod}
                exclusive
                onChange={handlePeriodChange}
                aria-label="Data Period"
                className={classes.toggleBtnsContainer}
              >
                <ToggleButton value="all" aria-label="All">
                  {/* {t("toggleButtonGroup.button1")} */}
                  <Refresh />
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              endIcon={<GetApp />}
              // onClick={downloadApplicantReport}
              onClick={download}
              disabled={agentApplicantsAnalDashboardData.applicantWiseData?.length === 0}
            >
              Reports
            </Button>
            {/* <label htmlFor="activeScheme" className={classes.activeSchmTxt}>{t("analyticsDashboard.activeSchemeTxt")}</label>
            <Switch
              id="activeScheme"
              checked={avtiveSchemeIs}
              onChange={switchHandleChange}
              color="primary"
              name="avtiveSchemeIs"
              inputProps={{ 'aria-label': 'Active Scheme' }}
            /> */}
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Box paddingBottom={1} paddingTop={1}>
              {(personName.length === 0 && !roles) ?
                <Typography variant="h6" color="secondary">Your Performance</Typography>
                :
                <Typography variant="h6" color="secondary">Team Performance</Typography>}
              {/* <Grid container alignItems="center" justify="space-between">
                <Grid item>
                  <Box>
                    <ToggleButtonGroup
                      value={selectedPeriod}
                      exclusive
                      onChange={handlePeriodChange}
                      aria-label="Data Period"
                      className={classes.toggleBtnsContainer}
                    >
                      <ToggleButton value="all" aria-label="All">
                        {t("toggleButtonGroup.button1")}
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                </Grid>
                <Grid item>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      id="from-date-picker-dialog"
                      label={t("dateFilter.fromDateLabel")}
                      format="MMM-dd-yyyy"
                      value={selectedFromDate}
                      onChange={handleFromDateChange}
                      className={classes.datePickerCont}
                      maxDate={new Date()}
                      invalidDateMessage=""
                      InputLabelProps={{ shrink: true }}  
                    />
                    <KeyboardDatePicker
                      id="to-date-picker-dialog"
                      label={t("dateFilter.toDateLabel")}
                      format="MMM-dd-yyyy"
                      value={selectedToDate}
                      onChange={handleToDateChange}
                      className={classes.datePickerCont}
                      maxDate={new Date()}
                      invalidDateMessage=""
                      InputLabelProps={{ shrink: true }} 
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid> */}
            </Box>
            {isSuccessResAgentApplicantsAnalDashboard &&
              <Grid container spacing={2}>
                <Grid item sm={3}>
                  <ApplicationsCountCard cardData={{ title: t("Total Applicants"), count: agentApplicantsAnalDashboardData.total_applicants_count, cardType: "total" }} />
                </Grid>
                <Grid item sm={3}>
                  <ApplicationsCountCard cardData={{ title: t("Registration Fee Pending"), count: agentApplicantsAnalDashboardData.registration_fee_pending_count, cardType: "inProgress", detailsTitle: "Pending Registration Fee", detailsSubTitle: "Pending Registration" }} agentApplicantsAnalDashboardData={agentApplicantsAnalDashboardData} />
                </Grid>
                <Grid item sm={3}>
                  <ApplicationsCountCard cardData={{ title: t("Registration Paid Applicants"), count: agentApplicantsAnalDashboardData.registration_paid_applicants_count, cardType: "submitted", detailsTitle: "Registration Fee Paid", detailsSubTitle: "Submitted Registration" }} agentApplicantsAnalDashboardData={agentApplicantsAnalDashboardData} />
                </Grid>
                <Grid item sm={3}>
                  <ApplicationsCountCard cardData={{ title: t("Doc Verification Pending"), count: agentApplicantsAnalDashboardData.total_applicants_count - agentApplicantsAnalDashboardData.doc_verification_completed_count, cardType: "inProgress", detailsTitle: "Document Verification Pending", detailsSubTitle: "Pending Doc Verification" }} agentApplicantsAnalDashboardData={agentApplicantsAnalDashboardData} />
                </Grid>
                <Grid item sm={3}>
                  <ApplicationsCountCard cardData={{ title: t("Doc Verification Completed"), count: agentApplicantsAnalDashboardData.doc_verification_completed_count, cardType: "submitted", detailsTitle: "Document Verification Completed", detailsSubTitle: "Document Verified" }} agentApplicantsAnalDashboardData={agentApplicantsAnalDashboardData} />
                </Grid>
                <Grid item sm={3}>
                  <ApplicationsCountCard cardData={{ title: t("Total Application(s)"), count: agentApplicantsAnalDashboardData.total_applications_count, cardType: "total" }} />
                </Grid>
                <Grid item sm={3}>
                  <ApplicationsCountCard cardData={{ title: t("Completed Applications (Booking Paid)"), count: agentApplicantsAnalDashboardData.succesfull_payment_count, cardType: "total" }} />
                </Grid>
                <Grid item sm={3}>
                  <ApplicationsCountCard cardData={{ title: t("Waiting For Loan"), count: agentApplicantsAnalDashboardData.waiting_for_loan, cardType: "total" }} />
                </Grid>

              </Grid>
            }
            <Box paddingTop={2}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {/* {isSuccessResAgentApplicantsAnalDashboard && agentApplicantsAnalDashboardData.agentWiseData.length <= 0 && agentApplicantsAnalDashboardData.applicantWiseData.length == 0 &&
                    <ProgressChartCard agentApplicantsAnalDashboardData={agentApplicantsAnalDashboardData} />
                  } */}

                  {isSuccessResAgentApplicantsAnalDashboard && agentApplicantsAnalDashboardData?.agentWiseData?.length > 0 && agentProfileData?.AgentDetails[0]?.UserType !== 'Agent' && agentList?.length !== 0 &&
                    <AgentAnalyticsTable agentWiseData={agentApplicantsAnalDashboardData?.agentWiseData} />
                  }

                  {Object.keys(agentProfileData)?.length !== 0 && isSuccessResAgentApplicantsAnalDashboard && agentApplicantsAnalDashboardData?.applicantWiseData?.length > 0 && agentList?.length === 0 &&
                    < ApplicantAnalyticsTable applicantWiseData={agentApplicantsAnalDashboardData?.applicantWiseData} />
                  }
                </Grid>
                {/* <Grid item xs={12} sm={3}>
                        {isSuccessResAgentApplicantsAnalDashboard &&
                            <>
                                <Box height={'auto'}>
                                    <ApplicationsCountCard cardData={{ title: t("Doc Verification Pending"), count: agentApplicantsAnalDashboardData.doc_verification_pending_count, cardType: "inProgress", detailsTitle:"Document Verification Pending", detailsSubTitle:"Pending Doc Verification" }} agentApplicantsAnalDashboardData={agentApplicantsAnalDashboardData} />
                                </Box>
                                <Box height={170} paddingY={2}>
                                    <ApplicationsCountCard cardData={{ title: t("Total Application(s)"), count: agentApplicantsAnalDashboardData.total_applications_count, cardType: "total" }} />
                                </Box>
                                <Box height={170} paddingY={2}>
                                    <ApplicationsCountCard cardData={{ title: t("Completed Applications"), count: agentApplicantsAnalDashboardData.succesfull_payment_count, cardType: "total" }} />
                                </Box>
                            </>
                        }
                    </Grid> */}
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <Box paddingTop={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={9}>
              {/* {isSuccessResAgentApplicantsAnalDashboard &&
                <ProjectOverviewCard agentApplicantsAnalDashboardData={agentApplicantsAnalDashboardData} />
              } */}
            </Grid>
            <Grid item xs={12} sm={3}>
              {/* {isSuccessResAgentApplicantsAnalDashboard &&
                <RecentActivityCard agentApplicantsAnalDashboardData={agentApplicantsAnalDashboardData} />
              } */}
            </Grid>
          </Grid>
        </Box>
      </Box>
      {/* {ReportsNotFound && (
    )} */}
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={ReportsNotFound} autoHideDuration={6000} onClose={() => setReportsNotFound(false)}>
        <Alert severity="info" onClose={() => setReportsNotFound(false)}>Reports Not Found</Alert>
      </Snackbar>
    </div>
  );
};

export default withWidth()(ApplicantsAnalyticsDashboard);
