import React from "react";
import Layout from "../Layout/Layout";
import TermsConditions from "../../organisms/InitialPagesComponents/TermsConditions/TermsConditions";

const TermsConditionsPage = () => {
  return (
    <>
      <TermsConditions />
    </>
  );
};

export default TermsConditionsPage;
