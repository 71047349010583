

// import React, { useEffect, useState } from 'react';
// import { useFormik } from 'formik';
// import {
//     TextField,
//     FormControl,
//     InputLabel,
//     MenuItem,
//     Select,
//     Grid,
//     Typography,
//     Button,
//     FormHelperText,
// } from '@material-ui/core';
// import { FormGroup } from '@material-ui/core';
// import { useDispatch, useSelector } from 'react-redux';
// import { ModeofAssignmentDetailed, PmayNonPmaySelector } from '../../../../redux/features/pmayNonPmay/pmayNonPmaySlice';
// import { RegistrationStepperSave } from '../../../../redux/features/registration/registrationStepperSlice';
// import { clearImageUrl, fileDataUpload, fileUploadSelector } from '../../../../redux/features/file/FileUploadSlice';
// import { SupportedFormats } from '../../../../utils/Common';

// const ModeOfAssignmentForm = (props) => {
//     const { active,data } = props;
//     const dispatch = useDispatch();
//     // const [aadharCardPreview, setAadharCardPreview] = useState(null);
//     const { isFileFetching, isFileSuccess, imageUrl, isFileError, fileErrorMessage } = useSelector(fileUploadSelector);
//     const { isFetchingModeOfAssignmentDetail,
//         isSuccessModeOfAssignmentDetail } = useSelector(PmayNonPmaySelector)
//     const initialValues = {
//         // documentRegDetails: data?.documentRegDetails || '',
//         // otherDetails: data?.otherDetails || '',
//         // loanSanctionLetter:data?.loanSanctionLetter || '',

//         mode_of_assignment  - 	varchar 100
//         mode_of_assignment_other - 	varchar 255
//         loan_sanction_letter_doc - 	varchar 255
        

//     };
//    ;
//     const [filed, setFiled] = useState({});
//     const [isUploaded, setUploaded] = useState(false);
//     const [isImage, setIsImage] = useState(false);
//     const handleSubmit = (values) => {
//         // Handle form submission logic here
//         const data = {
//             ...values,
//             loanSanctionLetter: imageUrl,

//         };
//         console.log(data)
//          dispatch(ModeofAssignmentDetailed(data));
//     };

//     useEffect(() => {
//         if (isFileSuccess) {
//             setUploaded(true);
//             formik.setFieldValue("loanSanctionLetter",imageUrl)
//         }
//     }, [isFileSuccess]);

//     useEffect(() => {
//         if (isSuccessModeOfAssignmentDetail) {

//             dispatch(clearImageUrl());
//             dispatch(RegistrationStepperSave("8"))
//         }
//     }, [isSuccessModeOfAssignmentDetail]);

//     useEffect(() => {
//         if (filed.name) {
//             if (SupportedFormats.DocsFormats.includes(filed.type)) {
//                 switch (filed.type) {
//                     case "image/jpeg":
//                     case "image/png":
//                     case "application/pdf":
                       
//                         setIsImage(true);
//                         break;
//                     default:
//                         setIsImage(false);
//                 }
//                 let sendData = {
//                     doc: filed,
//                     docName: "loanSanctionLetter",
//                 };
//                 dispatch(fileDataUpload(sendData));
//             }
//         }
//     }, [filed]);




//     const formik = useFormik({
//         initialValues,
//         onSubmit: handleSubmit,
//     });

//     return (
//         <form onSubmit={formik.handleSubmit}>
//             <Grid container spacing={2}>
//                 <Grid item xs={12} md={6}>


//                     {/* <TextField
//                         fullWidth
//                         labelId="document-reg-details-label"
//                         id="documentRegDetails"
//                         name="documentRegDetails"
//                         value={formik.values.documentRegDetails}
//                         onChange={formik.handleChange}
//                         label="Document Reg. Details"
//                         error={formik.touched.documentRegDetails && Boolean(formik.errors.documentRegDetails)}
//                         margin="dense"
//                         variant="outlined"
//                     >
//                         <MenuItem value="Mortgage">Mortgage</MenuItem>
//                         <MenuItem value="Any Other">Any Other</MenuItem>
//                     </TextField> */}
//                       <FormControl fullWidth>
//             <TextField
//             select
//                 labelId="document-reg-details-label"
//                 id="documentRegDetails"
//                 name="documentRegDetails"
//                 value={formik.values.documentRegDetails}
//               onChange={formik.handleChange}
//               label="Document Reg. Details"
//                         error={formik.touched.documentRegDetails && Boolean(formik.errors.documentRegDetails)} 
            
//               margin="dense"
//               variant="outlined"
//             >
              
//               <MenuItem value="Mortgage">Mortgage</MenuItem>
//               <MenuItem value="Any Other">Any Other</MenuItem>
//             </TextField>
//           </FormControl>


//                     {formik.values.documentRegDetails === 'Any Other' && (
//                         <TextField
//                             fullWidth
//                             id="otherDetails"
//                             name="otherDetails"
//                             label="Other Details"
//                             placeholder="Other Details"
//                             variant="outlined"
//                             value={formik.values.otherDetails}
//                             onChange={formik.handleChange}
//                             margin="dense"
//                         />
//                     )}
//                 </Grid>

//                 <Grid item xs={12} md={6}>



//                     <TextField
//                         id="loanSanctionLetter"
//                         name="loanSanctionLetter"
//                         accept="image/*, application/pdf"
//                         type="file"
//                         label='Upload Loan Sanction Letter from the Bank'
//                         fullWidth
//                         variant="outlined"
//                         margin="dense"

//                         onChange={(event) => {
//                             if (event.currentTarget.files[0]) {
//                                 formik.setFieldValue(
//                                     "loanSanctionLetter",
//                                     event.currentTarget.files[0]
//                                 );
//                                 setFiled(event.currentTarget.files[0]);
//                             }
//                         }}
//                         onBlur={formik.handleBlur}
//                         InputLabelProps={{ shrink: true }}
//                     />

//                     {(isImage || data?.loanSanctionLetter) && (
//                         <img src={imageUrl ? imageUrl:formik?.values?.loanSanctionLetter} alt="Loan Sanction Letter Preview" style={{ maxWidth: '100px', marginTop: '10px' }} />
//                     )}
                  


//                 </Grid>
//             </Grid>

//             <Button type="submit" variant="contained" color="primary" style={{ marginTop: '10px' }}>
//                 Submit
//             </Button>
//         </form>
//     );
// };

// export default ModeOfAssignmentForm;


import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import {
    TextField,
    FormControl,
    MenuItem,
    Grid,
    Button,
    CircularProgress,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getSummaryDetails, ModeofAssignmentDetailed, PmayNonPmaySelector } from '../../../../redux/features/pmayNonPmay/pmayNonPmaySlice';
import { RegistrationStepperSave } from '../../../../redux/features/registration/registrationStepperSlice';
import { clearImageUrl, fileDataUpload, fileUploadSelector } from '../../../../redux/features/file/FileUploadSlice';
import { SupportedFormats } from '../../../../utils/Common';
import * as Yup from 'yup';
import AlertBox from '../../../atoms/AlertBox/AlertBox';


const ModeOfAssignmentForm = (props) => {
    const { active, data } = props;
    const dispatch = useDispatch();
    const { isFileFetching, isFileSuccess, imageUrl, isFileError, fileErrorMessage } = useSelector(fileUploadSelector);
    const { isFetchingModeOfAssignmentDetail, isSuccessModeOfAssignmentDetail, isErrorModeOfAssignmentDetail ,errorMsgModeOfAssignmentDetail} = useSelector(PmayNonPmaySelector);
  
   
    useEffect(() => {
        dispatch(getSummaryDetails());
        dispatch(clearImageUrl());
    }, [])
   
    const { summaryData, isSuccessResSummary } = useSelector(PmayNonPmaySelector);
    const [filed, setFiled] = useState({});
    const [isUploaded, setUploaded] = useState(false);
    const [isImage, setIsImage] = useState(false);
   
    const validationSchema = Yup.object({
        mode_of_assignment: Yup.string().required('Mode of Assignment is required'),
        mode_of_assignment_other: Yup.string().when('mode_of_assignment', {
            is: 'Any Other',
            then: Yup.string().required('Other Details are required'),
        }),
        loan_sanction_letter_doc: Yup.mixed().required('Loan Sanction Letter is required')
            .test('fileFormat', 'Unsupported Format', value => value && SupportedFormats.DocsFormats.includes(value.type)),
    });
   
    const defaultValues = {
        mode_of_assignment:summaryData? summaryData[0]?.mode_of_assignment : data?.mode_of_assignment|| '',
        mode_of_assignment_other:summaryData? summaryData[0]?.mode_of_assignment : data?.mode_of_assignment_other ||'',
        loan_sanction_letter_doc:summaryData ? summaryData?.DocumentDetails?.loan_sanction_letter_doc:"",
    };  
    const initialValues = defaultValues;  
    useEffect(()=>{
      if(isSuccessResSummary && summaryData)
      {
        const proApprovedPlanDocA = summaryData?.DocumentDetails?.loan_sanction_letter_doc;
      
        formik.setFieldValue( "loan_sanction_letter_doc",proApprovedPlanDocA)
       
      
         
    
      
      }
    
    },[isSuccessResSummary,summaryData])

    const handleSubmit = (values) => {
        const data = {
            ...values,
            loan_sanction_letter_doc: imageUrl,
        };
        console.log(data);
        dispatch(ModeofAssignmentDetailed(data));
    };

    useEffect(() => {
        if (isFileSuccess) {
            setUploaded(true);
            formik.setFieldValue("loan_sanction_letter_doc", imageUrl);
        }
    }, [isFileSuccess]);

    useEffect(() => {
        if (isSuccessModeOfAssignmentDetail) {
            dispatch(clearImageUrl());
            dispatch(RegistrationStepperSave("8"));
        }
    }, [isSuccessModeOfAssignmentDetail]);

    useEffect(() => {
        if (filed.name) {
            if (SupportedFormats.DocsFormats.includes(filed.type)) {
                switch (filed.type) {
                    case "image/jpeg":
                    case "image/png":
                    case "application/pdf":
                        setIsImage(true);
                        break;
                    default:
                        setIsImage(false);
                }
                let sendData = {
                    doc: filed,
                    docName: "loan_sanction_letter_doc",
                };
                dispatch(fileDataUpload(sendData));
            }
        }
    }, [filed]);

    const formik = useFormik({
        initialValues,
        onSubmit: handleSubmit,
    });

    return (
    <>

{isErrorModeOfAssignmentDetail && (
                  <AlertBox severity="error">{errorMsgModeOfAssignmentDetail}</AlertBox>
                )}
                  { isFileError && (
                  <AlertBox severity="error">{fileErrorMessage}</AlertBox>
                )}
                 {isFileError && (
        <AlertBox severity="error">{fileErrorMessage}</AlertBox>
      )}
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <TextField
                            select
                            labelId="mode-of-assignment-label"
                            id="mode_of_assignment"
                            name="mode_of_assignment"
                            value={formik.values.mode_of_assignment}
                            onChange={formik.handleChange}
                            label="Mode of Assignment"
                            error={formik.touched.mode_of_assignment && Boolean(formik.errors.mode_of_assignment)}
                            margin="dense"
                            variant="outlined"
                        >
                            <MenuItem value="Mortgage">Mortgage</MenuItem>
                            <MenuItem value="Any Other">Any Other</MenuItem>
                        </TextField>
                    </FormControl>

                    {formik.values.mode_of_assignment === 'Any Other' && (
                        <TextField
                            fullWidth
                            id="mode_of_assignment_other"
                            name="mode_of_assignment_other"
                            label="Other Details"
                            placeholder="Other Details"
                            variant="outlined"
                            value={formik.values.mode_of_assignment_other}
                            onChange={formik.handleChange}
                            margin="dense"
                        />
                    )}
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        id="loan_sanction_letter_doc"
                        name="loan_sanction_letter_doc"
                        accept="image/*, application/pdf"
                        type="file"
                        label="Upload Loan Sanction Letter from the Bank"
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        onChange={(event) => {
                            if (event.currentTarget.files[0]) {
                                formik.setFieldValue(
                                    "loan_sanction_letter_doc",
                                    event.currentTarget.files[0]
                                );
                                setFiled(event.currentTarget.files[0]);
                            }
                        }}
                        onBlur={formik.handleBlur}
                        InputLabelProps={{ shrink: true }}
                    />

                    {(isImage || summaryData?.DocumentDetails?.loan_sanction_letter_doc) && (
                        <img src={imageUrl? imageUrl :summaryData?.DocumentDetails?.loan_sanction_letter_doc ||"" } alt="Loan Sanction Letter Preview" style={{ maxWidth: '100px', marginTop: '10px' }} />
                    )}
                </Grid>
            </Grid>

            <Grid container alignItems="flex-end" style={{ marginTop: 8 }} justifyContent="flex-end">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          disabled={isFetchingModeOfAssignmentDetail}
        >
          {isFetchingModeOfAssignmentDetail && <CircularProgress size={20} style={{ marginRight: "10px" }} />}
          {isFetchingModeOfAssignmentDetail ? 'Saving...' : 'Save & Continue'}
        </Button>
        </Grid>
        </form>
        </>
    );
};

export default ModeOfAssignmentForm;
