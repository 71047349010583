// import React from "react";
// // import Header from "../../../atoms/Header/Header";
// import Container from "@material-ui/core/Container";
// import Paper from "@material-ui/core/Paper";
// import { authCardStyles } from "./AuthCard.styles";
// import { Grid } from "@material-ui/core";

// const AuthCard = (props) => {
//   const { children } = props;
//   const classes = authCardStyles();

//   return (
//     <>
//       {/* <Header /> */}
//       <div className={classes.authSectionMain}>
//         <Grid container>
//           <Grid item lg={12} xs={12} md={12}>
//             <Container maxWidth="md" className={classes.containerRoot}>
//               <Paper className={classes.authRoot}>{children}</Paper>
//             </Container>
//           </Grid>
//         </Grid>
//       </div>
//     </>
//   );
// };

// export default AuthCard;

import React from "react";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import { Box, Grid, Typography } from "@material-ui/core";
import { authCardStyles } from "./AuthCard.styles";
import Logo from '../../../../assets/Logo.svg'
import { Rowing } from "@mui/icons-material";

const AuthCard = (props) => {
  const { leftContent, children } = props;
  const classes = authCardStyles();

  return (

    <Grid container className={classes.authSectionMain}>
      <Grid item lg={6} xs={12} md={6} style={{ display: "flex", justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>


        <img src={Logo} alt={"Logo"} className={classes.logo} />
        <Typography variant="h4" style={{ fontWeight: 350, color: 'white' }} > Application For Fire NOC</Typography>

      </Grid>
      <Grid item lg={6} xs={12} md={6} style={{backgroundColor:'#fff'}}>
        <Container className={classes.containerRoot}>
          <Paper className={classes.authRoot}>{children}</Paper>
        </Container>
      </Grid>

    </Grid>

  );
};

export default AuthCard;