import React, { useEffect, useRef, useState } from "react";
import * as yup from 'yup';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    InputAdornment,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { Form, Formik } from "formik";
import FormControl from "../../../molecules/FormControl/FormControl";
import { ChangeWidthIcon } from "../../../atoms/SvgIcons/SvgIcons";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { useDispatch, useSelector } from "react-redux";
import { bankLeadSelector, sendBankLeadsMail } from "../../../../redux/features/agent/AgentBankLeadSlice";
const useStyles = makeStyles((theme) => ({
    dialogBoxTitle: {
        padding: theme.spacing(1),
        display: "flex",
        justifyContent: "space-between",

        "& .MuiDialogTitle-root": {
            padding: 0,
        },
        "& h2": {
            color: "#007AE7",
            fontSize: "1.5rem",
            fontWeight: "bold",

        },
    },

    underlineHover: {
        "&:hover": {
            textDecoration: "underline",
        },
    },
}));

export default function BankLeadMailDialog(props) {
    const {
        isFetchingSendBankLeadMail,
        isSuccessSendBankLeadMail,
        isErrorSendBankLeadMail,
        errorMsgSendBankLeadMail,
        sendBankLeadMailData,
    } = useSelector(bankLeadSelector);
    const dispatch = useDispatch();
    const { open, onClose } = props;
    const classes = useStyles();
    const formikRef = useRef();
    const initialValues = {
        to: "",
        cc: "",
        bcc: "",
        subject: "",
        body: "",
        attchment: "",
    };

    const [showCc, setShowCc] = useState(false);
    const [showBcc, setShowBcc] = useState(false);
    const [dialogWidth, setDialogWidth] = useState("sm");
    const [attachedFiles, setAttachedFiles] = useState([]);

    const toggleDialogWidth = () => {
        setDialogWidth(dialogWidth === "sm" ? "md" : "sm");
    };
    const handleFileChange = (event) => {
        const files = event.target.files;
        // Convert the FileList object to an array
        const fileList = Array.from(files);
        setAttachedFiles(fileList);
    };
    const validationSchema = yup.object().shape({
        to: yup.string()
            .matches(
                /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}(,[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4})*$/,
                'Invalid email address(es)'
            )
            .required('To field is required'),
        cc: yup.string()
            .matches(
                /^$|^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}(,[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4})*$/,
                'Invalid email address(es)'
            ),
        bcc: yup.string()
            .matches(
                /^$|^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}(,[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4})*$/,
                'Invalid email address(es)'
            ),
        subject: yup.string().required('Subject field is required'),

    });
    const onSubmit = (values, { setSubmitting }) => {

        setSubmitting(false);
        console.log(values, attachedFiles);
        const formData = new FormData();

        formData.append("receiver_email", values.to);
        formData.append("cc_email", values.cc);
        formData.append("bcc_email", values.bcc);

        formData.append("subject", values.subject);
        formData.append("message", values.body);
        // formData.append("AgentId", "1")


        attachedFiles.forEach((file) => {
            formData.append("attachment", file);
        });



        dispatch(sendBankLeadsMail(formData));
    };



    useEffect(() => {
        if (isSuccessSendBankLeadMail)
            handleClose();
    }, [isSuccessSendBankLeadMail])


    const handleClose = () => {
        onClose();
        setAttachedFiles([]);
    };
    return (
        <>
            <Dialog open={open} onClose={() => handleClose()} aria-labelledby="form-dialog-title" maxWidth={dialogWidth}>

                <DialogTitle style={{ padding: 0 }}>
                    <Grid className={classes.dialogBoxTitle}>

                        <DialogTitle id="alert-dialog-title">Mail Documents</DialogTitle>
                        <Grid> <IconButton onClick={toggleDialogWidth} size="small">
                            <ChangeWidthIcon />
                        </IconButton>
                            <IconButton onClick={() => handleClose()} size="small">
                                <CloseIcon />
                            </IconButton></Grid>
                    </Grid>


                </DialogTitle>
                <DialogContent style={{ paddingTop: 0 }}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        innerRef={formikRef}
                        validationSchema={validationSchema}
                    >
                        {({ submitForm }) => (
                            <Form noValidate autoComplete="off">
                                <Grid container alignItems="center">
                                    <Grid item>
                                        <FormControl
                                            control="input"
                                            label="To"
                                            name="to"
                                            type="text"
                                            id="to"
                                            margin="dense"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton size="small"
                                                            onClick={() => setShowCc(!showCc)}
                                                            className={classes.underlineHover}
                                                        >
                                                            Cc
                                                        </IconButton>
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => setShowBcc(!showBcc)}
                                                            className={classes.underlineHover}
                                                        >
                                                            Bcc
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        {showCc && (
                                            <FormControl
                                                control="input"
                                                label="Cc"
                                                name="cc"
                                                type="text"
                                                id="cc"
                                                margin="dense"
                                            />
                                        )}
                                        {showBcc && (
                                            <FormControl
                                                control="input"
                                                label="Bcc"
                                                name="bcc"
                                                type="text"
                                                id="bcc"
                                                margin="dense"
                                            />
                                        )}
                                        <FormControl
                                            control="input"
                                            label="Subject"
                                            name="subject"
                                            type="text"
                                            id="subject"
                                            margin="dense"
                                        />
                                        <FormControl
                                            control="input"
                                            name="body"
                                            multiline
                                            rows={5}
                                            type="text"
                                            id="body"
                                            margin="dense"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item style={{ marginTop: 5, justifyContent: 'space-between', display: 'flex' }}>
                                    <Grid item>
                                        {/* <Button
                                            style={{ borderRadius: 20 }}
                                            color="primary"
                                            variant="contained"
                                            type="submit"
                                        >
                                            Send
                                        </Button> */}
                                        <Button style={{ borderRadius: 20 }} type="submit" autoFocus variant="contained" color="primary" disabled={isFetchingSendBankLeadMail}>
                                            {isFetchingSendBankLeadMail && <CircularProgress size={20} style={{ marginRight: "10px" }} />}
                                            {!isFetchingSendBankLeadMail && <>Send</>}
                                            {isFetchingSendBankLeadMail && <>Sending...</>}
                                        </Button>
                                        <input
                                            id="attachment"
                                            type="file"
                                            multiple
                                            style={{ display: 'none' }}
                                            onChange={handleFileChange}
                                        />
                                        <label htmlFor="attachment">
                                            <IconButton component="span">
                                                <AttachFileIcon />
                                            </IconButton>
                                        </label>
                                        {attachedFiles.map((file, index) => (
                                            <div key={index}>{file.name}</div>
                                        ))} </Grid>
                                    <Grid item> <IconButton onClick={() => handleClose()}>
                                        <DeleteOutlineIcon />
                                    </IconButton></Grid>
                                </Grid>


                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog></>
    );
}
