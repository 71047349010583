import React from "react";
import AgentProfileView from "../../organisms/AgentProfilePageComponents/AgentProfileView/AgentProfileView";
import AgentLayout from "../AgentLayout/AgentLayout";

const AgentProfilePage = () => {
  return (
    <AgentLayout>
      <AgentProfileView />
    </AgentLayout>
  );
};

export default AgentProfilePage;
