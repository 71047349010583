import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndPoint } from "../../../utils/Common";
import { Api } from "@mui/icons-material";
//const ApiEndPointMyAppOverview = ApiEndPoint + "/Applicant/PmayNonPmay/";
const ApiEndPointMyApplicantOverview = ApiEndPoint + "/Applicant/OtherDetails/";
const  ExbitionDetailApi = ApiEndPoint + "/Applicant/ExhibitionDetails/";
const  ExbitionPeriodApi = ApiEndPoint + '/Applicant/processReceiptDetails/';
const  ContactPersonApi = ApiEndPoint + '/Applicant/ContactPersonDetails/';
const PaymentEndPoint = ApiEndPoint + '/Applicant/MortgagePaymentDetails/'
const ExhibitionPayment= ApiEndPoint +'/Applicant/MortgagePayment/';
const SorryFormEndPoint = "https://infouat.cidcohomes.com/wp-json/wp/v2/save-lead-data";
//Mortgage Noc Details saving Api;
const MortgagePropertyapi = ApiEndPoint +'/Applicant/fireApplicationDetails/';
const MortgageLeeseDetailapi = ApiEndPoint +'/Applicant/fireNocChallanDetails/';
const MortgagelisionAuthPersonDetailsapi = ApiEndPoint +'/Applicant/processChallanDetails/';
const MortgageocDetailsapi = ApiEndPoint +'/Applicant/ocDetails/';
const MortgagebuaFormDetailsapi = ApiEndPoint +'/Applicant/buaFormDetails/';
const MortgageBankDetailsapi = ApiEndPoint +'/Applicant/mortgageBankDetails/';
const MortgageassignmentModeDetailsapi = ApiEndPoint +'/Applicant/assignmentModeDetails/';
const MortgagepreviousNocDetailsapi = ApiEndPoint +'/Applicant/previousNocDetails/';
const PostFactoApprovalDetailsapi = ApiEndPoint +'/Applicant/FactoDetails/';


///////////////////////////////////////////////////////////////////////////////////////////
// export const getPmay = createAsyncThunk(
//   "PmayNonPmay/getMyProfile",
//   async (params, thunkAPI) => {
//     try {
//       const response = await axios.get(
//         ApiEndPointMyAppOverview + localStorage.getItem("applicantId") + "?Lang=" + localStorage.getItem("i18nextLng") + params
//       );
//       let responseData = await response.data;
//       if (response.status === 200) {
//         return responseData;
//       } else {
//         return thunkAPI.rejectWithValue(responseData);
//       }
//     } catch (e) {
//       thunkAPI.rejectWithValue(e.response.data);
//     }
//   }
// );
export const getBidderType = createAsyncThunk(
  "bidder/getbiddertype",
  async ({ RegisterType, Type, GstNo }, thunkAPI) => {
    try {
      const response = await axios.put(ApiEndPointMyApplicantOverview + localStorage.getItem("applicantId"), {
        RegisterType,
        GstNo,
        Type,
        Lang: localStorage.getItem("i18nextLng"),
      });
      let responseData = await response.data;
      if (response.status === 200) {
        return responseData;
      } else {
        return thunkAPI.rejectWithValue(responseData);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

/// Save Gst Value For Non Individual

export const saveGstValue = createAsyncThunk(
  "bidder/savegstvalue",
  async ({ Type, GstNo }, thunkAPI) => {
    try {
      const response = await axios.put(ApiEndPointMyApplicantOverview + localStorage.getItem("applicantId"), {

        GstNo,
        Type,
        Lang: localStorage.getItem("i18nextLng"),
      });
      let responseData = await response.data;
      if (response.status === 200) {
        return responseData;
      } else {
        return thunkAPI.rejectWithValue(responseData);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const RegisterBidderType = createAsyncThunk(
  "bidder/Registerbiddertype",
  async (values, thunkAPI) => {
    try {
      const response = await axios.put(ApiEndPointMyApplicantOverview + localStorage.getItem("applicantId"), values);
      let responseData = await response.data;
      if (response.status === 200) {
        return responseData;
      } else {
        return thunkAPI.rejectWithValue(responseData);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

//Exbition detail api
export const ExhibitionDetailed = createAsyncThunk(
  "bidder/ExhibitionDetails",
  async (values, thunkAPI) => {
    try {
      const response = await axios.put(ExbitionDetailApi + localStorage.getItem("applicantId"), values);
      let responseData = await response.data;
      if (response.status === 200) {
        return responseData;
      } else {
        return thunkAPI.rejectWithValue(responseData);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// period Details Api 
export const EventPeriod = createAsyncThunk(
  "bidder/EventPeriodDetails",
  async (values, thunkAPI) => {
    try {
      const response = await axios.put(ExbitionPeriodApi + localStorage.getItem("applicantId"), values);
      let responseData = await response.data;
      if (response.status === 200) {
        return responseData;
      } else {
        return thunkAPI.rejectWithValue(responseData);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// ContactPersonDetails api

export const EventContactPerson = createAsyncThunk(
  "bidder/EventContentPerson",
  async (values, thunkAPI) => {
    try {
      const response = await axios.put(ContactPersonApi + localStorage.getItem("applicantId"), values);
      let responseData = await response.data;
      if (response.status === 200) {
        return responseData;
      } else {
        return thunkAPI.rejectWithValue(responseData);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

//payment detail api
export const getPaymentDetail = createAsyncThunk(
  "bidder/getpaymentdetail",
  async (values, thunkAPI) => {
    try {
      const response = await axios.put(ExhibitionPayment+ localStorage.getItem("applicantId"), values);
      let responseData = await response.data;
      if (response.status === 200) {
        return responseData;
      } else {
        return thunkAPI.rejectWithValue(responseData);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// summarydetailapi

export const getSummaryDetails = createAsyncThunk(
  "bidder/getSummaryDetail",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(
        ApiEndPoint + "/Applicant/fireNocChallanDetails/" + localStorage.getItem("applicantId")+"/"+localStorage.getItem("requestId")  
      );
      let responseData = await response.data;
      if (response.status === 200) {
        return responseData;
      } else {
        return thunkAPI.rejectWithValue(responseData);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
//challan receipt Details
export const getChalanReceipt = createAsyncThunk(
  "bidder/getChalanReceipt",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(
        ApiEndPoint + "/Applicant/fireNocReceiptDetails/" + localStorage.getItem("applicantId")+"/"+localStorage.getItem("requestId")  
      );
      let responseData = await response.data;
      if (response.status === 200) {
        return responseData;
      } else {
        return thunkAPI.rejectWithValue(responseData);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);



//challan detail api

export const getChallanDetails = createAsyncThunk(
  "bidder/getChallanDetail",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(
        ApiEndPoint + "/Applicant/FireNocSummaryDetails/" + localStorage.getItem("applicantId")  
      );
      let responseData = await response.data;
      if (response.status === 200) {
        return responseData;
      } else {
        return thunkAPI.rejectWithValue(responseData);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getPropertyDetails = createAsyncThunk(
  "bidder/getPropertyDetails",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(
        ApiEndPoint + "/Property/allProperty"   
      );
      let responseData = await response.data;
      if (response.status === 200) {
        return responseData;
      } else {
        return thunkAPI.rejectWithValue(responseData);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);


export const saveLeadForm = createAsyncThunk(
  "preferences/saveLeadForm",
  async (params, thunkAPI) => {
    try {
      const response = await axios.post(
        SorryFormEndPoint,
        params
      );
      let responseData = await response.data;
      if (response.status === 200) {
        return responseData;
      } else {
        return thunkAPI.rejectWithValue(responseData);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

//payment api for application slice

export const Paymentformapi = createAsyncThunk(
  "bidder/payment",
  async (values, thunkAPI) => {
    try {
      const response = await axios.post(
        PaymentEndPoint + localStorage.getItem("applicantId"),
        values
      );
      let responseData = await response.data;
      if (response.status === 200) {
        return responseData;
      } else {
        return thunkAPI.rejectWithValue(responseData);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

///Mortgage Noc apis
export const MortgagePropertyDetails = createAsyncThunk(
  "mortgage/property details",
  async (values, thunkAPI) => {
    try {
      const response = await axios.post(
        MortgagePropertyapi+localStorage.getItem("applicantId"),
        values
      );
      let responseData = await response.data;
      if (response.status === 200) {
        return responseData;
      } else {
        return thunkAPI.rejectWithValue(responseData);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

//Leessee detail api

export const LeeseeDetailed = createAsyncThunk(
  "mortgage/LeeseeDetails",
  async (values, thunkAPI) => {
    try {
      const response = await axios.put(MortgageLeeseDetailapi + localStorage.getItem("applicantId"), values);
      let responseData = await response.data;
      if (response.status === 200) {
        return responseData;
      } else {
        return thunkAPI.rejectWithValue(responseData);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// person authorized for lision
export const AuthorizedLisionDetailed = createAsyncThunk(
  "mortgage/AuthorizedLisionDetails",
  async (values, thunkAPI) => {
    try {
      const response = await axios.put(MortgagelisionAuthPersonDetailsapi + localStorage.getItem("applicantId"), values) ;
      let responseData = await response.data;
      if (response.status === 200) {
        return responseData;
      } else {
        return thunkAPI.rejectWithValue(responseData);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
// oc detail form api
export const OCFormDetailed = createAsyncThunk(
  "mortgage/OcFormDetails",
  async (values, thunkAPI) => {
    try {
      const response = await axios.put(MortgageocDetailsapi + localStorage.getItem("applicantId"), values);
      let responseData = await response.data;
      if (response.status === 200) {
        return responseData;
      } else {
        return thunkAPI.rejectWithValue(responseData);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
// Bua form api

export const BuaFormDetailed = createAsyncThunk(
  "mortage/buaform",
  async (values, thunkAPI) => {
    try {
      const response = await axios.put(MortgagebuaFormDetailsapi + localStorage.getItem("applicantId"), values);
      let responseData = await response.data;
      if (response.status === 200) {
        return responseData;
      } else {
        return thunkAPI.rejectWithValue(responseData);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// mortgage bank detail api

export const MortgageBankDetailed = createAsyncThunk(
  "mortage/bankDetails",
  async (values, thunkAPI) => {
    try {
      const response = await axios.put(MortgageBankDetailsapi+ localStorage.getItem("applicantId"), values);
      let responseData = await response.data;
      if (response.status === 200) {
        return responseData;
      } else {
        return thunkAPI.rejectWithValue(responseData);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
// assignment mode of assignment detail api
export const ModeofAssignmentDetailed = createAsyncThunk(
  "mortgage/modeofassignment",
  async (values, thunkAPI) => {
    try {
      const response = await axios.put(MortgageassignmentModeDetailsapi + localStorage.getItem("applicantId"), values);
      let responseData = await response.data;
      if (response.status === 200) {
        return responseData;
      } else {
        return thunkAPI.rejectWithValue(responseData);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// Previous Noc Detail api

export const PreviousNocDetailed = createAsyncThunk(
  "mortgage/PreviousNocDetails",
  async (values, thunkAPI) => {
    try {
      const response = await axios.put(MortgagepreviousNocDetailsapi + localStorage.getItem("applicantId"), values);
      let responseData = await response.data;
      if (response.status === 200) {
        return responseData;
      } else {
        return thunkAPI.rejectWithValue(responseData);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

///////post Facto approval
export const PostFactoApproval = createAsyncThunk(
  "mortgage/PostFactoApprovcal",
  async (values, thunkAPI) => {
    try {
      const response = await axios.put( PostFactoApprovalDetailsapi + localStorage.getItem("applicantId"), values);
      let responseData = await response.data;
      if (response.status === 200) {
        return responseData;
      } else {
        return thunkAPI.rejectWithValue(responseData);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);




////////////////////////////////////////////////////////////////////////////////////////

export const pmaySlice = createSlice({
  name: "PmayNonPmay",
  initialState: {
//Mortgage Noc State
// mortgage property details
isSuccessMortageDetails: false,
isFetchingMortageDetails: false,
mortageDetailsMessage: "",
isErrorMortageDetails: "",

//leeseedetail state

isSuccessLeeseeDetail: false,
isErrorLeeseeDetail: false,
errorMsgLeeseeDetail: "",
isFetchingLeeseeDetail: false,
applicationLeeseeDetail: "",
currentLeeseeDetail: [],

//lision authorizedperson

isSuccessAuthorizedLisionDetail: false,
isErrorAuthorizedLisionDetail: false,
errorMsgAuthorizedLisionDetail: "",
isFetchingAuthorizedLisionDetail: false,
applicationAuthorizedLisionDetail: "",
currentAuthorizedLisionDetail: [],

//ocform state
isSuccessOCFormDetail: false,
isErrorOCFormDetail: false,
errorMsgOCFormDetail: "",
isFetchingOCFormDetail: false,
applicationOCFormDetail: "",
currentOCFormDetail: [],

//bua form state
isSuccessBuaFormDetail: false,
isErrorBuaFormDetail: false,
errorMsgBuaFormDetail: "",
isFetchingBuaFormDetail: false,
applicationBuaFormDetail: "",
currentBuaFormDetail: [],

//bankdetail form state
isSuccessMortgageBankDetail: false,
isErrorMortgageBankDetail: false,
errorMsgMortgageBankDetail: "",
isFetchingMortgageBankDetail: false,
applicationMortgageBankDetail: "",
currentMortgageBankDetail: [],

//modeofassignment form state

isSuccessModeOfAssignmentDetail: false,
isErrorModeOfAssignmentDetail: false,
errorMsgModeOfAssignmentDetail: "",
isFetchingModeOfAssignmentDetail: false,
applicationModeOfAssignmentDetail: "",
currentModeOfAssignmentDetail: [],

//previos noc form state

isSuccessPreviousNocDetail: false,
isErrorPreviousNocDetail: false,
errorMsgPreviousNocDetail: "",
isFetchingPreviousNocDetail: false,
applicationPreviousNocDetail: "",
currentPreviousNocDetail: [],

//post Facto form state

isSuccessPostFactoDetail: false,
isErrorPostFactoDetail: false,
errorMsgPostFactoDetail: "",
isFetchingPostFactoDetail: false,
applicationPostFactoDetail: "",
currentPostFactoDetail: [],



//////////////////////////////////////
    //save gst state
    isSuccessSaveGst: false,
    isFetchingSaveGst: false,
    saveGstMessage: "",
    isErrorSaveGst: "",
    


    isSuccessPmay: false,
    isFetchingPmay: false,
    isErrorPmay: false,
    errorMsgPmay: "",
    applicationPmay: "",
    currentPmay: [],
    isSuccessLeadForm: "",
    isFetchingLeadForm: false,
    isErrorLeadForm: false,
    errorMsgLeadForm: "",

    // payment form state
    isSuccessPaymentForm: "",
    isFetchingPaymentForm: false,
    isErrorPaymentForm: false,
    errorMsgPaymentForm: "",
    paymentData:{},
   // applicant api
    isSuccessBidder: false,
    isErrorBidder: false,
    errorMsgBidder: "",
    isFetchingBidder: false,
    applicationBidder: "",
    currentBidder: [],
    // exbitiondetails State
    isSuccessExhibition: false,
    isErrorExhibition: false,
    errorMsgExhibition: "",
    isFetchingExhibition: false,
    applicationExhibition: "",
    currentExhibition: [],
    //Event peroid states
    isSuccessEventPeriod: false,
    isErrorEventPeriod: false,
    errorMsgEventPeriod: "",
    isFetchingEventPeriod: false,
    applicationEventPeriod: "",
    currentEventPeriod: [],
    //Contact Person Detail
      
      isSuccessContact: false,
      isErrorContact: false,
      errorMsgContact: "",
      isFetchingContact: false,
      applicationContact: "",
      currentContact: [],

       //Payment Detail
      
       isSuccessPaymentDetail: false,
       isErrorPaymentDetail: false,
       errorMsgPaymentDetail: "",
       isFetchingPaymentDetail: false,
       applicationPaymentDetail: "",
       currentPaymentDetail: [],
       //summary details state
       isFetchingSummary: false,
       isSuccessReqsummary: false,
       isSuccessRessummary: false,
       isErrorsummary: false,
       errorMessagesummary: "",
       summaryData: {},

        //Challan details state
        isFetchingChallan: false,
        isSuccessReqChallan: false,
        isSuccessResChallan: false,
        isErrorChallan: false,
        errorMessageChallan: "",
        challanData: {},

         //Challan Receipt details state
         isFetchingChallanReceipt: false,
         isSuccessReqChallanReceipt: false,
         isSuccessResChallanReceipt: false,
         isErrorChallanReceipt: false,
         errorMessageChallanReceipt: "",
         challanReciptData: {},

       //propertydetails state
       isFetchingProperty: false,
       isSuccessReqProperty: false,
       isSuccessResProperty: false,
       isErrorProperty: false,
       errorMessageProperty: "",
       propertyData: {},
  },
  reducers: {
    clearProfileState: (state) => {
      state.isSuccessPmay = false;
      state.isFetchingPmay = false;
      state.isErrorPmay = false;
      state.errorMsgPmay = false;
      state.errorMsgPmay = "";
      state.isSuccessPmay = false;
      state.errorMsgPmay = "";
      state.applicationPmay = "";
      state.currentPmay = [];
    },
    clearBidderState: (state) => {
      state.isSuccessBidder = false;
      state.isFetchingBidder = false;
      state.isErrorBidder = false;
      state.errorMsgBidder = false;
      state.errorMsgBidder = '';
      state.applicationBidder = '';
      state.currentBidder = [];
    },
    //
    clearPaymentDetails:(state)=>{
      state.isSuccessPaymentForm= "";
      state.isFetchingPaymentForm= false;
      state.isErrorPaymentForm= false;
      state.errorMsgPaymentForm= "";
      state.paymentData={}
    },
    // Exhibition Details
    clearExhibitionState: (state) => {
      state.isSuccessExhibhition = false;
      state.isFetchingExhibhition = false;
      state.isErrorExhibhition = false;
      state.errorMsgExhibhition = false;
      state.errorMsgExhibhition = '';
      state.applicationExhibhition = '';
      state.currentExhibhition = [];
    },
    //getpayment detail
    clearexhibitionpaymentState: (state) => {
      state.isSuccessPaymentDetail = false;
      state.isFetchingPaymentDetail = false;
      state.isErrorPaymentDetail = false;
      state.errorMsgPaymentDetail = false;
      state.errorMsgPaymentDetail = '';
      state.applicationPaymentDetail = '';
      state.currentPaymentDetail = [];
    },
    // Event Period State
    clearEventPeriodState: (state) => {
      state.isSuccessEventPeriod = false;
      state.isFetchingEventPeriod = false;
      state.isErrorEventPeriod = false;
      state.errorMsgEventPeriod = false;
      state.errorMsgEventPeriod = '';
      state.applicationEventPeriod = '';
      state.currentEventPeriod = [];
    },
     // contact person State
     clearContactPersonState: (state) => {
      state.isSuccessContact = false;
      state.isFetchingContact = false;
      state.isErrorContact = false;
      state.errorMsgContact = false;
      state.errorMsgContact = '';
      state.applicationContact = '';
      state.currentContact = [];
    },
    clearProfileData: (state) => {
      state.applicationBidder = '';
      state.applicationPmay = "";
      state.isSuccessLeadForm = "";
      state.paymentData=''
    },
    clearGstData: (state) => {
      state.isSuccessSaveGst = false;
      state.isFetchingSaveGst = false;
      state.saveGstMessage = "";
      state.isErrorSaveGst = "";
    }
  },
  extraReducers: {
    [getBidderType.fulfilled]: (state, { payload }) => {
      state.applicationPmay = payload.message;
      state.isFetchingPmay = false;
      state.isSuccessPmay = payload.success;
    },
    [getBidderType.pending]: (state) => {
      state.isFetchingPmay = true;
    },
    [getBidderType.rejected]: (state, { payload }) => {
      state.isFetchingPmay = false;
      state.isErrorPmay = payload.error;
      state.errorMsgPmay = payload.message;
      state.applicationPmay = "";
      state.currentPmay = [];
    },

    //gst save reducers

    [saveGstValue.fulfilled]: (state, { payload }) => {
      state.saveGstMessage = payload.message;
      state.isFetchingSaveGst = false;
      state.isSuccessSaveGst = payload.success;
    },
    [saveGstValue.pending]: (state) => {
      state.isFetchingSaveGst = true;
    },
    [saveGstValue.rejected]: (state, { payload }) => {
      state.isFetchingSaveGst = false;
      state.isErrorSaveGst = payload.error;
      state.errorMsgSaveGst = payload.message;
      state.applicationPmay = "";
      state.currentPmay = [];
    },
    [RegisterBidderType.fulfilled]: (state, { payload }) => {
      state.applicationBidder = payload.message;
      state.isFetchingBidder = false;
      state.isSuccessBidder = payload.success;
    },
    [RegisterBidderType.pending]: (state) => {
      state.isFetchingBidder = true;
    },
    [RegisterBidderType.rejected]: (state, { payload }) => {
      state.isFetchingBidder = false;
      state.isErrorBidder = payload.error;
      state.errorMsgBidder = payload.message;
      state.applicationBidder = "";
      state.currentBidder = [];
    },
    // Exibition details api 
    [ExhibitionDetailed.fulfilled]: (state, { payload }) => {
      state.applicationExhibhition = payload.message;
      state.isFetchingExhibhition = false;
      state.isSuccessExhibition = payload.success;
    },
    [ExhibitionDetailed.pending]: (state) => {
      state.isFetchingExhibition = true;
    },
    [ExhibitionDetailed.rejected]: (state, { payload }) => {
      state.isFetchingExhibition = false;
      state.isErrorExhibition = payload.error;
      state.errorMsgExhibition = payload.message;
      state.applicationExhibition = "";
      state.currentExhibition = [];
    },
// Exbition Period Details Api
[EventPeriod.fulfilled]: (state, { payload }) => {
  state.applicationEventPeriod = payload.message;
  state.isFetchingEventPeriod = false;
  state.isSuccessEventPeriod = payload.success;
},
[EventPeriod.pending]: (state) => {
  state.isFetchingEventPeriod = true;
},
[EventPeriod.rejected]: (state, { payload }) => {
  state.isFetchingEventPeriod = false;
  state.isErrorEventPeriod = payload.error;
  state.errorMsgEventPeriod = payload.message;
  state.applicationEventPeriod = "";
  state.currentEventPeriod = [];
},
//EventContactPerson
[EventContactPerson.fulfilled]: (state, { payload }) => {
  state.applicationContact = payload.message;
  state.isFetchingContact = false;
  state.isSuccessContact = payload.success;
},
[EventContactPerson.pending]: (state) => {
  state.isFetchingContact = true;
},
[EventContactPerson.rejected]: (state, { payload }) => {
  state.isFetchingContact = false;
  state.isErrorContact = payload.error;
  state.errorMsgContact = payload.message;
  state.applicationContact = "";
  state.currentContact = [];
},
//exhibition payment detail

[getPaymentDetail.fulfilled]: (state, { payload }) => {
  state.applicationPaymentDetail = payload.message;
  state.isFetchingPaymentDetail = false;
  state.isSuccessPaymentDetail = payload.success;
},
[getPaymentDetail.pending]: (state) => {
  state.isFetchingPaymentDetail = true;
},
[getPaymentDetail.rejected]: (state, { payload }) => {
  state.isFetchingPaymentDetail = false;
  state.isErrorPaymentDetail = payload.error;
  state.errorMsgPaymentDetail = payload.message;
  state.applicationPaymentDetail = "";
  state.currentContact = [];
},

// payment form api 
[Paymentformapi.fulfilled]: (state, { payload }) => {
  state.isFetchingPaymentForm = false;
  state.isSuccessPaymentForm = payload.success;
  state.paymentData = payload.data;
},
[Paymentformapi.pending]: (state) => {
  state.isFetchingPaymentForm = true;
},
[Paymentformapi.rejected]: (state, { payload }) => {
  state.isFetchingPaymentForm = false;
  state.isErrorPaymentForm = payload.error;
  state.errorMsgPaymentForm = payload.message;
},
////
 // 
 [getSummaryDetails.fulfilled]: (state, { payload }) => {
 state.summaryData=payload.data
  state.isFetchingSummary = false;
  state.isSuccessResSummary = payload.success;
  state.isErrorSummary = payload.error;
  state.errorMessageSummary = payload?.message;
},
[getSummaryDetails.pending]: (state) => {
  state.isFetchingSummary = true;
},
[getSummaryDetails.rejected]: (state, { payload }) => {
  state.isFetchingSummary = false;
  state.isErrorSummary = true;
  state.errorMessageSummary = payload?.message;
  state.summaryData = {};
},
/// chalanreceipt////
[getChalanReceipt.fulfilled]: (state, { payload }) => {
  state.challanReciptData=payload.data
   state.isFetchingChallanReceipt = false;
   state.isSuccessResChallanReceipt = payload.success;
   console.log(payload,"adadadad")
   state.isErrorChallanReceipt = payload.error;
   state.errorMessageChallanReceipt = payload?.message;
 },
 [getChalanReceipt.pending]: (state) => {
   state.isFetchingChallanReceipt = true;
 },
 [getChalanReceipt.rejected]: (state, { payload }) => {
   state.isFetchingChallanReceipt = false;
   state.isErrorChallanReceipt = true;
   state.errorMessageChallanReceipt = payload?.message;
   state.challanReceiptData = {};
 },
////////////////////////
////challan details/////

[getChallanDetails.fulfilled]: (state, { payload }) => {
  state.challanData=payload.data
   state.isFetchingChallan = false;
   state.isSuccessResChallan = payload.success;
   state.isErrorChallan = payload.error;
   state.errorMessageChallan = payload?.message;
 },
 [getChallanDetails.pending]: (state) => {
   state.isFetchingChallan = true;
 },
 [getChallanDetails.rejected]: (state, { payload }) => {
   state.isFetchingChallan = false;
   state.isErrorChallan = true;
   state.errorMessageChallan = payload?.message;
   state.ChallanData = {};
 },

//////////////////////////////
///property details////
[getPropertyDetails.fulfilled]: (state, { payload }) => {
  state.propertyData=payload.data
   state.isFetchingProperty = false;
   state.isSuccessResProperty = payload.success;
   state.isErrorProperty = payload.error;
   state.errorMessageProperty = payload?.message;
 },
 [getPropertyDetails.pending]: (state) => {
   state.isFetchingProperty = true;
 },
 [getPropertyDetails.rejected]: (state, { payload }) => {
   state.isFetchingProperty = false;
   state.isErrorProperty = true;
   state.errorMessageProperty = payload?.message;
   state.summaryData = {};
 },

//////////////////////////////
    [saveLeadForm.fulfilled]: (state, { payload }) => {
      state.isFetchingLeadForm = false;
      state.isSuccessLeadForm = payload.status;
    },
    [saveLeadForm.pending]: (state) => {
      state.isFetchingLeadForm = true;
    },
    [saveLeadForm.rejected]: (state, { payload }) => {
      state.isFetchingLeadForm = false;
      state.isErrorLeadForm = payload.error;
      state.errorMsgLeadForm = payload.message;
    },


    //Mortgage Extra reducers

    //Mortgage property details
    [MortgagePropertyDetails.fulfilled]: (state, { payload }) => {
      state.isFetchingMortageDetails = false;
      state.isSuccessMortageDetails = payload.success;
    },
    [MortgagePropertyDetails.pending]: (state) => {
      state.isFetchingMortageDetails = true;
    },
    [MortgagePropertyDetails.rejected]: (state, { payload }) => {
      state.isFetchingMortageDetails = false;
      state.isErrorMortageDetails = payload.error;
      state.errorMsgMortageDetails = payload.message;
    },

    // Leesee Details
    [LeeseeDetailed.fulfilled]: (state, { payload }) => {
      state.applicationLeeseeDetail = payload.message;
      state.isFetchingLeeseeDetail = false;
      state.isSuccessLeeseeDetail = payload.success;
    },
    [LeeseeDetailed.pending]: (state) => {
      state.isFetchingLeeseeDetail = true;
    },
    [LeeseeDetailed.rejected]: (state, { payload }) => {
      state.isFetchingLeeseeDetail = false;
      state.isErrorLeeseeDetail = payload.error;
      state.errorMsgLeeseeDetail = payload.message;
      state.applicationLeeseeDetail = "";
    
    },

    //authorized person lision
    [AuthorizedLisionDetailed.fulfilled]: (state, { payload }) => {
      state.applicationAuthorizedLisionDetail = payload.message;
      state.isFetchingAuthorizedLisionDetail = false;
      state.isSuccessAuthorizedLisionDetail = payload.success;
    },
    [AuthorizedLisionDetailed.pending]: (state) => {
      state.isFetchingAuthorizedLisionDetail = true;
    },
    [AuthorizedLisionDetailed.rejected]: (state, { payload }) => {
      state.isFetchingAuthorizedLisionDetail = false;
      state.isErrorAuthorizedLisionDetail = payload.error;
      state.errorMsgAuthorizedLisionDetail = payload.message;
      state.applicationAuthorizedLisionDetail = "";
     
    },

    //oc form detaisl
    [OCFormDetailed.fulfilled]: (state, { payload }) => {
      state.applicationOCFormDetail = payload.message;
      state.isFetchingOCFormDetail = false;
      state.isSuccessOCFormDetail = payload.success;
    },
    [OCFormDetailed.pending]: (state) => {
      state.isFetchingOCFormDetail = true;
    },
    [OCFormDetailed.rejected]: (state, { payload }) => {
      state.isFetchingOCFormDetail = false;
      state.isErrorOCFormDetail = payload.error;
      state.errorMsgOCFormDetail = payload.message;
      state.applicationOCFormDetail = "";
     
    },
    //bua form details
    [BuaFormDetailed.fulfilled]: (state, { payload }) => {
      state.applicationBuaFormDetail = payload.message;
      state.isFetchingBuaFormDetail = false;
      state.isSuccessBuaFormDetail = payload.success;
    },
    [BuaFormDetailed.pending]: (state) => {
      state.isFetchingBuaFormDetail = true;
    },
    [BuaFormDetailed.rejected]: (state, { payload }) => {
      state.isFetchingBuaFormDetail = false;
      state.isErrorBuaFormDetail = payload.error;
      state.errorMsgBuaFormDetail = payload.message;
      state.applicationBuaFormDetail = "";
      state.currentContact = [];
    },
    //mortgage bank detail
    [MortgageBankDetailed.fulfilled]: (state, { payload }) => {
      state.applicationMortgageBankDetail = payload.message;
      state.isFetchingMortgageBankDetail = false;
      state.isSuccessMortgageBankDetail = payload.success;
    },
    [MortgageBankDetailed.pending]: (state) => {
      state.isFetchingMortgageBankDetail = true;
    },
    [MortgageBankDetailed.rejected]: (state, { payload }) => {
      state.isFetchingMortgageBankDetail = false;
      state.isErrorMortgageBankDetail = payload.error;
      state.errorMsgMortgageBankDetail = payload.message;
      state.applicationMortgageBankDetail = "";
      state.currentContact = [];
    },
    //modeofassiignment detail
    [ModeofAssignmentDetailed.fulfilled]: (state, { payload }) => {
      state.applicationModeOfAssignmentDetail = payload.message;
      state.isFetchingModeOfAssignmentDetail = false;
      state.isSuccessModeOfAssignmentDetail = payload.success;
    },
    [ModeofAssignmentDetailed.pending]: (state) => {
      state.isFetchingModeOfAssignmentDetail = true;
    },
    [ModeofAssignmentDetailed.rejected]: (state, { payload }) => {
      state.isFetchingModeOfAssignmentDetail = false;
      state.isErrorModeOfAssignmentDetail = payload.error;
      state.errorMsgModeOfAssignmentDetail = payload.message;
      state.applicationModeOfAssignmentDetail = "";
      state.currentContact = [];
    },
    //previous noc details
    [PreviousNocDetailed.fulfilled]: (state, { payload }) => {
      state.applicationPreviousNocDetail = payload.message;
      state.isFetchingPreviousNocDetail = false;
      state.isSuccessPreviousNocDetail = payload.success;
    },
    [PreviousNocDetailed.pending]: (state) => {
      state.isFetchingPreviousNocDetail = true;
    },
    [PreviousNocDetailed.rejected]: (state, { payload }) => {
      state.isFetchingPreviousNocDetail = false;
      state.isErrorPreviousNocDetail = payload.error;
      state.errorMsgPreviousNocDetail = payload.message;
      state.applicationPreviousNocDetail = "";
      state.currentContact = [];
    },

    //post facto reducers 


    [ PostFactoApproval.fulfilled]: (state, { payload }) => {
      state.applicationPostFactoDetail = payload.message;
      state.isFetchingPostFactoDetail = false;
      state.isSuccessPostFactoDetail = payload.success;
    },
    [ PostFactoApproval.pending]: (state) => {
      state.isFetchingPostFactoDetail = true;
    },
    [ PostFactoApproval.rejected]: (state, { payload }) => {
      state.isFetchingPostFactoDetail = false;
      state.isErrorPostFactoDetail = payload.error;
      state.errorMsgPostFactoDetail = payload.message;
      state.applicationPostFactoDetail = "";
      state.currentContact = [];
    },
    /////////////////////////////////////////////////////////////
  },
});

export const { clearProfileState, clearProfileData, clearBidderState,clearPaymentDetails, clearContactPersonState, clearGstData ,clearExhibitionState,clearEventPeriodState} =
  pmaySlice.actions;

export const PmayNonPmaySelector = (state) => state.PmayNonPmay;
