import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { lighten, makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {
  ButtonGroup,
  Checkbox,
  CircularProgress,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Popover,
  Snackbar,
  Toolbar,
  Tooltip,
  useTheme,
} from "@material-ui/core";
import PhoneIcon from '@material-ui/icons/Phone';
import InfoIcon from '@material-ui/icons/Info';
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ChatTwoToneIcon from '@material-ui/icons/ChatTwoTone';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import DeleteIcon from "@material-ui/icons/Delete";
import DataNoteFoundIcon from "../../../../assets/DataNotFoundIcon.png";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import {
  agentLeadSelector,
  clearAddCommentState,
  clearChangeStatusState,
  clearCommentState,
  clearEditLeadState,
  getAgentLeadData,
} from "../../../../redux/features/agent/AgentLeadSlice";
import AgentDeleteDialoogBox from "../../../molecules/DialogBoxes/AgentDeleteLeadDialogBox/AgentDeleteLeadDialogBox";
import AgentLeadViewDialogBox from "../../../molecules/DialogBoxes/AgentLeadViewDialogBox/AgentLeadViewDialogBox";
import BankLeadComment from "../BankCommentAndStatus/BankComment";
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import PropTypes from "prop-types";
import moment from "moment";
import { truncateString } from "../../../../utils/UtilsFunction";
import { Alert } from "@material-ui/lab";
import { ManagebankLeadStyles } from "../ManagebankLead.styles";
import { Form, Formik } from "formik";
import FormControl from "../../../molecules/FormControl/FormControl";
import { assignBankLeads, bankLeadSelector, clearAssignLeadState, getBankLeads } from "../../../../redux/features/agent/AgentBankLeadSlice";
import BankLeadDocumentDialog from "../BankLeadDocumentDialog/BankLeadDocumentDialog";
import { MailOutline } from "@material-ui/icons";
import PublishIcon from '@material-ui/icons/Publish';
import BankLeadMailDialog from "../BankLeadDocumentDialog/BankLeadMailDialog";
import { ApiEndPoint } from "../../../../utils/Common";
import UploadChallanDialog from "../BankLeadDocumentDialog/UploadChallanDialog";
import ViewChallanData from "../BankLeadDocumentDialog/ViewChallanData";
import ReceiptIcon from '@material-ui/icons/Receipt';
import { getChalanReceipt, getSummaryDetails, PmayNonPmaySelector } from "../../../../redux/features/pmayNonPmay/pmayNonPmaySlice";
import ViewChallanReceipt from "../BankLeadDocumentDialog/ViewChallanReceipt";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    background: theme.palette.primary.main,
    color: '#fff',
    display: 'flex',
    justifyContent: 'space-between'
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, managerList, selected } = props;
  const [managerValue, setMangagerValue] = useState({ manager: '' });
  const formikRef = useRef();
  const dispatch = useDispatch();
  const { isFetchingAssignLead } = useSelector(bankLeadSelector);

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
    if (values.manager) {
      let data = {
        managerID: values.manager,
        leadIds: selected.join(',')
      }
      dispatch(assignBankLeads(data));
    }
    else {
      alert('Please select a manager to assign the lead')
    }
  };

  return (
    <>
      {numSelected > 0 &&
        <Toolbar
          className={classes.root}
        >
          <Typography style={{ fontWeight: 600 }} color="inherit" variant="subtitle1" component="div">
            {numSelected} selected
          </Typography>
          <Box width='45%'>
            <Formik
              initialValues={managerValue}
              onSubmit={onSubmit}
              innerRef={formikRef}
            >
              {({ submitForm, setFieldValue, values }) => (
                <Form noValidate autoComplete="off">
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={8}>
                      <FormControl
                        margin="dense"
                        control="selectbox"
                        variant="outlined"
                        label="select manager to assign"
                        name="manager"
                        id="status"
                        options={managerList}
                        style={{ backgroundColor: '#fff', borderRadius: 25 }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Button onClick={submitForm} style={{ backgroundColor: '#fff' }} autoFocus variant="contained" size="small" disabled={isFetchingAssignLead}>
                        {isFetchingAssignLead && <CircularProgress size={20} />}
                        {!isFetchingAssignLead && <>Assign Application</>}
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Box>
        </Toolbar>}
    </>
  );
};

const CustomTooltip = withStyles({
  tooltip: {
    backgroundColor: "#FFFFFF",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: 11,
    boxShadow: "0px 4px 20px rgba(23, 33, 61, 0.06);",
    borderRadius: "8px",
    border: "1px solid rgba(0, 56, 192, 1)",
  },
  arrow: {
    "&:before": {
      border: "1px solid rgba(0, 56, 192, 1)",
    },
    color: "#FFFFFF",
  },
})(Tooltip);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#0038C0",
    color: theme.palette.common.white,
    lineHeight: '1rem'
  },
  body: {
    fontSize: 13,
    padding: "0px 10px",
    lineHeight: "1"
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      // backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const statusList = [
  {
    value: 1,
    label: "Unassigned Application",
  },
  {
    value: 2,
    label: "Assigned Application",
  },
  {
    value: 3,
    label: "Challan Uploaded",
  },
  {
    value: 4,
    label: "Challan Approved",
  },
  {
    value: 5,
    label: "Challan Rejected",
  },
  {
    value: 6,
    label: "Receipt Uploaded",
  },
  {
    value: 7,
    label: "NOC Generated",
  },
  {
    value: 8,
    label: "Receipt Rejected",
  },
];

const shortCategory = [
  {
    value: 1,
    label: "General"
  },
  {
    value: 2,
    label: "SC"
  },
  {
    value: 3,
    label: "ST"
  },
  {
    value: 4,
    label: "NT"
  },
  {
    value: 5,
    label: "DT"
  },
  {
    value: 6,
    label: "RM"
  },
  {
    value: 7,
    label: "State Gov."
  },
  {
    value: 8,
    label: "Journalist"
  },
  {
    value: 9,
    label: "CIDCO Emp."
  },
  {
    value: 10,
    label: "PAP"
  },
  {
    value: 11,
    label: "Divyang"
  },
  {
    value: 12,
    label: "Ex-Servicemen "
  },
  {
    value: 13,
    label: "Mathadi Kamgar"
  },
];

const stepper = [
  {
    value: 1,
    label: "Applicant Sign Up"
  },
  {
    value: 2,
    label: "KYC"
  },
  {
    value: 3,
    label: "Personal details"
  },
  {
    value: 4,
    label: "Add Co-Applicant"
  },
  {
    value: 5,
    label: "Category Details"
  },
  {
    value: 6,
    label: "Upload Documents"
  },
  {
    value: 7,
    label: "Application Fee"
  },
  {
    value: 8,
    label: "Document Verification"
  },
  {
    value: 9,
    label: "Select Flat"
  },
  {
    value: 10,
    label: "Booking Fee"
  },
  {
    value: 11,
    label: "LOI Generation"
  },
  {
    value: 12,
    label: "Allotment Letter"
  },
  {
    value: 13,
    label: "Installments"
  },
  {
    value: 14,
    label: "Agreement"
  },
  {
    value: 15,
    label: "Agreement completed"
  },
];

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;


  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
        title="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page" title="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        title="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
        title="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};


const BankLeadTable = (props) => {
  const history = useHistory();
  const {
    cardData,
    storeLeadData,
    rowsPerPage,
    setRowsPerPage,
    page,
    setPage,
    filterCategoryData,
    agentProfile,
    selectedStatus,
    count,
    setSelectedSort,
    selectedSort,
    managerList,
    isHOD
  } = props;
  const { summaryData, isSuccessResSummary, challanReciptData, isSuccessResChallanReceipt } = useSelector(PmayNonPmaySelector);
  const classes = ManagebankLeadStyles();
  const { t } = useTranslation("AgentLeadPageTrans");
  const [selected, setSelected] = React.useState([]);
  const isSelected = (row) => selected.indexOf(row) !== -1;
  const [openLeadViewDialog, setOpenLeadViewDialog] = React.useState(false);
  const [openCommentDialog, SetOpenCommentDialog] = React.useState(false);
  const [leadData, setLeadData] = React.useState([]);
  const [showDocument, setShowDocument] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false);
  const ApiEndPointPdf = ApiEndPoint + "/FireNoc//applicationOverviewPdf/";
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialog2Open, setDialog2Open] = useState(false);
  const [data, setData] = useState({
    amountDue: "",
    amountDate: "",
    challanFile: "",
    // Add other data fields as needed
  });
  const [receiptData, setReceiptData] = useState({
    challanreceipt: ""
  })
  useEffect(() => {
    if (isSuccessResSummary) {
      setData(summaryData)
    }
  }, [summaryData])

  useEffect(() => {
    if (isSuccessResChallanReceipt) {
      setReceiptData(challanReciptData)
     console.log(challanReciptData,"sgfsdgdgdhd")
    }
  }, [challanReciptData,isSuccessResChallanReceipt])


  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(25);
  /* const [selectedFromDate, setSelectedFromDate] = useState(new Date('2021-08-09'));
  const [selectedToDate, setSelectedToDate] = useState(new Date('2021-08-10')); */
  const dispatch = useDispatch();
  const {
    isFetchingAssignLead,
    isSuccessAssignLead,
    isErrorAssignLead,
    errorMsgAssignLead,
    assignLeadData
  } = useSelector(bankLeadSelector);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  useEffect(() => {
    setSelected([])
  }, [selectedStatus, isSuccessAssignLead])

  // const emptyRows = rowsPerPage - Math.min(rowsPerPage, storeLeadData.length - page * rowsPerPage);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  // const handleModalAfterRequestClose = () => {
  //   if (isSuccessEditLead) {
  //     dispatch(getAgentLeadData());
  //     setOpenLeadViewDialog(false);
  //     setLeadData([]);
  //   }
  // };

  const handleCommentClose = () => {
    dispatch(getBankLeads());
    SetOpenCommentDialog(false);
    dispatch(clearCommentState());
    dispatch(clearAddCommentState());
    dispatch(clearChangeStatusState());
    setLeadData([]);
  }

  const handleShowDocumentClose = () => {
    setShowDocument(false);
    setLeadData([]);
  }

  /*
    const handleChangeDense = (event) => {
      setDense(event.target.checked);
    };
   */
  const categoryMapping = {};
  shortCategory.forEach((category) => {
    categoryMapping[category.value] = category.label;
  });
  const categoryTittleMapping = {};
  filterCategoryData.forEach((category) => {
    categoryTittleMapping[category.value] = category.label;
  });

  function CategoryName({ row }) {
    const categoryLabel = categoryMapping[row.category] || "--";
    const categoryTittle = categoryTittleMapping[row.category] || "--";
    const truncatedLabel = truncateString(categoryLabel, 2);

    return (
      <StyledTableCell align="center" title={categoryTittle} >
        {categoryLabel}
      </StyledTableCell>
    );
  }
  const downloadPdf = (request) => {
    setPdfLoading(true);
    let fileUrl = ApiEndPointPdf +
      request
    fetch(fileUrl, {
      method: "GET",
      headers: {
        Authorization: localStorage.getItem("jwtToken"),
      },
    }).then((response) => response.blob()).then((blob) => {
      setPdfLoading(false);
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      let today = new Date();
      const dateTime = moment(new Date(today.getFullYear(), today.getMonth() + 1, 0)).format('YYYY-MM-DD-h:mm');
      link.download = `${localStorage.getItem("applicantId")} -${dateTime}`;
      document.body.append(link);
      link.click();
      link.remove();
      // in case the Blob uses a lot of memory
      setTimeout(() => URL.revokeObjectURL(link.href), 300);
    }).catch(function (error) {
      setPdfLoading(false);
    });
  };
  return (
    <div className={classes.root}>
      <Snackbar
        open={isSuccessAssignLead}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        autoHideDuration={3000}
        onClose={() => dispatch(clearAssignLeadState())}
      >
        <Alert
          onClose={() => dispatch(clearAssignLeadState())}
          severity={isSuccessAssignLead ? "success" : "error"}
          variant="filled"
        >
          {errorMsgAssignLead}
        </Alert>
      </Snackbar>
      {/* {isFetchingAgentLead && (
        <Loading isOpen={isFetchingAgentLead} />
      )}
      {isErrorAgentLead && (
        <AlertBox severity="error">{errorMsgAgentLead}</AlertBox>
      )} */}
      {/* <Box component={Paper} className={classes.container}> */}
      <Grid item xs={12} style={{ padding: "8px 14px" }}>
        <Grid container spacing={2}>
          {isHOD && <Grid item>
            <Grid container alignItems="center">
              <span className={`_1 ${classes.statusLegends}`}></span>
              <Typography style={{ fontSize: 12 }}>Unassigned</Typography>
            </Grid>
          </Grid>}
          <Grid item>
            <Grid container alignItems="center">
              <span className={`_2 ${classes.statusLegends}`}></span>
              <Typography style={{ fontSize: 12 }}>Assigned</Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems="center">
              <span className={`_3 ${classes.statusLegends}`}></span>
              <Typography style={{ fontSize: 12 }}>Challan Uploaded</Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems="center">
              <span className={`_4 ${classes.statusLegends}`}></span>
              <Typography style={{ fontSize: 12 }}>Challan Approved</Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems="center">
              <span className={`_5 ${classes.statusLegends}`}></span>
              <Typography style={{ fontSize: 12 }}>Challan Rejected</Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems="center">
              <span className={`_6 ${classes.statusLegends}`}></span>
              <Typography style={{ fontSize: 12 }}>Receipt Uploaded</Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems="center">
              <span className={`_7 ${classes.statusLegends}`}></span>
              <Typography style={{ fontSize: 12 }}>NOC Generated</Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems="center">
              <span className={`_8 ${classes.statusLegends}`}></span>
              <Typography style={{ fontSize: 12 }}>Receipt Rejected</Typography>
            </Grid>
          </Grid>
          {/* <Grid item>
            <Grid container alignItems="center">
              <span className={`_8 ${classes.statusLegends}`}></span>
              <Typography style={{ fontSize: 12 }}>Not Connected</Typography>
            </Grid>
          </Grid> */}
          {/* <Grid item>
            <Grid container alignItems="center">
              <span className={`_9 ${classes.statusLegends}`}></span>
              <Typography style={{ fontSize: 12 }}>Connected</Typography>
            </Grid>
          </Grid> */}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item sm>
          <Box p={1} marginRight={1}>
            <EnhancedTableToolbar numSelected={selected.length} selected={selected} managerList={managerList} />
            <TableContainer component={Paper} square>
              {/* <TablePagination
                  className={classes.tablePaginationCon}
                  // rowsPerPageOptions={[25, 50, 100, { label: 'All', value: -1 }]}
                  rowsPerPageOptions={[25, 50, 100]}
                  labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count !== -1 ? count : `more than ${to}`}`}
                  component="div"
                  count={count}
                  rowsPerPage={rowsPerPage}
                  page={page - 1}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                /> */}
              <Table
                size="small"
                className="tableView"
                aria-labelledby="tableTitle"
                aria-label="enhanced table"
              >
                <TableHead>
                  <StyledTableRow>
                    {selectedStatus == 1 && <StyledTableCell style={{ borderLeft: "12px solid #0038C0" }} align="center"></StyledTableCell>}
                    <StyledTableCell align="center" style={{ borderLeft: selectedStatus !== 1 ? "12px solid #0038C0" : "" }}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        Application Date&nbsp;
                        {selectedSort === "dsc" ? (
                          <IconButton
                            size="small"
                            style={{ color: "#fff" }}
                            title="Sort by ascending"
                            onClick={() => setSelectedSort("asc")}
                          >
                            <ArrowDropUpIcon />
                          </IconButton>
                        ) : (
                          <IconButton
                            size="small"
                            style={{ color: "#fff" }}
                            title="Sort by descending"
                            onClick={() => setSelectedSort("dsc")}
                          >
                            <ArrowDropDownIcon />
                          </IconButton>
                        )}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell align="center">Allotee Name</StyledTableCell>
                    {/* <StyledTableCell align="center">Mobile No</StyledTableCell> */}
                    {isHOD && <StyledTableCell align="center">Assigned To</StyledTableCell>}
                    <StyledTableCell align="center">Property Type</StyledTableCell>
                    <StyledTableCell align="center">CC Date</StyledTableCell>
                    <StyledTableCell align="center">OC Date</StyledTableCell>
                    <StyledTableCell align="center">
                      Property PAN
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Property details
                    </StyledTableCell>
                    <StyledTableCell align="center">Action</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {console.log(storeLeadData, "dtatasts")}
                  {storeLeadData.map((row, index) => {
                    const isItemSelected = isSelected(row.FireNocLeadId);
                    const activeStep = row.ApplicantActiveStep;
                    const divideResult = activeStep / 15;
                    const Activeprogress =
                      Math.round(divideResult * 100) + "%";
                    const ActiveStep = stepper.find(
                      (step) => step.value == activeStep
                    );

                    return (
                      <StyledTableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={index}
                        selected={isItemSelected}
                      >
                        {selectedStatus == 1 && <StyledTableCell padding="checkbox"
                          className={`${classes.status} _${row.status}`}
                          title={statusList.find((status) => status.value == row.status)?.label || "--"}
                        >
                          <Checkbox
                            // disabled={isDisabled}
                            onClick={(event) => handleClick(event, row.FireNocLeadId)}
                            checked={isItemSelected}
                            color="primary"
                            style={{ color: row.status === 2 ? 'rgb(21 141 15 / 54%)' : '' }}
                          />
                        </StyledTableCell>}
                        <StyledTableCell
                          className={selectedStatus !== 1 && (`${classes.status} _${row.status}`)}
                          title={selectedStatus !== 1 && (statusList.find((status) => status.value == row.status)?.label || "--")}
                        >
                          {moment(row.bookingPaymentDate).format("DD-MMM-YYYY") || "--"}
                        </StyledTableCell>
                        <StyledTableCell align="center" title={row.name}>
                          <strong>{row.AlloteeName || "--"}</strong>
                        </StyledTableCell>
                        {/* <StyledTableCell align="center" title={row.mobileNo}>
                          <strong>{row.mobileNo || "--"}</strong>
                        </StyledTableCell> */}
                        {isHOD && <StyledTableCell align="center" title={row.mobileNo}>
                          {row?.AgentName || "--"}
                        </StyledTableCell>}
                        {/* <CategoryName key={index} row={row} /> */}
                        <StyledTableCell align="center" title="PropertyType">
                          {row.PropertyType || "--"}
                        </StyledTableCell>
                        <StyledTableCell align="center" title={`${row.CommencementDate} yrs`}>
                          {row.CommencementDate || "--"}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          title={row.OccupancyDate}
                        >
                          {row.OccupancyDate || "--"}
                        </StyledTableCell>
                        <StyledTableCell align="center" title={row?.AgentName}>
                          {row?.PropertyPanNo || "--"}
                        </StyledTableCell>
                        <StyledTableCell align="center" title={row?.AgentName}>
                          <CustomTooltip
                            arrow
                            enterTouchDelay={0}
                            title={
                              <DialogContent>
                                <Typography variant="subtitle">Node: <strong>{row?.PropertyNode}</strong></Typography>
                                <br />
                                <Typography variant="subtitle">Sector: <strong>{row?.PropertySector}</strong></Typography>
                                <br />
                                <Typography variant="subtitle">Plot No: <strong>{row?.PropertyPlotNo}</strong></Typography>
                                <br />
                                <Typography variant="subtitle">Plot Area: <strong>{row?.PropertyPlotArea}</strong></Typography>
                                <br />
                                <Typography variant="subtitle">Provisional Fire NOC Date: <strong>{row?.ProvisionalFireNOCDate}</strong></Typography>
                                <br />
                                <Typography variant="subtitle">Final Fire NOC Date:<strong>{row?.FinalFireNOCDate}</strong></Typography>
                              </DialogContent>
                            }
                            style={{
                              display: "inline-block",
                              paddingLeft: "12px",
                              lineHeight: "10px",
                            }}
                          >
                            <InfoIcon color="primary" fontSize="large" />
                          </CustomTooltip>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Box
                            display="flex"
                            gridGap={4}
                            justifyContent="space-between"
                          >
                            {/* <IconButton
                              title="View Documents"
                              style={{ color: "#f12502" }}
                              size="small"
                            >
                              <PhoneIcon />
                            </IconButton> */}
                            {isHOD === true ? (
                              <IconButton
                                title="View Challan"
                                style={{ color: "#f12502" }}
                                size="small"
                                onClick={() => {
                                  setLeadData(row);
                                  localStorage.setItem("applicantId", row.ApplicantId);
                                  localStorage.setItem("requestId", row.RequestId);
                                  dispatch(getSummaryDetails())
                                  setDialogOpen(true)

                                }}
                              >
                                <VisibilityIcon />
                              </IconButton>
                            ) : (
                              <IconButton
                                title="Upload Challan"
                                style={{ color: "#f12502" }}
                                size="small"
                                onClick={() => {
                                  setLeadData(row);
                                  localStorage.setItem("applicantId", row.ApplicantId);

                                  setShowDocument(true);
                                }}
                              >
                                <PublishIcon />
                              </IconButton>
                            )}
                            <IconButton
                              title="View Documents"
                              style={{ color: "#f12502" }}
                              size="small"
                              onClick={() => {
                                // setLeadData(row);

                                downloadPdf(row?.RequestId);
                                //setShowDocument(true);
                              }}
                            >
                              <InsertDriveFileIcon />
                            </IconButton>
                            {/* <IconButton
                              title="Status & Comment"
                              style={{ color: "#f12502" }}
                              size="small"
                              onClick={() => {
                                setLeadData(row);
                                SetOpenCommentDialog(true);
                              }}
                            >
                              <ChatTwoToneIcon />
                            </IconButton> */}
                            {isHOD === true ? (
                              <IconButton
                                title="View Challan Receipt"
                                style={{ color: "#f12502" }}
                                size="small"
                                onClick={() => {
                                  setLeadData(row);
                                  localStorage.setItem("applicantId", row.ApplicantId);
                                  localStorage.setItem("requestId", row.RequestId);
                                  dispatch(getChalanReceipt())
                                  
                                  setDialog2Open(true)  

                                }}
                              >
                                <ReceiptIcon />
                              </IconButton>
                            ) : (
                              <IconButton
                                title="Status & Comment"
                                style={{ color: "#f12502" }}
                                size="small"
                                onClick={() => {
                                  setLeadData(row);
                                  SetOpenCommentDialog(true);
                                }}
                              >
                                <ChatTwoToneIcon />
                              </IconButton>
                            )}
                            {/* <IconButton
                              title="View & Edit"
                              size="small"
                              style={{ color: "#f12502" }}
                              onClick={() => {
                                setLeadData(row);
                                setOpenLeadViewDialog(true);
                              }}
                            >
                              <EditTwoToneIcon />
                            </IconButton> */}
                          </Box>
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                  {/* {emptyRows > 0 && (
                      <TableRow style={{ height: (53) * emptyRows }}>
                        <TableCell colSpan={5} />
                      </TableRow>
                    )} */}
                </TableBody>
              </Table>
              {storeLeadData.length === 0 && (
                <Box marginBottom={1} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                  <img
                    src={DataNoteFoundIcon}
                    width={150}
                    alt="No Data Found"
                  />
                  <Typography variant="h6">No Records Found !!</Typography>
                </Box>
              )}
              <TablePagination
                // rowsPerPageOptions={[25, 50, 100, { label: 'All', value: -1 }]}
                // className={classes.tablePaginationCon}
                rowsPerPageOptions={[25, 50, 100]}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} of ${count !== -1 ? count : `more than ${to}`
                  }`
                }
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableContainer>
          </Box>
        </Grid>
        {agentProfile && openCommentDialog && (
          <BankLeadComment
            filterCategoryData={filterCategoryData}
            openCommentDialog={openCommentDialog}
            onClose={handleCommentClose}
            leadData={leadData}
            agentProfile={agentProfile}
            isHOD={isHOD}
          />
        )}
        {/* <BankLeadDocumentDialog open={showDocument} data={leadData} onClose={handleShowDocumentClose} /> */}
        <UploadChallanDialog open={showDocument} dataaa={leadData} onClose={handleShowDocumentClose} />
        <ViewChallanData open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          data={data}
          lead={leadData}
        />
       <ViewChallanReceipt open={dialog2Open}
          onClose={() => setDialog2Open(false)}
          data={receiptData}
          lead={leadData} />
      </Grid>
    </div>
  );
};

export default BankLeadTable;
