import * as React from "react";
import PropTypes from "prop-types";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { makeStyles } from "@material-ui/core/styles";
import * as yup from "yup";
import styles from "./BankComment.module.css";
import { Button, Dialog, DialogTitle, DialogActions, Slide, DialogContent, Typography, IconButton, Box, Grid, Divider, Avatar, Badge, withStyles, Snackbar, CircularProgress, Hidden } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import clsx from "clsx";
import BankCommnetHistory from "./BankCommentHistory";
import FormControl from "../../../molecules/FormControl/FormControl";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../atoms/Loading/Loading";
import { Alert } from "@material-ui/lab";
import TextField from '@material-ui/core/TextField';
import CustomDateTimePicker from "../../../molecules/FormControl/components/CustomDatePicker/custonDateAndTimePicker";
import moment from "moment";
import { truncateContent } from "../../../../utils/UtilsFunction";
import { addBankLeadComment, bankLeadSelector, clearBankAddCommentState, clearBankChangeStatusState, getBankLeadComment, updateBankLeadStatus } from "../../../../redux/features/agent/AgentBankLeadSlice";

const StyledBadge = withStyles((theme) => ({
    badge: {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: '$ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}))(Badge);

const useStyles = makeStyles((theme) => ({
    dialog: {
        '& .MuiDialog-paperScrollPaper': {
            maxHeight: 'calc(100% - 30px)'
        },
    },
    dialogActions: {
        justifyContent: "center",
        padding: theme.spacing(2),
        paddingBottom: theme.spacing(3),
    },

    dialogBoxTitle: {
        padding: theme.spacing(1.5),
        display: "flex",
        justifyContent: "space-between",
        "& .MuiDialogTitle-root": {
            padding: 0,
        },
        "& h2": {
            color: "#007AE7",
            fontSize: "1.5rem",
            fontWeight: "bold",
        },
    },
    dotView: {
        backgroundColor: "#E63626",
        borderRadius: "50%",
        display: "inline-block",
        width: 10,
        height: 10,
        marginLeft: theme.spacing(1.4),
    },
    dialogContentSec: {
        display: "flex",
        padding: theme.spacing(0, 4.5, 2),
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            padding: theme.spacing(1),
        }
    },
    commentHistory: {
        overflow: "auto",
        background: "#668bcb",
        borderRadius: theme.spacing(1),
        margin: theme.spacing(1.5),
        "&::-webkit-scrollbar-track": {
            background: "rgb(255 255 255 / 55%)",
        },
        "&::-webkit-scrollbar-thumb": {
            background: "linear-gradient(180deg, #0038C0 0%, #006FD5 100%)",
        },
    },
    commentAvatar: {
        backgroundColor: "#ff5722",
        width: 36,
        height: 36
    },
    textField: {
        width: "100%",
    },
}));

const statusList = [
    {
      value: 1,
      label: "Unassigned Application",
    },
    {
      value: 2,
      label: "Assigned Application",
    },
    {
      value: 3,
      label: "Challan Uploaded",
    },
    {
      value: 4,
      label: "Challan Approved",
    },
    {
      value: 5,
      label: "Challan Rejected",
    },
    {
      value: 6,
      label: "Receipt Uploaded",
    },
    {
      value: 7,
      label: "NOC Generated",
    },
    {
      value: 8,
      label: "Receipt Rejected",
    },
  ];

const managerList = [
    {
        "value": "54",
        "label": "Deepak Muniraj"
    },
    {
        "value": "55",
        "label": "Sandesh Dalvi"
    },
    {
        "value": "56",
        "label": "Kishor Sharma"
    },
    {
        "value": "57",
        "label": "Sachin More"
    }
]

function TransitionLeft(props) {
    return <Slide {...props} direction="right" />;
}

function getInitials(name) {
    const words = name.split(' ');
    let initials = '';

    for (let i = 0; i < Math.min(2, words.length); i++) {
        if (words[i].length > 0) {
            initials += words[i][0].toUpperCase();
        }
    }

    return initials;
}

const MyAvatar = ({ name }) => {
    const initials = getInitials(name);
    const classes = useStyles();
    return (
        <Avatar className={classes.commentAvatar}>
            {initials}
        </Avatar>
    );
};

const useDynamicHeightField = (element, value) => {
    useEffect(() => {
        if (!element || !element.current) return;

        element.current.style.height = "auto";
        element.current.style.height = element.current.scrollHeight + "px";
    }, [element, value]);
};

const INITIAL_HEIGHT = 46;

export default function BankLeadComment(props) {
    const { openCommentDialog, onClose, leadData, filterCategoryData, agentProfile, isHOD } = props;
    const { t } = useTranslation("AgentProfilePageTrans");
    const formikRef = React.useRef();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isExpanded, setIsExpanded] = useState(false);
    const [commentValue, setCommentValue] = useState("");
    const [remainingChars, setRemainingChars] = useState(280);
    const [allCommentData, setAllCommentData] = useState([]);
    const [statusvalue, setStatusValue] = useState({ status: "", assignedTo: "" });

    const { isFetchingBankComment,
        isSuccessBankComment,
        isErrorBankComment,
        errorMsgBankComment,
        bankCommentData,

        isFetchingBankAddComment,
        isSuccessBankAddComment,
        isErrorBankAddComment,
        errorMsgBankAddComment,
        bankAddCommentData,

        isFetchingChangeBankStatus,
        isSuccessChangeBankStatus,
        isErrorChangeBankStatus,
        errorMsgChangeBankStatus,
        changeStatusBankData } = useSelector(bankLeadSelector)

    useEffect(() => {
        if (leadData) {
            dispatch(getBankLeadComment(leadData.BankLeadId))
        }
    }, [leadData])

    useEffect(() => {
        if (!openCommentDialog) {
            setIsExpanded(false);
            setCommentValue("");
            setRemainingChars(280);
        }
    }, [openCommentDialog]);

    useEffect(() => {
        if (formikRef.current && isSuccessBankComment && bankCommentData) {
            setAllCommentData(bankCommentData.Comments);
            let status = {
                status: bankCommentData.Status,
                // callBackTime: new Date(),
                callBackTime: bankCommentData.callBackTime,
                siteVisitTime: bankCommentData.siteVisitTime
            }
            setStatusValue(status)
            formikRef.current.setFieldValue("status", bankCommentData.Status);
            formikRef.current.setFieldValue("callBackTime", bankCommentData.callBackTime);
            formikRef.current.setFieldValue("siteVisitTime", bankCommentData.siteVisitTime);
        }
    }, [formikRef, bankCommentData, isSuccessBankComment])

    const outerHeight = useRef(INITIAL_HEIGHT);
    const textRef = useRef(null);
    const containerRef = useRef(null);
    useDynamicHeightField(textRef, commentValue);

    const onExpand = () => {
        if (!isExpanded) {
            outerHeight.current = containerRef.current.scrollHeight;
            setIsExpanded(true);
        }
    };

    const onChange = (e) => {
        const newValue = e.target.value;
        setCommentValue(newValue);
        const remaining = 280 - newValue.length;
        setRemainingChars(remaining);
    };


    const onCloseBox = () => {
        setCommentValue("");
        setIsExpanded(false);
        setRemainingChars(280);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (commentValue && leadData) {
            let data = {
                "AgentCode": localStorage.getItem('agentId'),
                "LeadId": leadData.BankLeadId,
                "Comment": commentValue
            }
            dispatch(addBankLeadComment(data));
        }
    };

    useEffect(() => {
        if (isSuccessBankAddComment) {
            setCommentValue("");
            dispatch(getBankLeadComment(leadData.BankLeadId))
        }
    }, [isSuccessBankAddComment])

    const onSubmitStatus = (values, { setSubmitting }) => {
        setSubmitting(false);
        if (values && leadData) {
            let data = {
                "LeadId": leadData.BankLeadId,
                "Status": values.status,
                "assignedTo": values.assignedTo
            }
            dispatch(updateBankLeadStatus(data));
        }
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => { dispatch(clearBankAddCommentState()); dispatch(clearBankChangeStatusState()) }}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <>
            <Dialog
                open={openCommentDialog}
                maxWidth="md"
                fullWidth
                className={classes.dialog}
            >
                {(isFetchingBankComment) && <Loading isOpen={(isFetchingBankComment)} />}
                <Snackbar open={isSuccessBankAddComment}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    autoHideDuration={5000}
                    TransitionComponent={TransitionLeft}
                    onClose={() => dispatch(clearBankAddCommentState())}
                    action={action} >
                    <Alert onClose={() => dispatch(clearBankAddCommentState())} severity="success" variant="filled">
                        {errorMsgBankAddComment}
                    </Alert>
                </Snackbar>
                <Snackbar open={isSuccessChangeBankStatus}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    autoHideDuration={5000}
                    TransitionComponent={TransitionLeft}
                    onClose={() => dispatch(clearBankChangeStatusState())}
                    action={action} >
                    <Alert onClose={() => dispatch(clearBankChangeStatusState())} severity="success" variant="filled">
                        {errorMsgChangeBankStatus}
                    </Alert>
                </Snackbar>
                <Snackbar open={isErrorChangeBankStatus}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    autoHideDuration={5000}
                    TransitionComponent={TransitionLeft}
                    onClose={() => dispatch(clearBankChangeStatusState())}
                    action={action} >
                    <Alert onClose={() => dispatch(clearBankChangeStatusState())} severity="error" variant="filled">
                        {errorMsgChangeBankStatus}
                    </Alert>
                </Snackbar>
                <Grid className={classes.dialogBoxTitle}>
                    <Hidden smDown>
                        <Grid item >
                            {leadData.name && <Typography variant="body2">Lead Name : <strong>{truncateContent(leadData.name, 25)}</strong></Typography>}
                            {leadData.mobileNo && <Typography variant="body2">Mobile No.  : <strong>{leadData.mobileNo}</strong></Typography>}
                        </Grid>
                    </Hidden>
                    <DialogTitle id="alert-dialog-title">
                        Comment & Status
                        <Hidden mdUp>
                            <Grid item >
                                {leadData.name && <Typography variant="body2">Lead Name : <strong>{truncateContent(leadData.name, 25)}</strong></Typography>}
                                {leadData.mobileNo && <Typography variant="body2">Mobile No.  : <strong>{leadData.mobileNo}</strong></Typography>}
                            </Grid>
                        </Hidden>
                    </DialogTitle>
                    <IconButton onClick={() => onClose(false)}>
                        <CloseIcon color="error" />
                    </IconButton>
                </Grid>
                <Divider variant="middle" />
                <DialogContent className={classes.dialogContentSec}>
                    <Grid container direction="column" md={5} xs={12}>
                        <form
                            onSubmit={onSubmit}
                            ref={containerRef}
                            className={clsx(styles["comment-box"], {
                                [styles.expanded]: isExpanded,
                                [styles.collapsed]: !isExpanded,
                                [styles.modified]: commentValue.length > 0
                            })}
                            style={{
                                minHeight: isExpanded ? outerHeight.current : INITIAL_HEIGHT
                            }}
                        >
                            <div className={styles.header}>
                                <div className={styles.user}>
                                    <StyledBadge
                                        overlap="circular"
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        variant="dot"
                                    >
                                        <MyAvatar name={agentProfile?.FirstName} />
                                    </StyledBadge>
                                    <span style={{ fontWeight: 600, marginLeft: 10 }}>{agentProfile?.FirstName}</span>
                                </div>
                            </div>
                            {/* <label className={styles.commentLabel} htmlFor="comment">Make a Comment</label> */}
                            <textarea
                                ref={textRef}
                                onClick={onExpand}
                                onFocus={onExpand}
                                onChange={onChange}
                                className={styles["comment-field"]}
                                placeholder="Make a Comment"
                                value={commentValue}
                                name="comment"
                                id="comment"
                                maxLength={280}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        e.preventDefault();
                                    }
                                }}
                            />
                            <div className={styles.actions} style={{ justifyContent: commentValue ? "space-between" : "flex-end" }}>
                                {commentValue && <p style={{ color: remainingChars == 0 ? "rgb(244, 67, 54)" : "grey", fontStyle: "italic", fontSize: 11 }}><strong>{remainingChars}</strong> characters remaining</p>}
                                <Grid item>
                                    <Button onClick={onCloseBox} color="primary" size="small" style={{ minWidth: 0, marginRight: 10 }}>
                                        Cancel
                                    </Button>
                                    <Button type="submit" variant="contained" size="small" color="primary" disabled={(commentValue.length < 1 || isFetchingBankAddComment)}>
                                        {isFetchingBankAddComment && <CircularProgress size={20} style={{ marginRight: "10px" }} />}
                                        {!isFetchingBankAddComment && <>Comment</>}
                                        {isFetchingBankAddComment && <>Saving...</>}
                                    </Button>
                                </Grid>
                            </div>
                        </form>
                        <Grid item>
                            <Typography variant="h6">Mark Status & Assign</Typography>
                            <Formik
                                initialValues={statusvalue}
                                onSubmit={onSubmitStatus}
                                innerRef={formikRef}
                            >
                                {({ submitForm, setFieldValue, values }) => (
                                    <Form noValidate autoComplete="off">
                                        <Grid container xs={12}>
                                            <FormControl
                                                margin="dense"
                                                control="selectbox"
                                                variant="outlined"
                                                label="Status"
                                                name="status"
                                                id="status"
                                                options={statusList}
                                                className={`${classes.filterInputBox} filterInputs`}
                                            />
                                        </Grid>
                                        {isHOD && <Grid container xs={12}>
                                            <FormControl
                                                margin="dense"
                                                control="selectbox"
                                                variant="outlined"
                                                label="Assign to"
                                                name="assignedTo"
                                                id="assignedTo"
                                                options={managerList}
                                                className={`${classes.filterInputBox} filterInputs`}
                                            />
                                        </Grid>}
                                        <Box textAlign="right" paddingTop={0.5}>
                                            <Button onClick={submitForm} autoFocus variant="contained" color="primary" size="small" disabled={isFetchingChangeBankStatus}>
                                                {isFetchingChangeBankStatus && <CircularProgress size={20} style={{ marginRight: "10px" }} />}
                                                {!isFetchingChangeBankStatus && <>Save</>}
                                                {isFetchingChangeBankStatus && <>Saving...</>}
                                            </Button>
                                        </Box>
                                    </Form>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>
                    {allCommentData && <Grid item md={7} xs={12} className={classes.commentHistory}>
                        <BankCommnetHistory allCommentData={allCommentData} />
                    </Grid>}
                </DialogContent>
            </Dialog>
        </>
    );
}
