import React from "react";
import AgentLayout from "../AgentLayout/AgentLayout";
import ManageBankLead from "../../organisms/AgentManageBankLeads/ManageBankLead";

const AgentManageBankLeadPage = () => {
  return (
    <AgentLayout width='xl'>
      <ManageBankLead />
    </AgentLayout>
  );
};

export default AgentManageBankLeadPage;