// import {
//     Box,
//     Button,
//     Dialog,
//     DialogContent,
//     DialogTitle,
//     Grid,
//     IconButton,
//     Typography,
// } from "@material-ui/core";
// import React from "react";
// import CloseIcon from "@material-ui/icons/Close";

// // You can import or define custom styles as needed
// import { useTranslation } from "react-i18next";
// import { initialPagesStyles } from "../../InitialPagesComponents/InitialPages.styles";

// export default function ViewChallanData(props) {
//     const { open, onClose, data, onApprove, onReject } = props;
//     const classes = initialPagesStyles();
//     const { t } = useTranslation("InitialPageTrans");

//     // const renderFilePreview = () => {
//     //     if (data.challanFile) {
//     //         const fileType = data.challanFile.type;
//     //         if (fileType.startsWith("image/")) {
//     //             return (
//     //                 <Box textAlign="center" mt={2}>
//     //                     <img
//     //                         src={URL.createObjectURL(data.challanFile)}
//     //                         alt="File Preview"
//     //                         style={{ maxWidth: "100%", maxHeight: 400 }}
//     //                     />
//     //                 </Box>
//     //             );
//     //         } else if (fileType === "application/pdf") {
//     //             return (
//     //                 <Box textAlign="center" mt={2}>
//     //                     <iframe
//     //                         src={URL.createObjectURL(data.challanFile)}
//     //                         style={{ width: "100%", height: 500 }}
//     //                         title="File Preview"
//     //                     />
//     //                 </Box>
//     //             );
//     //         } else {
//     //             return (
//     //                 <Typography variant="body2" color="textSecondary" mt={2}>
//     //                     {t("Unsupported File ")}
//     //                 </Typography>
//     //             );
//     //         }
//     //     } else {
//     //         return (
//     //             <Typography variant="body2" color="textSecondary" mt={2}>
//     //                 {t("No File Selected")}
//     //             </Typography>
//     //         );
//     //     }
//     // };
//     const renderFilePreview = () => {
//         if (data.challanFile) {
//             return (
//                 <Box textAlign="center" mt={2}>
//                     {/* Attempt to display the content directly */}
//                     <img
//                         src={data.challanFile}
//                         alt="File Preview"
//                         style={{ maxWidth: "100%", maxHeight: 400 }}
//                         onError={(e) => {
//                             // If the image fails to load, fall back to iframe
//                             e.target.style.display = 'none'; // Hide the img tag
//                             document.getElementById('pdfViewer').style.display = 'block'; // Show the iframe
//                         }}
//                     />
//                     {/* <iframe
//                         id="pdfViewer"
//                         src={data.challanFile}
//                         style={{ width: "100%", height: 250, display: 'none' }}
//                         title="File Preview"
//                         onLoad={(e) => {
//                             // If iframe loads successfully, hide the image
//                             document.querySelector('img').style.display = 'none'; // Hide the img tag
//                             e.target.style.display = 'block'; // Show the iframe
//                         }}
//                     /> */}
//                 </Box>
//             );
//         } else {
//             return (
//                 <Typography variant="body2" color="textSecondary" mt={2}>
//                     {t("No File Selected")}
//                 </Typography>
//             );
//         }
//     };
    

//     return (
//         <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
//             <DialogTitle className={classes.dialogBoxTitle}>
//                 <Typography variant="h2">View Data</Typography>
//                 <IconButton
//                     className={classes.closeButton}
//                     onClick={onClose}
//                     size="small"
//                 >
//                     <CloseIcon />
//                 </IconButton>
//             </DialogTitle>
//             <DialogContent dividers>
//                 <Grid container spacing={2}>
//                     <Grid item xs={12}>
//                         <Typography variant="h6">Data Details</Typography>
//                         <Box mt={2}>
//                             <Typography variant="body1">
//                                 <strong>Amount Due:</strong> {data.amountDue}
//                             </Typography>
//                             <Typography variant="body1">
//                                 <strong>Amount Date:</strong> {data.amountDate}
//                             </Typography>
//                             {/* Add more data fields as needed */}
//                         </Box>
//                     </Grid>
//                     <Grid item xs={12}>
//                         <Typography variant="h6" mt={4}>
//                             Challan Preview
//                         </Typography>
//                         {renderFilePreview()}
//                     </Grid>
//                 </Grid>
//                 <Box mt={3} display="flex" justifyContent="flex-end">
//                     <Button
//                         variant="contained"
//                         color="primary"
//                         style={{ backgroundColor: "green", color: "white", marginRight: 8 }}
//                         onClick={onApprove}
//                     >
//                         {t("Approve")}
//                     </Button>
//                     <Button
//                         variant="contained"
//                         color="secondary"
//                         style={{ backgroundColor: "red", color: "white" }}
//                         onClick={onReject}
//                     >
//                         {t("Reject")}
//                     </Button>
//                 </Box>
//             </DialogContent>
//         </Dialog>
//     );
// }

import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from "@material-ui/core";
import React, { useEffect } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { useTranslation } from "react-i18next";
import { initialPagesStyles } from "../../InitialPagesComponents/InitialPages.styles";
import { useDispatch, useSelector } from "react-redux";
import { AuthorizedLisionDetailed, PmayNonPmaySelector } from "../../../../redux/features/pmayNonPmay/pmayNonPmaySlice";

export default function ViewChallanData(props) {
    const { open, onClose, data,lead  } = props;
    const classes = initialPagesStyles();
    const { t } = useTranslation("InitialPageTrans");
    const dispatch=useDispatch();
    const { isFetchingAuthorizedLisionDetail, isSuccessAuthorizedLisionDetail ,isErrorAuthorizedLisionDetail,errorMsgAuthorizedLisionDetail} = useSelector(PmayNonPmaySelector);
    
    useEffect(() => {
        if (isSuccessAuthorizedLisionDetail) {
            onClose();
            window.location.reload();  // This will refresh the page
        }
    }, [isSuccessAuthorizedLisionDetail, onClose]);
    
    const renderFilePreview = () => {
        if (data.challanFile) {
            return (
                <Box textAlign="center" mt={2}>
                    {/* <img
                        src={data.challanFile}
                        alt="File Preview"
                        style={{ maxWidth: "100%", maxHeight: 400 }}
                        onError={(e) => {
                            // If the image fails to load, fall back to iframe
                            e.target.style.display = 'none'; // Hide the img tag
                            document.getElementById('pdfViewer').style.display = 'block'; // Show the iframe
                        }}
                    /> */}
                     <iframe
                        id="pdfViewer"
                        src={data.challanFile}
                        style={{ width: "100%", height: 250, display: 'none' }}
                        title="File Preview"
                        onLoad={(e) => {
                            // If iframe loads successfully, hide the image
                            document.querySelector('img').style.display = 'none'; // Hide the img tag
                            e.target.style.display = 'block'; // Show the iframe
                        }}
                    />
                  
                </Box>
            );
        } else {
            return (
                <Typography variant="body2" color="textSecondary" mt={2}>
                    {t("No File Selected")}
                </Typography>
            );
        }
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle className={classes.dialogBoxTitle}>
                <Typography variant="h2">View Challan Detail</Typography>
                <IconButton
                    className={classes.closeButton}
                    onClick={onClose}
                    size="small"
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="body2">Due Date</Typography>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableBody>
                                <TableRow style={{ backgroundColor: "rgb(29 255 113 / 45%)" }}>
                                        <TableCell><strong>{t("Amount To Pay")}</strong></TableCell>
                                        <TableCell>{data.amountDue}</TableCell>
                                        <TableCell><strong>{t("Due date")}</strong></TableCell>
                                        <TableCell>{data.amountDate}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2" mt={4}>
                            Challan Preview
                        </Typography>
                        {renderFilePreview()}
                    </Grid>
                </Grid>
                <Box mt={3} display="flex" justifyContent="flex-end">
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ backgroundColor: "green", color: "white", marginRight: 8 }}
                        onClick={() => {
                            const values = {
                                RequestId: lead.RequestId,
                                isApproved: '1'
                            };
                            dispatch(AuthorizedLisionDetailed(values));
                        }}
                    >
                        {t("Approve")}
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        style={{ backgroundColor: "red", color: "white" }}
                        onClick={() => {
                            const values = {
                                RequestId: lead.RequestId,
                                isApproved: '0'
                            };
                            dispatch(AuthorizedLisionDetailed(values));
                        }}
                    >
                        {t("Reject")}
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
}
