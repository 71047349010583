import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import withWidth from "@material-ui/core/withWidth";
import PropTypes from "prop-types";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { CSVIcon, DownloadIcon, DownloadPrimaryIcon } from "../../../atoms/SvgIcons/SvgIcons";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { useSelector, useDispatch } from "react-redux";
import CardMedia from "@material-ui/core/CardMedia";
import CameraAltOutlinedIcon from "@material-ui/icons/CameraAltOutlined";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { ApiEndPoint, InfoWebSiteUrl } from "../../../../utils/Common";
import { AgentLeadViewsStyles } from "../AgentLeadViews.styles";
import {
  AgentMaleIcon,
  AgentFemaleIcon,
  AgentOtherIcon,
} from "../../../atoms/SvgIcons/SvgIcons";
import Image from "../../../../assets/Profile.jpg";
import Loading from "../../../atoms/Loading/Loading";
import AlertBox from "../../../atoms/AlertBox/AlertBox";
import ImageDialogBox from "../../../molecules/DialogBoxes/ImageDialogBox/ImageDialogBox";
import CloseIcon from '@material-ui/icons/Close';
import {
  uploadAgentLeadData,
  getAgentLeadData,
  clearAgentLeadState,
  clearAgentLeadData,
  agentLeadSelector,
  clearUploadMessage,
  clearAgentUploadLeadResponse,
  clearAddLeadState,
  clearUpdateRes,
} from "../../../../redux/features/agent/AgentLeadSlice";
import AgentLeadFilter from "../AgentLeadFilter/AgentLeadFilter";
import AgentLeadTable from "../AgentLeadTable/AgentLeadTable";
import { ButtonGroup, Chip, List, ListItem, ListItemText, Slide, Snackbar, TableBody, TableCell, TableRow } from "@material-ui/core";
import AgentLeadDetailCard from "../AgentLeadDetailcard/AgentLeadDetailcard";
import { agentProfileSelector, clearGetAgentData, getAgentProfileData } from "../../../../redux/features/agent/AgentProfileSlice";
import { getReservationCategories, masterDataSelector } from "../../../../redux/features/masterdata/MasterDataSlice";
import _ from 'lodash';
import { Alert } from "@material-ui/lab";
import { agentApplicantSelector, getAgentReservationCategories } from "../../../../redux/features/agent/AgentApplicantsSlice";
import { green } from "@material-ui/core/colors";
import AgentAddLeadDialogBox from "../../../molecules/DialogBoxes/AgentLeadViewDialogBox/AgentAddLead";

function TransitionLeft(props) {
  return <Slide {...props} direction="right" />;
}

const AgentUploadLeadView = (props) => {
  const { width } = props;
  const { t } = useTranslation("AgentLeadPageTrans");
  const classes = AgentLeadViewsStyles();
  const dispatch = useDispatch();
  const {
    agentLeadData,
    updateAgentLeadData,
    isFetchingAgentLead,
    isSuccessResAgentLead,
    isErrorAgentLead,
    errorMsgAgentLead,
    isSuccessResUploadAgentLead,
    isErrorUploadAgentLead,
    isErroruploadAgentLead,
    errorMessageUploadpdateLead,
    isFetchingUploadAgentLead,
    errorMessageUploadLead,
    agentUploadLeadResponse,
    successMsgUploadLead,
    agentLeadFilePath
  } = useSelector(agentLeadSelector);

  // const { isSuccessResUpdateAgentProfilePhoto, updateagentProfilePhotoData } =
  //   useSelector(agentProfileSelector);
  /* const [applicationStatus, setApplicationStatus] = useState("");
  const [appliedApplicationStatus, setAppliedApplicationStatus] = useState("");
  const [searchedText, setSearchedText] = useState("");*/
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSort, setSelectedSort] = useState("dsc");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [notificationsSendIs, setNotificationsSendIs] = useState(false);
  const [storeLeadData, setStoreLeadData] = useState([]);
  const history = useHistory();
  const [openPhotoDialog, setOpenPhotoDialog] = useState(false);
  const isNotifyFirstRender = useRef(true);
  const isImageUrlFirstRender = useRef(true);
  const [searchedText, setSearchedText] = React.useState("");
  const [clear, setClear] = React.useState(false);
  const [leadsCounts, setLeadsCount] = useState();
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [agentProfile, setAgentProfile] = useState(null);
  const { isFetchingAddLead, isSuccessAddLead, isErrorAddLead, errorMsgAddLead, addLeadData, alreadyExist } = useSelector(agentLeadSelector);
  const { isFetchingAgentProfile, agentProfileData, isSuccessResAgentProfile } = useSelector(agentProfileSelector);
  const [filterCategoryData, setFilterCategoryData] = useState([]);
  const [allLeadData, setAllLeadData] = useState();
  const [team, setTeam] = React.useState([]);


  const {
    allCategory,
    isSuccessMasterData
  } = useSelector(agentApplicantSelector);

  React.useEffect(() => {
    dispatch(getAgentReservationCategories());
    dispatch(getAgentProfileData())
    return () => {
      dispatch(clearGetAgentData())
    }
  }, []);

  useEffect(() => {
    if (isSuccessMasterData && allCategory) {
      const uniqueData = _.uniqBy(allCategory, 'value');
      setFilterCategoryData(uniqueData);
    }
  }, [t, isSuccessMasterData, allCategory])

  React.useEffect(() => {
    if (isSuccessResAgentProfile && agentProfileData.AgentDetails.length > 0) {
      setAgentProfile(agentProfileData?.AgentDetails[0]);
    }
  }, [isSuccessResAgentProfile, agentProfileData]);

  useEffect(() => {
    if (agentProfile) {
      if (agentProfile.source === 'bank') {
        history.push('/cfc-applicants-analytics-dashboard')
      }
    }
  }, [agentProfile]);

  const handleListItemClick = (event, index) => {
    setSelectedStatus(index);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    setPage(1);
  }, [searchedText]);


  useEffect(() => {
    getAppDetailsWithFilter();
    dispatch(getAgentLeadData());
    // setPage(0);
  }, [t, searchedText, selectedCategory, selectedSort, selectedStatus, team, page, rowsPerPage]);

  useEffect(() => {
    if (isSuccessResAgentLead) {
      setStoreLeadData(agentLeadData.CustomerList);
      setAllLeadData(agentLeadData);
      setLeadsCount(agentLeadData.Total);
    }
  }, [agentLeadData, isSuccessResAgentLead]);

  useEffect(() => {
    if (isSuccessResUploadAgentLead) {
      getAppDetailsWithFilter();
      dispatch(getAgentLeadData());
      setPage(1);
    }
  }, [isSuccessResUploadAgentLead, updateAgentLeadData]);

  const getAppDetailsWithFilter = () => {
    let params = "";
    if (rowsPerPage) {
      params += "?perpage=" + rowsPerPage;
    }
    if (page) {
      params += "&page=" + page;
    }
    if (searchedText) {
      params += "&search=" + searchedText;
    }
    if (selectedCategory) {
      params += "&category=" + selectedCategory;
    }
    if (selectedSort) {
      params += "&sortby=" + selectedSort;
    }
    if (selectedStatus || selectedStatus == 0) {
      params += "&status=" + selectedStatus;
    }
    if (team.length > 0) {
      params += "&agent_ids=" + team
    }
    localStorage.setItem("agentLeadApiParam", params);
    window.scroll(
      {
        top: 100,
        left: 100,
        behavior: "smooth",
      });
    // dispatch(getAgentLeadData());
  };


  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => dispatch(clearAgentUploadLeadResponse())}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className={classes.root}>
      {isFetchingAgentLead && <Loading isOpen={isFetchingAgentLead} />}
      {isErrorAgentLead && (
        <AlertBox severity="error">{errorMsgAgentLead}</AlertBox>
      )}
      {/* <Snackbar open={agentUploadLeadResponse}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        autoHideDuration={5000}
        TransitionComponent={TransitionLeft}
        onClose={() => dispatch(clearAgentUploadLeadResponse())}
        action={action} >

      </Snackbar> */}
      <Snackbar open={agentUploadLeadResponse}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        autoHideDuration={5000}
        TransitionComponent={TransitionLeft}
        onClose={() => dispatch(clearAgentUploadLeadResponse())}
        action={action} >
        <Box padding={1}>
          {(typeof errorMessageUploadLead == 'object' && !_.isEmpty(errorMessageUploadLead)) &&
            <Alert onClose={() => dispatch(clearAgentUploadLeadResponse())} severity="warning" variant="filled" style={{ marginBottom: 8 }}>
              {Object.keys(errorMessageUploadLead).map((error) => (
                <>{errorMessageUploadLead[error]}</>
              ))}
            </Alert>}
          <Alert onClose={() => dispatch(clearAgentUploadLeadResponse())} severity={isErrorUploadAgentLead || successMsgUploadLead === 'No Records Inserted Duplicate records found.' || successMsgUploadLead === 'No Records Inserted' ? "error" : "success"} variant="filled">
            {successMsgUploadLead}
          </Alert>
        </Box>
      </Snackbar>
      <Snackbar open={isSuccessAddLead}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        autoHideDuration={4000}
        TransitionComponent={TransitionLeft}
        onClose={() => dispatch(clearAddLeadState())}>
        <Alert onClose={() => dispatch(() => dispatch(clearAddLeadState()))} severity={alreadyExist?.length === 0 ? "success" : "error"} variant="filled">
          {alreadyExist?.length === 0 ? errorMsgAddLead : `Lead With mobile number ${alreadyExist[0]?.mobileNo} already exist`}
        </Alert>
      </Snackbar>
      <Paper elevation={4}>
        <div className={classes.container}>
          <Box className={classes.dataContainer}>
            <Grid xs={3} item className={`${classes.leadHeader} innerSection`}>
              <Grid container className={classes.innerGrid}>
                {/* {Object.keys(agentProfileData).length != 0 && agentProfileData?.AgentDetails[0].UserType !== 'CSMO' && */}
                <Grid item>
                  <Typography
                    variant="h6"
                    className={classes.sectionCardTitle}
                    style={{ margin: "10px" }}
                  >
                    Manage Leads
                  </Typography>
                </Grid>
                <List component="nav" className={classes.NavList}>
                  <ListItem
                    button
                    selected={selectedStatus === ''}
                    className={selectedStatus === '' ? 'active' : ''}
                    onClick={(event) => handleListItemClick(event, '')}
                  >
                    <ListItemText primary="Total Leads" />
                    <Chip label={allLeadData?.AllLeadsCount || 0} size='small' color="primary" className={`${classes.chipColor}`} />
                  </ListItem>
                  <ListItem
                    button
                    selected={selectedStatus === 0}
                    className={selectedStatus === 0 ? 'active' : ''}
                    onClick={(event) => handleListItemClick(event, 0)}
                  >
                    <ListItemText primary="Unattended" />
                    <Chip label={allLeadData?.StatusWiseCount[0]?.LeadCount || 0} size='small' color="primary" className={`${classes.chipColor} _0`} />
                  </ListItem>
                  <ListItem
                    button
                    selected={selectedStatus === 1}
                    className={selectedStatus === 1 ? 'active' : ''}
                    onClick={(event) => handleListItemClick(event, 1)}
                  >
                    <ListItemText primary="Call Back (warm)" />
                    <Chip label={allLeadData?.StatusWiseCount[1]?.LeadCount || 0} size='small' color="primary" className={`${classes.chipColor} _1`} />
                  </ListItem>
                  <ListItem
                    button
                    selected={selectedStatus === 3}
                    className={selectedStatus === 3 ? 'active' : ''}
                    onClick={(event) => handleListItemClick(event, 3)}
                  >
                    <ListItemText primary="Call Success (warm)" />
                    <Chip label={allLeadData?.StatusWiseCount[3]?.LeadCount || 0} size='small' color="primary" className={`${classes.chipColor} _3`} />
                  </ListItem>
                  <ListItem
                    button
                    selected={selectedStatus === 4}
                    className={selectedStatus === 4 ? 'active' : ''}
                    onClick={(event) => handleListItemClick(event, 4)}
                  >
                    <ListItemText primary="Call Not Picked (warm)" />
                    <Chip label={allLeadData?.StatusWiseCount[4]?.LeadCount || 0} size='small' color="primary" className={`${classes.chipColor} _4`} />
                  </ListItem>
                  <ListItem
                    button
                    selected={selectedStatus === 8}
                    className={selectedStatus === 8 ? 'active' : ''}
                    onClick={(event) => handleListItemClick(event, 8)}
                  >
                    <ListItemText primary="Site Visit (Hot)" />
                    <Chip label={allLeadData?.StatusWiseCount[8]?.LeadCount || 0} size='small' color="primary" className={`${classes.chipColor} _8`} />
                  </ListItem>
                  <ListItem
                    button
                    selected={selectedStatus === 5}
                    className={selectedStatus === 5 ? 'active' : ''}
                    onClick={(event) => handleListItemClick(event, 5)}
                  >
                    <ListItemText primary="Not Intrested (Cold)" />
                    <Chip label={allLeadData?.StatusWiseCount[5]?.LeadCount || 0} size='small' color="primary" className={`${classes.chipColor} _5`} />
                  </ListItem>
                </List>
                {/* } */}
                {/* {Object.keys(agentProfileData).length != 0 && agentProfileData?.AgentDetails[0].UserType !== 'CSMO' && */}

                {/* {allLeadData && <AgentLeadDetailCard
                  storeLeadData={storeLeadData}
                  leadsCounts={leadsCounts}
                  allLeadData={allLeadData}
                  selectedStatus={selectedStatus}
                  setSelectedStatus={setSelectedStatus}
                />} */}
              </Grid>
            </Grid>
            <Grid item style={{ marginLeft: '25%' }}>
              {filterCategoryData && <AgentLeadFilter
                searchedText={searchedText}
                setSearchedText={setSearchedText}
                setSelectedCategory={setSelectedCategory}
                selectedCategory={selectedCategory}
                setSelectedSort={setSelectedSort}
                setSelectedStatus={setSelectedStatus}
                setClear={setClear}
                clear={clear}
                setSelectedTab={setSelectedTab}
                filterCategoryData={filterCategoryData}
                selectedStatus={selectedStatus}
                team={team}
                setTeam={setTeam}
                setPage={setPage}
              />}
              {((isSuccessResAgentLead || isSuccessResUploadAgentLead)) && (
                <AgentLeadTable
                  storeLeadData={storeLeadData}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  page={page}
                  setPage={setPage}
                  setSelectedSort={setSelectedSort}
                  selectedSort={selectedSort}
                  filterCategoryData={filterCategoryData}
                  agentProfile={agentProfile}
                  selectedStatus={selectedStatus}
                  count={leadsCounts}
                />
              )}
            </Grid>
          </Box>
        </div>
      </Paper>
    </div>
  );
};

export default withWidth()(AgentUploadLeadView);
