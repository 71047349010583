

import { Button, Typography, Grid, FormControl, InputLabel, Select, MenuItem, TextField, Box, CircularProgress } from "@material-ui/core";
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Loading from "../../atoms/Loading/Loading";
import { FileUploadIcon } from '../../atoms/SvgIcons/SvgIcons';
import UploadedKyc from "../../../assets/UploadedKyc.svg";
import { useFormik } from "formik";
import {
    applicantSelector, getOfficeCenters, searchByPinCode, clearApplicantData, getApplicant,
} from "../../../redux/features/applicant/ApplicantSlice";
import { clearImageUrl, fileDataUpload, fileUploadSelector } from "../../../redux/features/file/FileUploadSlice";
import { MortgagePropertyDetails, PmayNonPmaySelector } from "../../../redux/features/pmayNonPmay/pmayNonPmaySlice";
import { initialPagesStyles } from "../InitialPagesComponents/InitialPages.styles";
import { SupportedFormats } from "../../../utils/Common";
import AlertBox from "../../atoms/AlertBox/AlertBox";

const AddNew = () => {

    const formikRef = useRef();
    const { t } = useTranslation("InitialPageTrans");
    const classes = initialPagesStyles();
    const history = useHistory();
    const dispatch = useDispatch();


    const { isFileFetching, isFileSuccess, imageUrl, isFileError, fileErrorMessage } = useSelector(fileUploadSelector);
    const { summaryData, isSuccessResSummary } = useSelector(PmayNonPmaySelector);
    const {
        isFetchingAppointmentData,
        isSuccessAppointmentCancel,
        applicantData,
        isFetchingApplicantGet,
        isSuccessResApplicantGet,
        isSuccessResOfflineCenter,
        OfflineCenterData,
        isFetchingOfflineCenter,
        isSuccessSearchCode,
        isErrorSearchCode,
        errorMsgSearchCode,
        SearchCodeData,
    } = useSelector(applicantSelector);
    const { isFetchingMortgageDetails,
        isSuccessMortageDetails,


        isErrorMortageDetails,
        errorMsgMortageDetails,
        propertyData, } = useSelector(PmayNonPmaySelector)
    const [isImageA, setIsImageA] = useState(false);
    const [isImageB, setIsImageB] = useState(false);
    const [isImageC, setIsImageC] = useState(false);
    const [isImageD, setIsImageD] = useState(false);
    const [isImageE, setIsImageE] = useState(false);
    const [approvedPlanAUrl, setApprovedPlanAUrl] = useState('');
    const [approvedPlanBUrl, setApprovedPlanBUrl] = useState('');
    const [approvedPlanCUrl, setApprovedPlanCUrl] = useState('');
    const [approvedPlanDUrl, setApprovedPlanDUrl] = useState('');
    const [approvedPlanEUrl, setApprovedPlanEUrl] = useState('');
    const [approvalPlanAPreview, setApprovalPlanAPreview] = useState(null);
    const [approvalPlanBPreview, setApprovalPlanBPreview] = useState(null);
    const [approvalPlanCPreview, setApprovalPlanCPreview] = useState(null);
    const [approvalPlanDPreview, setApprovalPlanDPreview] = useState(null);
    const [approvalPlanEPreview, setApprovalPlanEPreview] = useState(null);
    const [uploadingField, setUploadingField] = useState('');
    const fileInputRefA = useRef(null);
    const fileInputRefB = useRef(null);
    const fileInputRefC = useRef(null);
    const fileInputRefD = useRef(null);
    const fileInputRefE = useRef(null);
    const formik = useFormik({
        initialValues: {
            allotteeName: '',
            propertyType: '',
            panCard: '',
            node: '',
            sector: '',
            plotNo: '',
            plotArea: '',
            formB: null,
            ccDate: '',
            ccPic: null,
            ocDate: '',
            ocPic: null,
            provisionalFireNOCDate: '',
            provisionalFireNOCPic: null,
            finalFireNOCDate: '',
            finalFireNOCPic: null,
        },
        onSubmit: values => {
            const data = {
                ...values,

                formB: approvedPlanAUrl,
                ccPic: approvedPlanBUrl,
                ocPic: approvedPlanCUrl,
                provisionalFireNOCPic: approvedPlanDUrl,
                finalFireNOCPic: approvedPlanEUrl,
            }
            console.log(data);
            dispatch(MortgagePropertyDetails(data));
        },
    });



    function hasImageExtension(url) {
        if (!url) {
            return false;
        }
        const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webp', '.tiff'];
        return imageExtensions.some(extension => url.toLowerCase().endsWith(extension));
    }

    // Usage


    // useEffect(() => {
    //     if (isSuccessResSummary && summaryData) {
    //         const proApprovedPlanDocA = summaryData.DocumentDetails.formB;
    //         const proApprovedPlanDocB = summaryData.DocumentDetails.ccPic;
    //         const proApprovedPlanDocC = summaryData.DocumentDetails.ocPic;
    //         const proApprovedPlanDocD = summaryData.DocumentDetails.provisionalFireNOCPic;
    //         const proApprovedPlanDocE = summaryData.DocumentDetails.finalFireNOCPic;

    //         if (hasImageExtension(proApprovedPlanDocA)) {
    //             setIsImageA(true);
    //             setApprovalPlanAPreview(proApprovedPlanDocA);
    //         } else {
    //             setIsImageA(false);
    //         }

    //         if (hasImageExtension(proApprovedPlanDocB)) {
    //             setIsImageB(true);
    //             setApprovalPlanBPreview(proApprovedPlanDocB);
    //         } else {
    //             setIsImageB(false);
    //         }

    //         if (hasImageExtension(proApprovedPlanDocC)) {
    //             setIsImageC(true);
    //             setApprovalPlanCPreview(proApprovedPlanDocC);
    //         } else {
    //             setIsImageC(false);
    //         }

    //         if (hasImageExtension(proApprovedPlanDocD)) {
    //             setIsImageD(true);
    //             setApprovalPlanDPreview(proApprovedPlanDocD);
    //         } else {
    //             setIsImageD(false);
    //         }

    //         if (hasImageExtension(proApprovedPlanDocE)) {
    //             setIsImageE(true);
    //             setApprovalPlanEPreview(proApprovedPlanDocE);
    //         } else {
    //             setIsImageE(false);
    //         }
    //     }
    // }, [summaryData]);




    const onReUpload = (fieldName) => {
        if (fieldName === 'formB' && fileInputRefA.current) {
            fileInputRefA.current.click();
        } else if (fieldName === 'ccPic' && fileInputRefB.current) {
            fileInputRefB.current.click();
        } else if (fieldName === 'ocPic' && fileInputRefC.current) {
            fileInputRefC.current.click();
        } else if (fieldName === 'provisionalFireNOCPic' && fileInputRefD.current) {
            fileInputRefD.current.click();
        } else if (fieldName === 'finalFireNOCPic' && fileInputRefE.current) {
            fileInputRefE.current.click();
        }
    };


    useEffect(() => {
        if (isFileSuccess && imageUrl) {
            if (uploadingField === 'formB') {
                setApprovedPlanAUrl(imageUrl);
            } else if (uploadingField === 'ccPic') {
                setApprovedPlanBUrl(imageUrl);

            } else if (uploadingField === 'ocPic') {
                setApprovedPlanCUrl(imageUrl);
            } else if (uploadingField === 'provisionalFireNOCPic') {
                setApprovedPlanDUrl(imageUrl);
            } else if (uploadingField === 'finalFireNOCPic') {
                setApprovedPlanEUrl(imageUrl);
            }
            dispatch(clearImageUrl());
            setUploadingField('');
        }
    }, [isFileSuccess, imageUrl, uploadingField, dispatch]);

    useEffect(() => {
        if (isSuccessMortageDetails) {
            dispatch(clearImageUrl());
            history.push('/dashboard')
        }
    }, [isSuccessMortageDetails, dispatch]);

    const handleFileChange = (event, fieldName) => {
        const file = event.currentTarget.files[0];
        if (file) {
            setUploadingField(fieldName);
            formik.setFieldValue(fieldName, file);
            const data = { doc: file, docName: fieldName };
            dispatch(fileDataUpload(data));

            if (fieldName === 'formB') {
                if (SupportedFormats.DocsFormats.includes(file.type)) {
                    switch (file.type) {
                        case "image/jpeg":
                        case "image/png":
                            setIsImageA(true);
                            break;
                        case "application/pdf":
                        default:
                            setIsImageA(false);
                    }
                }
                setApprovalPlanAPreview(URL.createObjectURL(file));
            } else if (fieldName === 'ccPic') {
                if (SupportedFormats.DocsFormats.includes(file.type)) {
                    switch (file.type) {
                        case "image/jpeg":
                        case "image/png":
                            setIsImageB(true);
                            break;
                        case "application/pdf":
                        default:
                            setIsImageB(false);
                    }
                }
                setApprovalPlanBPreview(URL.createObjectURL(file));
            } else if (fieldName === 'ocPic') {
                if (SupportedFormats.DocsFormats.includes(file.type)) {
                    switch (file.type) {
                        case "image/jpeg":
                        case "image/png":
                            setIsImageC(true);
                            break;
                        case "application/pdf":
                        default:
                            setIsImageC(false);
                    }
                }
                setApprovalPlanCPreview(URL.createObjectURL(file));
            } else if (fieldName === 'provisionalFireNOCPic') {
                if (SupportedFormats.DocsFormats.includes(file.type)) {
                    switch (file.type) {
                        case "image/jpeg":
                        case "image/png":
                            setIsImageD(true);
                            break;
                        case "application/pdf":
                        default:
                            setIsImageD(false);
                    }
                }
                setApprovalPlanDPreview(URL.createObjectURL(file));
            } else if (fieldName === 'finalFireNOCPic') {
                if (SupportedFormats.DocsFormats.includes(file.type)) {
                    switch (file.type) {
                        case "image/jpeg":
                        case "image/png":
                            setIsImageE(true);
                            break;
                        case "application/pdf":
                        default:
                            setIsImageE(false);
                    }
                }
                setApprovalPlanEPreview(URL.createObjectURL(file));
            }

            console.log(`File uploaded for ${fieldName}:`, file);
        }
    };


    return (
        <>
            {(isFetchingApplicantGet || isFetchingOfflineCenter) && (
                <Loading isOpen={isFetchingApplicantGet || isFetchingOfflineCenter} />
            )}
            {isErrorMortageDetails && (
                <AlertBox severity="error">{errorMsgMortageDetails}</AlertBox>
            )}
            {isFileError && (
                <AlertBox severity="error">{fileErrorMessage}</AlertBox>
            )}
            <div className={classes.mainContainer} style={{ height: "100%" }}>
                <Grid container className="header">
                    <Button variant="text" style={{ flexGrow: 1, justifyContent: "start" }} onClick={() => history.push("/dashboard")}>
                        <ArrowBackIcon />
                    </Button>
                    {applicantData &&
                        <Typography variant="h6" className="pageTitle">
                            Application For Fire NOC
                        </Typography>
                    }
                </Grid>
                <div className={classes.mainContainer} style={{ height: "100%", padding: 20 }}>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={1} style={{ display: 'flex', justifyContent: 'center', }}>
                            <Grid item xs={6}>
                                <FormControl fullWidth variant="outlined" margin="dense">
                                    <InputLabel id="propertyType-label">Select Property Type</InputLabel>
                                    <Select
                                        labelId="propertyType-label"
                                        id="propertyType"
                                        name="propertyType"
                                        value={formik.values.propertyType}
                                        onChange={formik.handleChange}
                                        label="Select Property Type"
                                    >
                                        <MenuItem value="Residential">Residential</MenuItem>
                                        <MenuItem value="Commercial">Commercial</MenuItem>
                                        <MenuItem value="Res+Comm">Residential + Commercial</MenuItem>
                                        <MenuItem value="Institutional">Institutional</MenuItem>
                                        <MenuItem value="Industrial">Industrial</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="allotteeName"
                                    name="allotteeName"
                                    label="Name of Allottee in CIDCO"
                                    value={formik.values.allotteeName}
                                    onChange={formik.handleChange}
                                    variant="outlined"
                                    margin="dense"
                                />
                            </Grid>




                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    id="node"
                                    name="node"
                                    label="Node"
                                    value={formik.values.node}
                                    onChange={formik.handleChange}
                                    variant="outlined"
                                    margin="dense"
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    id="sector"
                                    name="sector"
                                    label="Sector"
                                    value={formik.values.sector}
                                    onChange={formik.handleChange}
                                    variant="outlined"
                                    margin="dense"
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    id="plotNo"
                                    name="plotNo"
                                    label="Plot No."
                                    value={formik.values.plotNo}
                                    onChange={formik.handleChange}
                                    variant="outlined"
                                    margin="dense"
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    id="plotArea"
                                    name="plotArea"
                                    label="Plot Area (in SQ mtr.)"
                                    value={formik.values.plotArea}
                                    onChange={formik.handleChange}
                                    variant="outlined"
                                    margin="dense"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="panCard"
                                    name="panCard"
                                    label="PAN No"
                                    value={formik.values.panCard}
                                    onChange={formik.handleChange}
                                    variant="outlined"
                                    margin="dense"
                                />
                            </Grid>


                            <Grid item xs={6}>
                                <input
                                    ref={fileInputRefA}
                                    style={{ display: 'none' }}
                                    type="file"
                                    accept="image/*, application/pdf"
                                    id="formB"
                                    name="formB"
                                    onChange={(event) => handleFileChange(event, 'formB')}
                                />
                                <TextField
                                    fullWidth
                                    label='Upload Form B'
                                    variant="outlined"
                                    margin="dense"
                                    disabled
                                    InputLabelProps={{ shrink: true }}
                                    //onClick={() => fileInputRefA.current.click()}
                                    value={formik.values.formB}
                                    InputProps={{


                                        endAdornment: (
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                color="primary"
                                                onClick={() => fileInputRefA.current.click()}
                                            >
                                                Upload
                                            </Button>
                                        ),
                                    }}
                                />
                                <div style={{ marginTop: '2px', textAlign: 'right' }}>
                                    <Typography variant="body2">
                                        <a href="/path/to/sample-form-b.pdf" download>Download Sample Form B</a>
                                    </Typography>
                                </div>
                                {/* {(approvalPlanAPreview) && (
                                    <Box>
                                        <Typography className={classes.kycCaption} variant="subtitle1">
                                            Preview Form B
                                        </Typography>
                                        {isImageA ? (
                                            <Box className={classes.panCardPreviewCard}>
                                                <img
                                                    className={classes.panPreviewImg}
                                                    src={approvalPlanAPreview}
                                                    alt="uploaded successfully"
                                                />
                                            </Box>
                                        ) : (
                                            <Box textAlign="center" className={classes.panCardPreviewCard}>
                                                <img className={classes.panPreviewImg} src={UploadedKyc} alt="uploaded successfully" />
                                                <Typography className={classes.kycCaption}>
                                                    {t("kycBank.formControl.upload.success")}
                                                </Typography>
                                            </Box>
                                        )}
                                        <Box textAlign="left" marginTop={2}>
                                            <Typography className={classes.kycCaption} variant="subtitle1">
                                                Uploaded Form B
                                            </Typography>
                                        </Box>
                                        <Box className={classes.fileViewArea}>
                                            <Grid container spacing={1} alignItems="center">
                                                <Grid item xs="auto">
                                                    <FileUploadIcon color="primary" />
                                                </Grid>
                                                <Grid item xs zeroMinWidth>
                                                    <Typography variant="body2" className={classes.fileUrlPreview}>
                                                        {approvalPlanAPreview || "--"}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs="auto">
                                                    <Button
                                                        variant="text"
                                                        size="small"
                                                        color="primary"
                                                        onClick={() => onReUpload('formB')}
                                                    >
                                                        {t("kycBank.formControl.upload.reupload")}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Box>
                                )} */}
                                {approvalPlanAPreview && (
                                    <img src={approvalPlanAPreview} alt="Form B Preview" style={{ maxWidth: '100px', marginTop: '10px' }} />
                                )}
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    fullWidth
                                    id='ccDate'
                                    name='ccDate'
                                    label='CC Date'
                                    type="date"
                                    value={formik.values.ccDate}
                                    onChange={formik.handleChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    margin="dense"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <input
                                    ref={fileInputRefB}
                                    style={{ display: 'none' }}
                                    type="file"
                                    accept="image/*, application/pdf"
                                    id="ccPic"
                                    name="ccPic"
                                    onChange={(event) => handleFileChange(event, 'ccPic')}
                                />
                                <TextField
                                    fullWidth
                                    label='Upload Commencement Certificate'
                                    variant="outlined"
                                    margin="dense"
                                    InputLabelProps={{ shrink: true }}
                                    disabled
                                    //onClick={() => fileInputRefB.current.click()}
                                    value={formik.values.ccPic}
                                    InputProps={{
                                        endAdornment: (
                                            <Button
                                                variant="outlined"
                                                size="small"

                                                color="primary"
                                                onClick={() => fileInputRefB.current.click()}
                                            >
                                                Upload
                                            </Button>
                                        ),
                                    }}
                                />
                                {/* {approvalPlanBPreview && (
                                    <Box>
                                        <Typography className={classes.kycCaption} variant="subtitle1">
                                            Preview Commencement Certificate
                                        </Typography>
                                        {isImageB ? (
                                            <Box className={classes.panCardPreviewCard}>
                                                <img
                                                    className={classes.panPreviewImg}
                                                    src={approvalPlanBPreview}
                                                    alt="uploaded successfully"
                                                />
                                            </Box>
                                        ) : (
                                            <Box textAlign="center" className={classes.panCardPreviewCard}>
                                                <img className={classes.panPreviewImg} src={UploadedKyc} alt="uploaded successfully" />
                                                <Typography className={classes.kycCaption}>
                                                    {t("kycBank.formControl.upload.success")}
                                                </Typography>
                                            </Box>
                                        )}
                                        <Box textAlign="left" marginTop={2}>
                                            <Typography className={classes.kycCaption} variant="subtitle1">
                                                Uploaded Commencement Certificate
                                            </Typography>
                                        </Box>
                                        <Box className={classes.fileViewArea}>
                                            <Grid container spacing={1} alignItems="center">
                                                <Grid item xs="auto">
                                                    <FileUploadIcon color="primary" />
                                                </Grid>
                                                <Grid item xs zeroMinWidth>
                                                    <Typography variant="body2" className={classes.fileUrlPreview}>
                                                        {approvalPlanBPreview || "--"}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs="auto">
                                                    <Button
                                                        variant="text"
                                                        size="small"
                                                        color="primary"
                                                        onClick={() => onReUpload('ccPic')}
                                                    >
                                                        {t("kycBank.formControl.upload.reupload")}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Box>
                                )} */}
                                {approvalPlanBPreview && (
                                    <img src={approvalPlanBPreview} alt="Commencement Certificate Preview" style={{ maxWidth: '100px', marginTop: '10px' }} />
                                )}
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    fullWidth
                                    id='ocDate'
                                    name='ocDate'
                                    label='OC Date'
                                    type="date"
                                    value={formik.values.ocDate}
                                    onChange={formik.handleChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    margin="dense"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <input
                                    ref={fileInputRefC}
                                    style={{ display: 'none' }}
                                    type="file"
                                    accept="image/*, application/pdf"
                                    id="ocPic"
                                    name="ocPic"
                                    onChange={(event) => handleFileChange(event, 'ocPic')}
                                />
                                <TextField
                                    fullWidth
                                    label='Upload Occupancy Certificate'
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    margin="dense"
                                    disabled
                                    //onClick={() => fileInputRefB.current.click()}
                                    value={formik.values.ocPic}
                                    InputProps={{
                                        endAdornment: (
                                            <Button
                                                variant="outlined"
                                                size="small"

                                                color="primary"
                                                onClick={() => fileInputRefC.current.click()}
                                            >
                                                Upload
                                            </Button>
                                        ),
                                    }}
                                />
                                {/* {approvalPlanCPreview && (
                                    <Box>
                                        <Typography className={classes.kycCaption} variant="subtitle1">
                                            Preview Occupancy Certificate
                                        </Typography>
                                        {isImageC ? (
                                            <Box className={classes.panCardPreviewCard}>
                                                <img
                                                    className={classes.panPreviewImg}
                                                    src={approvalPlanCPreview}
                                                    alt="uploaded successfully"
                                                />
                                            </Box>
                                        ) : (
                                            <Box textAlign="center" className={classes.panCardPreviewCard}>
                                                <img className={classes.panPreviewImg} src={UploadedKyc} alt="uploaded successfully" />
                                                <Typography className={classes.kycCaption}>
                                                    {t("kycBank.formControl.upload.success")}
                                                </Typography>
                                            </Box>
                                        )}
                                        <Box textAlign="left" marginTop={2}>
                                            <Typography className={classes.kycCaption} variant="subtitle1">
                                                Uploaded Occupancy Certificate
                                            </Typography>
                                        </Box>
                                        <Box className={classes.fileViewArea}>
                                            <Grid container spacing={1} alignItems="center">
                                                <Grid item xs="auto">
                                                    <FileUploadIcon color="primary" />
                                                </Grid>
                                                <Grid item xs zeroMinWidth>
                                                    <Typography variant="body2" className={classes.fileUrlPreview}>
                                                        {approvalPlanCPreview || "--"}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs="auto">
                                                    <Button
                                                        variant="text"
                                                        size="small"
                                                        color="primary"
                                                        onClick={() => onReUpload('ocPic')}
                                                    >
                                                        {t("kycBank.formControl.upload.reupload")}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Box>
                                )} */}
                                {approvalPlanCPreview && (
                                    <img src={approvalPlanCPreview} alt="Occupancy Certificate Preview" style={{ maxWidth: '100px', marginTop: '10px' }} />
                                )}
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    fullWidth
                                    id='provisionalFireNOCDate'
                                    name='provisionalFireNOCDate'
                                    label='Pro Fire NOC Date'
                                    type="date"
                                    value={formik.values.provisionalFireNOCDate}
                                    onChange={formik.handleChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    margin="dense"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <input
                                    ref={fileInputRefD}
                                    style={{ display: 'none' }}
                                    type="file"
                                    accept="image/*, application/pdf"
                                    id="provisionalFireNOCPic"
                                    name="provisionalFireNOCPic"
                                    onChange={(event) => handleFileChange(event, 'provisionalFireNOCPic')}
                                />
                                <TextField
                                    fullWidth
                                    label='Upload Provisional Fire NOC'
                                    variant="outlined"
                                    margin="dense"
                                    InputLabelProps={{ shrink: true }}
                                    disabled
                                    //onClick={() => fileInputRefB.current.click()}
                                    value={formik.values.provisionalFireNOCPic}
                                    InputProps={{
                                        endAdornment: (
                                            <Button
                                                variant="outlined"
                                                size="small"

                                                color="primary"
                                                onClick={() => fileInputRefD.current.click()}
                                            >
                                                Upload
                                            </Button>
                                        ),
                                    }}
                                />
                                {/* {approvalPlanDPreview && (
                                    <Box>
                                        <Typography className={classes.kycCaption} variant="subtitle1">
                                            Preview Provisional Fire NOC
                                        </Typography>
                                        {isImageD ? (
                                            <Box className={classes.panCardPreviewCard}>
                                                <img
                                                    className={classes.panPreviewImg}
                                                    src={approvalPlanDPreview}
                                                    alt="uploaded successfully"
                                                />
                                            </Box>
                                        ) : (
                                            <Box textAlign="center" className={classes.panCardPreviewCard}>
                                                <img className={classes.panPreviewImg} src={UploadedKyc} alt="uploaded successfully" />
                                                <Typography className={classes.kycCaption}>
                                                    {t("kycBank.formControl.upload.success")}
                                                </Typography>
                                            </Box>
                                        )}
                                        <Box textAlign="left" marginTop={2}>
                                            <Typography className={classes.kycCaption} variant="subtitle1">
                                                Uploaded Provisional Fire NOC
                                            </Typography>
                                        </Box>
                                        <Box className={classes.fileViewArea}>
                                            <Grid container spacing={1} alignItems="center">
                                                <Grid item xs="auto">
                                                    <FileUploadIcon color="primary" />
                                                </Grid>
                                                <Grid item xs zeroMinWidth>
                                                    <Typography variant="body2" className={classes.fileUrlPreview}>
                                                        {approvalPlanDPreview || "--"}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs="auto">
                                                    <Button
                                                        variant="text"
                                                        size="small"
                                                        color="primary"
                                                        onClick={() => onReUpload('provisionalFireNOCPic')}
                                                    >
                                                        {t("kycBank.formControl.upload.reupload")}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Box>
                                )} */}
                                {approvalPlanDPreview && (
                                    <img src={approvalPlanDPreview} alt="Provisional Fire NOC Preview" style={{ maxWidth: '100px', marginTop: '10px' }} />
                                )}

                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    fullWidth
                                    id='finalFireNOCDate'
                                    name='finalFireNOCDate'
                                    label='Final Fire NOC Date'
                                    type="date"
                                    value={formik.values.finalFireNOCDate}
                                    onChange={formik.handleChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    margin="dense"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <input
                                    ref={fileInputRefE}
                                    style={{ display: 'none' }}
                                    type="file"
                                    accept="image/*, application/pdf"
                                    id="finalFireNOCPic"
                                    name="finalFireNOCPic"
                                    onChange={(event) => handleFileChange(event, 'finalFireNOCPic')}
                                />
                                <TextField
                                    fullWidth
                                    label='Upload Final Fire NOC'
                                    variant="outlined"
                                    margin="dense"
                                    InputLabelProps={{ shrink: true }}
                                    disabled
                                    //onClick={() => fileInputRefB.current.click()}
                                    value={formik.values.finalFireNOCPic}
                                    InputProps={{
                                        endAdornment: (
                                            <Button
                                                variant="outlined"
                                                size="small"

                                                color="primary"
                                                onClick={() => fileInputRefE.current.click()}
                                            >
                                                Upload
                                            </Button>
                                        ),
                                    }}
                                />
                                {/* {approvalPlanEPreview && (
                                    <Box>
                                        <Typography className={classes.kycCaption} variant="subtitle1">
                                            Preview Final Fire NOC
                                        </Typography>
                                        {isImageE ? (
                                            <Box className={classes.panCardPreviewCard}>
                                                <img
                                                    className={classes.panPreviewImg}
                                                    src={approvalPlanEPreview}
                                                    alt="uploaded successfully"
                                                />
                                            </Box>
                                        ) : (
                                            <Box textAlign="center" className={classes.panCardPreviewCard}>
                                                <img className={classes.panPreviewImg} src={UploadedKyc} alt="uploaded successfully" />
                                                <Typography className={classes.kycCaption}>
                                                    {t("kycBank.formControl.upload.success")}
                                                </Typography>
                                            </Box>
                                        )}
                                        <Box textAlign="left" marginTop={2}>
                                            <Typography className={classes.kycCaption} variant="subtitle1">
                                                Uploaded Final Fire NOC
                                            </Typography>
                                        </Box>
                                        <Box className={classes.fileViewArea}>
                                            <Grid container spacing={1} alignItems="center">
                                                <Grid item xs="auto">
                                                    <FileUploadIcon color="primary" />
                                                </Grid>
                                                <Grid item xs zeroMinWidth>
                                                    <Typography variant="body2" className={classes.fileUrlPreview}>
                                                        {approvalPlanEPreview || "--"}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs="auto">
                                                    <Button
                                                        variant="text"
                                                        size="small"
                                                        color="primary"
                                                        onClick={() => onReUpload('finalFireNOCPic')}
                                                    >
                                                        {t("kycBank.formControl.upload.reupload")}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Box>
                                )} */}
                                {approvalPlanEPreview && (
                                    <img src={approvalPlanEPreview} alt="Final Fire NOC Preview" style={{ maxWidth: '100px', marginTop: '10px' }} />
                                )}
                            </Grid>




                            <Grid container alignItems="flex-end" style={{ marginTop: 8 }} justifyContent="flex-end">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    size="large"

                                    disabled={isFetchingMortgageDetails}
                                >
                                    {isFetchingMortgageDetails && <CircularProgress size={20} style={{ marginRight: "10px" }} />}
                                    {isFetchingMortgageDetails ? 'Saving...' : 'Submit'}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </div>
        </>
    );
};

export default AddNew;
