import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { useTranslation } from "react-i18next";
import { initialPagesStyles } from "../../InitialPagesComponents/InitialPages.styles";
import { useDispatch, useSelector } from "react-redux";
import { AuthorizedLisionDetailed, EventPeriod, getChalanReceipt, PmayNonPmaySelector } from "../../../../redux/features/pmayNonPmay/pmayNonPmaySlice";

export default function ViewChallanReceipt(props) {
    const { open, onClose, data, lead } = props;
    const classes = initialPagesStyles();
    const { t } = useTranslation("InitialPageTrans");
    const dispatch = useDispatch();
    const {  isSuccessEventPeriod , isFetchingEventPeriod} = useSelector(PmayNonPmaySelector)


   
    
    useEffect(() => {
      if ( isSuccessEventPeriod) {
        onClose();
            window.location.reload();
      }
  }, [ isSuccessEventPeriod]);
  
 
    const renderFilePreview = () => {
        if (!data.challanreceipt) {
            return (
                <Typography variant="body2" color="textSecondary" mt={2}>
                    {t("No File Selected")}
                </Typography>
            );
        }

        const isImage = /\.(jpg|jpeg|png|gif)$/i.test(data.challanreceipt);
        const isPDF = /\.pdf$/i.test(data.challanreceipt);

        return (
            <Box textAlign="center" mt={2}>
                
                {isImage ? (
                    <img
                        src={data.challanreceipt}
                        alt="File Preview"
                        style={{ maxWidth: "100%", maxHeight: 400 }}
                        onError={(e) => {
                            e.target.style.display = 'none'; // Hide the image if it fails to load
                        }}
                    />
                ) : isPDF ? (
                    <iframe
                        src={data.challanreceipt}
                        style={{ width: "100%", height: 400 }}
                        title="File Preview"
                    />
                ) : (
                    <Typography variant="body2" color="textSecondary">
                        {t("Unsupported file format")}
                    </Typography>
                )}
            </Box>
        );
    };

    const handleApproval = (isApproved) => {
        const values = {
            RequestId: lead.RequestId,
            isApproved: isApproved ? '1' : '0',
        };
        dispatch(EventPeriod(values));
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle className={classes.dialogBoxTitle}>
                <Typography variant="h2">View Challan Receipt</Typography>
                <IconButton
                    className={classes.closeButton}
                    onClick={onClose}
                    size="small"
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="body2" mt={4}>
                            Challan Receipt Preview
                        </Typography>
                        {renderFilePreview()}
                    </Grid>
                </Grid>
                <Box mt={3} display="flex" justifyContent="flex-end">
                    <Button
                        variant="contained"
                        style={{ backgroundColor: "green", color: "white", marginRight: 8 }}
                        onClick={() => handleApproval(true)}
                    >
                        {t("Approve")}
                    </Button>
                    <Button
                        variant="contained"
                        style={{ backgroundColor: "red", color: "white" }}
                        onClick={() => handleApproval(false)}
                    >
                        {t("Reject")}
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
}
