import React from "react";
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, withStyles } from "@material-ui/core";
import moment from "moment";

const StyledTableCell = withStyles((theme) => ({
  head: {
    lineHeight: '1rem',
    backgroundColor: "#0038C0",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const ApplicantAnalyticsTable = ({ applicantWiseData }) => {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const indianRupeeFormat = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR'
  });

  return (
    <Box p={1} marginRight={1}>
      <TableContainer component={Paper}>
        <Table size="small" className="tableView" aria-labelledby="tableTitle" aria-label="enhanced table" >
          <TableHead>
            <StyledTableRow>
              <StyledTableCell component="th">Applicant Id</StyledTableCell>
              <StyledTableCell component="th">Name</StyledTableCell>
              <StyledTableCell component="th" align="center">Appl. Fee Status</StyledTableCell>
              <StyledTableCell component="th" align="right">Appl. Fee Amount</StyledTableCell>
              <StyledTableCell component="th" align="center">Doc Verify Complete</StyledTableCell>
              <StyledTableCell component="th" align="center">Flat Selected</StyledTableCell>
              <StyledTableCell component="th" align="right">Booking Amount</StyledTableCell>
              <StyledTableCell component="th" align="center">Registration Date</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {applicantWiseData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <StyledTableRow hover role="checkbox" tabIndex={-1} key={index} >
                    <StyledTableCell scope="row">
                      {row.ApplicantId || "--"}
                    </StyledTableCell>
                    <StyledTableCell scope="row">
                      {row.FirstName || "--"}
                    </StyledTableCell>
                    <StyledTableCell align="center" scope="row">
                      {row.ApplicationFeeStatus || '--'}
                    </StyledTableCell>
                    <StyledTableCell align="right" scope="row">
                      {indianRupeeFormat.format(row.ApplicationAmount) || "--"}
                    </StyledTableCell>
                    <StyledTableCell align="center" scope="row">
                      {row.DocVerified || '--'}
                    </StyledTableCell>
                    <StyledTableCell align="center" scope="row">
                      {row.FlatBooked || '--'}
                    </StyledTableCell>
                    <StyledTableCell align="right" scope="row">
                      {indianRupeeFormat.format(row.BookingAmount) || "00.00"}
                    </StyledTableCell>
                    <StyledTableCell align="center" scope="row">
                      {moment(row.RegistrationDate).format("MMM DD, YYYY")}
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })

            }
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={applicantWiseData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
        />
      </TableContainer>
    </Box>
  );
};

export default ApplicantAnalyticsTable;
