import React from "react";
import SwitchAlert from "../../organisms/SwitchAlertPagePagesComponents/SwitchAlert/SwitchAlert";
import NotificationPageLayout from "../Layout/NotificationPageLayout";

const SwitchAlertPage = () => {
  return (
    <NotificationPageLayout>
      <SwitchAlert />
    </NotificationPageLayout>
  );
};

export default SwitchAlertPage;
