//Handle api fail error
export const handleErrMsg = (payload) => {
  if (payload?.message === undefined || payload?.message === null) {
    return 'Something went wrong! Please try again later.';
  } else {
    return payload?.message;
  }
}

//Return money amount number with comma seprated - Indian format
export const numberWithCommas = (amount_val) => {
  return isNaN(amount_val)
    ? "0"
    : amount_val.toString().split(".")[0].length > 3
      ? amount_val
        .toString()
        .substring(0, amount_val.toString().split(".")[0].length - 3)
        .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
      "," +
      amount_val
        .toString()
        .substring(amount_val.toString().split(".")[0].length - 3)
      : amount_val.toString();
};


//turncate word within character limit followed by ...
export const truncateContent = (content, charLimit) => {
  if (content.length > charLimit) {
    return content.slice(0, charLimit) + '...';
  }
  return content;
};


//turncate string having multiple word within word limit followed by ...
export function truncateString(str, numWords) {
  if (str) {
    const words = str.split(" ");
    if (words.length > numWords) {
      return words.slice(0, numWords).join(" ") + " ...";
    }
  }
  return str;
};