import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndPoint } from "../../../utils/Common";
import { handleErrMsg } from "../../../utils/UtilsFunction";

// import { apiEndPoint } from "./ApplicantAuthSlice";

const ApiEndPointProfile = ApiEndPoint + "/AgentProfile/";

const apiurlofProfile = "http://localhost/rest-api/applicationservice/";

// export const headers = {
//   Authorization: localStorage.getItem("jwtToken"),
// };

/* export const editApplicant = createAsyncThunk(
  "applicant/editApplicant",
  async (agentApplicationsData, thunkAPI) => {
    try {
      const response = await axios.put(
        ApiEndPointApplicant + localStorage.getItem("applicantId"),
        agentApplicationsData
      );
      let responseData = await response.data;
      if (response.status === 201) {
        return responseData;
      } else {
        return thunkAPI.rejectWithValue(responseData);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
); */

export const getAgentList = createAsyncThunk(
  "agent/AgentList",
  async (requestData, thunkAPI) => {
    try {
      const response = await axios.get(
        ApiEndPointProfile + 'all_agents/' + localStorage.getItem("agentcode") + '?roles=' + requestData.roles + '&source=' + requestData.source
        //+ "?Lang=" + localStorage.getItem("i18nextLng")
        // { headers }
      );
      let responseData = await response.data;

      if (response.status === 200) {
        return responseData;
      } else {
        return thunkAPI.rejectWithValue(responseData);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getRolesForDropdown = createAsyncThunk(
  "agent/Roles",
  async (source, thunkAPI) => {
    try {
      const response = await axios.get(
        ApiEndPointProfile + 'getRoles/' + localStorage.getItem("agentcode") + '/' + source
      );
      let responseData = await response.data;

      if (response.status === 200) {
        return responseData;
      } else {
        return thunkAPI.rejectWithValue(responseData);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getAgentProfileData = createAsyncThunk(
  "agent/AgentProfile",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(
        ApiEndPointProfile + localStorage.getItem("agentcode")
        //+ "?Lang=" + localStorage.getItem("i18nextLng")
        // { headers }
      );
      let responseData = await response.data;

      if (response.status === 200) {
        return responseData;
      } else {
        return thunkAPI.rejectWithValue(responseData);
      }
    } catch (e) {
       return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateAgentProfilePhoto = createAsyncThunk(
  "agent/UpdateAgentProfilePhoto",
  async (updateData, thunkAPI) => {
    try {
      const response = await axios.post(
        ApiEndPoint + "/AgentFileUpload", updateData
      );
      let responseData = await response.data;
      if (response.status === 200) {
        return responseData;
      } else {
        return thunkAPI.rejectWithValue(responseData);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data)
    }
  }
);

export const updateAgentProfileInfo = createAsyncThunk(
  "agent/UpdateAgentProfile",
  async (updateData, thunkAPI) => {
    try {
      const response = await axios.put(
        ApiEndPointProfile + localStorage.getItem("agentcode"), updateData
        //+ "?Lang=" + localStorage.getItem("i18nextLng")
        // { headers }
      );
      let responseData = await response.data;

      if (response.status === 201) {
        return responseData;
      } else {
        return thunkAPI.rejectWithValue(responseData);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const agentProfileSlice = createSlice({
  name: "agentProfile",
  initialState: {
    isFetchingAgentProfile: false,
    isSuccessResAgentProfile: false,
    isSuccessReqAgentProfile: false,
    isErrorAgentProfile: false,
    errorMsgAgentProfile: "",
    agentProfileData: {},
    isFetchingUpdateAgentProfile: false,
    isFetchingUpdateAgentProfilePhoto: false,
    updateAgentProfileData: {},
    updateAgentProfilePhotoData: {},
    isSuccessResUpdateAgentProfile: false,
    isSuccessResUpdateAgentProfilePhoto: false,
    isErrorUpdateAgentProfile: false,
    errorMessageUpdateProfile: "",
    isErrorUpdateAgentProfilePhoto: false,
    errorMessageUpdateProfilePhoto: "",

    isFetchingAgentList: false,
    isSuccessResAgentList: false,
    isErrorAgentList: false,
    errorMsgAgentList: "",
    agentListData: [],

    isFetchingRoles: false,
    isSuccessRoles: false,
    isErrorRoles: false,
    errorMsgRoles: "",
    rolesData: [],
  },
  reducers: {
    clearAgentProfileState: (state) => {
      state.isFetchingUpdateAgentProfilePhoto = false;
      state.isSuccessResUpdateAgentProfilePhoto = false;
      state.isErrorUpdateAgentProfilePhoto = false;
      state.isFetchingAgentProfile = false;
      state.isSuccessResAgentProfile = false;
      state.isSuccessReqAgentProfile = false;
      state.isErrorAgentProfile = false;
      state.errorMsgAgentProfile = "";
      state.errorMessageUpdateProfilePhoto = "";
      state.isFetchingUpdateAgentProfile = false;
      state.isSuccessResUpdateAgentProfile = false;
      state.isErrorUpdateAgentProfile = false;
      state.errorMessageUpdateProfile = "";

      state.isFetchingAgentList = false;
      state.isSuccessResAgentList = false;
      state.isErrorAgentList = false;
      state.errorMsgAgentList = "";

      state.isFetchingRoles = false;
      state.isSuccessRoles = false;
      state.isErrorRoles = false;
      state.errorMsgRoles = "";
    },
    clearAgentProfileData: (state) => {
      state.agentProfileData = {};
      state.updateAgentProfileData = {};
      state.updateAgentProfilePhotoData = {};
      state.agentListData = [];
      state.rolesData = [];
    },
    clearGetAgentData : (state) =>{
      state.isFetchingAgentList = false;
      state.isSuccessResAgentList = false;
      state.isErrorAgentList = false;
      state.errorMsgAgentList = "";

      state.isFetchingRoles = false;
      state.isSuccessRoles = false;
      state.isErrorRoles = false;
      state.errorMsgRoles = "";

      state.agentListData = [];
      state.rolesData = [];
    }
  },
  extraReducers: {
    [getAgentProfileData.fulfilled]: (state, { payload }) => {
      state.isFetchingAgentProfile = false;
      state.agentProfileData = payload.data;
      state.isSuccessResAgentProfile = payload.success;
    },
    [getAgentProfileData.pending]: (state) => {
      state.isFetchingAgentProfile = true;
    },
    [getAgentProfileData.rejected]: (state, { payload }) => {
      state.isFetchingAgentProfile = false;
      state.isErrorAgentProfile = true;
      state.errorMsgAgentProfile = handleErrMsg(payload);
    },
    [updateAgentProfileInfo.fulfilled]: (state, { payload }) => {
      state.isFetchingUpdateAgentProfile = false;
      state.updateagentProfileData = payload.data;
      state.isSuccessResUpdateAgentProfile = payload.success;
    },
    [updateAgentProfileInfo.pending]: (state) => {
      state.isFetchingUpdateAgentProfile = true;
    },
    [updateAgentProfileInfo.rejected]: (state, { payload }) => {
      state.isFetchingUpdateAgentProfile = false;
      state.isErrorUpdateAgentProfile = true;
      state.errorMessageUpdateProfile = handleErrMsg(payload);
    },

    [updateAgentProfilePhoto.fulfilled]: (state, { payload }) => {
      state.isFetchingUpdateAgentProfilePhoto = false;
      state.updateagentProfilePhotoData = payload.data;
      state.isSuccessResUpdateAgentProfilePhoto = true;

    },
    [updateAgentProfilePhoto.pending]: (state) => {
      state.isFetchingUpdateAgentProfilePhoto = true;
    },
    [updateAgentProfilePhoto.rejected]: (state, { payload }) => {
      state.isFetchingUpdateAgentProfilePhoto = false;
      state.isErrorUpdateAgentProfilePhoto = true;
      state.errorMessageUpdateProfilePhoto = handleErrMsg(payload);
    },
    [getAgentList.pending]: (state) => {
      state.isFetchingAgentList = true;
    },
    [getAgentList.fulfilled]: (state, { payload }) => {
      state.isFetchingAgentList = false;
      state.isSuccessResAgentList = payload.success;
      state.agentListData = payload.data;
      state.isErrorAgentList = payload.error;
      state.errorMsgAgentList = payload.message;
    },
    [getAgentList.rejected]: (state, { payload }) => {
      state.isFetchingAgentList = false;
      state.isErrorAgentList = true;
      state.errorMsgAgentList = handleErrMsg(payload);
    },
    [getRolesForDropdown.fulfilled]: (state, { payload }) => {
      state.isFetchingRoles = false;
      state.isSuccessRoles = payload.success;
      state.rolesData = payload.data;
      state.isErrorRoles = payload.error;
      state.errorMsgRoles = payload.message;
    },
    [getAgentList.pending]: (state) => {
      state.isFetchingRoles = true;
    },
    [getRolesForDropdown.rejected]: (state, { payload }) => {
      state.isFetchingRoles = false;
      state.isErrorRoles = true;
      state.errorMsgRoles = handleErrMsg(payload);
    },
  },
});

export const { clearAgentProfileState, clearAgentProfileData, clearGetAgentData } =
  agentProfileSlice.actions;

export const agentProfileSelector = (state) => state.agentProfile;
