import * as React from "react";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { makeStyles } from "@material-ui/core/styles";
import * as yup from "yup";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
  Grid,
  Divider,
  InputAdornment,
  CircularProgress,
  DialogActions,
} from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';
import FormControl from "../../FormControl/FormControl";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { addAgentLead, agentLeadSelector } from "../../../../redux/features/agent/AgentLeadSlice";
import { useEffect } from "react";
import { Alert } from "@material-ui/lab";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  dialogActions: {
    justifyContent: "center",
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
  dialogBoxCloseBtn: {
    float: 'right'
  },
  dialogBoxTitle: {
    padding: theme.spacing(1),
    textAlign: "center",
    "& .MuiDialogTitle-root": {
      padding: 0,
    },
    "& h2": {
      color: "#007AE7",
      fontSize: "1.5rem",
      fontWeight: "bold",
    },
  },
  dotView: {
    backgroundColor: "#E63626",
    borderRadius: "50%",
    display: "inline-block",
    width: 10,
    height: 10,
    marginLeft: theme.spacing(1.4),
  },
  dialogContentSec: {
    minWidth: "450px",
    padding: theme.spacing(0, 7, 4.5),
  },
}));


const LeadField = [
  { value: "name", label: "Name" },
  { value: "dateOfVisit", label: "Date" },
  { value: "mobileNo", label: "Mobile No." },
  { value: "email", label: "Email" },
  { value: "category", label: "Category" },
  { value: "age", label: "Age" },
  { value: "budget", label: "Budget" },
  { value: "requirement", label: "Requirement" },
  { value: "currentHomeOwnership", label: "Current Home Ownership" },
  { value: "address", label: "Location" }
]

export default function AgentAddLeadDialogBox(props) {
  const { openAddLeadDialog, onClose, filterCategoryData } = props;
  const { t } = useTranslation("AgentProfilePageTrans");
  const dispatch = useDispatch();
  const formikRef = React.useRef();
  const classes = useStyles();
  const { isFetchingAddLead, isSuccessAddLead, isErrorAddLead, errorMsgAddLead, addLeadData, alreadyExist } = useSelector(agentLeadSelector);


  // function getInitialValues() {
  //   const initialValues = {};
  //   LeadField.forEach(field => {
  //     initialValues[field.value] = "";
  //   });
  //   console.log(initialValues);
  //   return initialValues;
  // }

  const initialvalues = {
    name: "",
    dateOfVisit: new Date(),
    mobileNo: "",
    email: "",
    category: "",
    age: "",
    budget: "",
    requirement: "",
    currentHomeOwnership: "",
    address: ""
  }

  const validationSchema = yup.object({
    name: yup
      .string()
      .required(t("personalInfoEdit.formControl.fullNameErrors.required")),
    mobileNo: yup
      .string()
      .matches(
        /^[6-9]\d{9}$/,
        t("personalInfoEdit.formControl.mobileNumberErrors.limitation")
      )
      .required(t("personalInfoEdit.formControl.mobileNumberErrors.required")),
    dateOfVisit: yup.string().nullable().required("Date is required"),
    // category: yup
    //   .string()
    //   .matches(
    //     /^([1-9]|1[0-3])$/,
    //     t("Please enter category from 1-13 only")
    //   )
    //   .required(t("category is required")),
    email: yup
      .string()
      .email(t("personalInfoEdit.formControl.emailIdErrors.limitation")),
    // .required(t("personalInfoEdit.formControl.emailIdErrors.required")),
    // currentAddress: yup
    //   .string()
    //   .required(
    //     t("personalInfoEdit.formControl.currentAddressErrors.required")
    //   ),
  });

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
    let SendArray = []
    const sendDate = {
      name: values.name,
      dateOfVisit: values.dateOfVisit ? moment(values.dateOfVisit).format('DD/MM/YYYY') : "",
      mobileNo: values.mobileNo,
      email: values.email,
      category: values.category,
      age: values.age,
      budget: values.budget,
      requirement: values.requirement,
      currentHomeOwnership: values.currentHomeOwnership,
      address: values.address
    }
    SendArray.push(sendDate);
    dispatch(addAgentLead(SendArray))
  };

  useEffect(() => {
    if (isSuccessAddLead && alreadyExist.length === 0) {
      onClose()
    }
  }, [isSuccessAddLead, alreadyExist.length])

  return (
    <>
      <Dialog
        open={openAddLeadDialog}
        // onClose={() => onClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title" className={classes.dialogBoxTitle}>
          Add Lead
          <IconButton
            className={classes.dialogBoxCloseBtn}
            onClick={() => onClose()}
          >
            <CloseOutlinedIcon />
          </IconButton>
        </DialogTitle>
        <Formik
          initialValues={initialvalues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          innerRef={formikRef}
        >
          {({ submitForm, setFieldValue, values }) => (
            <Form noValidate autoComplete="off">
              <DialogContent className={classes.dialogContentSec} dividers>
                <Grid container xs={12} spacing={2} >
                  {LeadField.map((item) => {
                    if (item.value === 'category') {
                      return (
                        <Grid item xs={12} md={6} key={item.value}>
                          <FormControl
                            control="selectbox"
                            variant="outlined"
                            label={item.label}
                            name={item.value}
                            id={item.value}
                            options={filterCategoryData}
                            className={`${classes.filterInputBox} filterInputs`}
                            margin='dense'
                          />
                        </Grid>
                      );
                    } else if (item.value === 'dateOfVisit') {
                      return (
                        <Grid item xs={12} md={6} key={item.value}>
                          <FormControl
                            control="datepicker"
                            name={item.value}
                            label={item.label}
                            placeholder={item.label}
                            onChange={(value) => {
                              setFieldValue(item.value, value);
                            }}
                            maxDate={new Date()}
                            inputVariant="outlined"
                            required
                            margin='dense'
                          />
                        </Grid>
                      );
                    }
                    else if (item.value === 'mobileNo') {
                      return (
                        <Grid item xs={12} md={6} key={item.value}>
                          <FormControl
                            control="input"
                            variant="outlined"
                            name={item.value}
                            label={item.label}
                            placeholder={item.label}
                            type="text"
                            id="mobileNumber"
                            required
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(/[^0-9]/gi, "");
                            }}
                            inputProps={{
                              maxLength: 10,
                            }}
                            margin='dense'
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <strong>+91 - </strong>{" "}
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      );
                    } else {
                      return (
                        <Grid item xs={12} md={6} key={item.value}>
                          <FormControl
                            control="input"
                            variant="outlined"
                            label={item.label}
                            placeholder={`Enter Client ${item.label}`}
                            name={item.value}
                            type="text"
                            id={item.value}
                            required={item.value === 'name'}
                            margin='dense'
                          />
                        </Grid>
                      );
                    }
                  })}
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => onClose()} color="primary">Cancel</Button>
                <Button type="submit" autoFocus variant="contained" startIcon={<SaveIcon />} color="primary" disabled={isFetchingAddLead}>
                  {isFetchingAddLead && <CircularProgress size={20} style={{ marginRight: "10px" }} />}
                  {!isFetchingAddLead && <>Save</>}
                  {isFetchingAddLead && <>Saving...</>}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}
