import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import withWidth from "@material-ui/core/withWidth";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import ClearIcon from '@material-ui/icons/Clear';
import { useSelector, useDispatch } from "react-redux";
import Loading from "../../../atoms/Loading/Loading";
import AlertBox from "../../../atoms/AlertBox/AlertBox";
import AgentApplicantCard from "../AgentApplicantCard/AgentApplicantCard";
import { getProjectsListData } from "../../../../redux/features/projectdata/ProjectDataSlice";
import { useState } from "react";
import { GetAgentApplicants, agentApplicantSelector, getAgentReservationCategories } from "../../../../redux/features/agent/AgentApplicantsSlice";
import { AgentApplicantsViewsStyles } from "../AgentApplicantsView.styles";
import { agentProfileSelector, clearGetAgentData, getAgentList, getRolesForDropdown } from "../../../../redux/features/agent/AgentProfileSlice";
import { Checkbox, FormControl, IconButton, Input, InputAdornment, InputLabel, ListItemText, MenuItem, Select, Snackbar, Typography } from "@material-ui/core";
import AgentSearchBox from "./AgentSearchBox";
import { Alert } from "@material-ui/lab";

const AntTabs = withStyles((theme) => ({
  root: {
    marginBottom: 15,
    minHeight: "auto",
    "& .MuiTabs-scroller": {
      display: "flex"
    },
    "& .MuiTabs-flexContainer": {
      background: "#0F2940",
      borderRadius: 30,
      [theme.breakpoints.only("xs")]: {
        overflow: "auto",
        padding: "0 12px"
      },
    }
  },
  indicator: {
    display: "none"
  },
}))(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    color: "#C1C1C1",
    textTransform: "none",
    letterSpacing: "0.04em",
    opacity: 1,
    padding: 8,
    fontWeight: "normal",
    "&:hover": {
      color: "#ffffff",
      opacity: 1,
    },
    "&$selected": {
      color: "#ffffff",
      fontWeight: "bold"
    },
    "&:focus": {
      color: "#ffffff",
    },
  },
  selected: {

  },
}))((props) => <Tab disableRipple {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;


  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Grid>{children}</Grid>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const AgentApplicantsView = (props) => {
  const { width } = props;
  const { t } = useTranslation("AgentApplicationDashboardPageTrans");
  const classes = AgentApplicantsViewsStyles();
  const dispatch = useDispatch();
  const {
    agentApplicantsData,
    isFetchingAgentApplicants,
    isSuccessResAgentApplicants,
    isErrorAgentApplicants,
    errorMsgAgentApplicants
  } = useSelector(agentApplicantSelector);


  const [applicationProject, setApplicationProject] = React.useState("");
  const [searchedText, setSearchedText] = React.useState("");
  const [selectedCategory, setSelectedCategory] = React.useState("");
  const [selectedStatus, setSelectedStatus] = React.useState("");
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [clear, setClear] = React.useState(false)
  const [page, setPage] = React.useState(1)
  const perPage = 6;
  const isTabFirstRender = useRef(true);
  const [roles, setRoles] = useState("");
  const [roleList, setRoleList] = React.useState([]);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [disabledStatus, setDisabledStatus] = React.useState(true);

  const {
    isFetchingAgentList,
    agentListData,
    agentProfileData,
    isFetchingRoles,
    isSuccessRoles,
    isErrorRoles,
    errorMsgRoles,
    rolesData,
    isSuccessResAgentProfile
  } = useSelector(agentProfileSelector)

  const [personName, setPersonName] = useState([]);
  const [agentList, setAgentList] = React.useState([]);
  const [selectAllAgent, setSelectAllAgent] = React.useState(true);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    if (newValue == 1) {
      setDisabledStatus(false);
    } else {
      setDisabledStatus(true);
    }
    localStorage.setItem("appDashboardFiltersParam", newValue);
  };

  const handleClickDisabled = () => {
    if (roles === "") {
      setOpenSnackbar(true);
    }
  };

  useEffect(() => {
    if (isSuccessResAgentProfile) {
      dispatch(getRolesForDropdown(agentProfileData?.AgentDetails[0]?.source));
    }
    return () => {
      dispatch(clearGetAgentData())
    }
  }, [isSuccessResAgentProfile]);

  useEffect(() => {
    if (roles && isSuccessResAgentProfile) {
      const requestData = {
        roles: roles,
        source: agentProfileData?.AgentDetails[0]?.source
      }
      dispatch(getAgentList(requestData));
    }
  }, [roles, isSuccessResAgentProfile]);

  useEffect(() => {
    if (isSuccessRoles && rolesData?.length > 0) {
      setRoleList([...rolesData])
    }
  }, [isSuccessRoles])

  useEffect(() => {
    dispatch(getAgentReservationCategories());
    let getFilterParamsObj = localStorage.getItem("appDashboardFiltersParam");
    if (getFilterParamsObj) {
      let newObj = JSON.parse(getFilterParamsObj);
      if (newObj.type == "completed") {
        setSelectedTab(1);
        let newObj = {
          type: "",
          status: ""
        };
        localStorage.setItem("appDashboardFiltersParam", JSON.stringify(newObj));
      } else {
        setSelectedTab(0);
      }
    } else {
      setSelectedTab(0);
    }
    // let getFilterParamsObj = localStorage.getItem("appDashboardFiltersParam");
    // if (getFilterParamsObj) {
    //   let newObj = JSON.parse(getFilterParamsObj);
    //   if (newObj == "1") {
    //     setSelectedTab(1); 
    //   }else if(newObj == "2"){
    //     setSelectedTab(2);
    //     //localStorage.setItem("appDashboardFiltersParam", null)
    //   }else{
    //     setSelectedTab(0);
    //   }
    // }
    return () => {
      dispatch(clearGetAgentData())
    }
  }, []);

  useEffect(() => {
    if (isTabFirstRender.current) {
      isTabFirstRender.current = false;
    } else {
      setPage(1)
      setSearchedText("")
      setApplicationProject("");
      getAppDetailsWithFilter();
    }
  }, [selectedTab]);

  useEffect(() => {
    getAppDetailsWithFilter();
    dispatch(getProjectsListData());
  }, [dispatch, t, searchedText, selectedCategory, selectedStatus, applicationProject, personName, page]);

  useEffect(() => {
    //console.log("enter clear");
    let params = "sortby=asc&search="
    localStorage.setItem("applctnsDashboardApiParam", params);
    //getAppAfterClearFilter();
  }, [clear]);

  useEffect(() => {
    setClear(false)
  }, [agentApplicantsData])


  useEffect(() => {
    if (agentListData?.length > 0) {
      setAgentList([{ Agentcode: "All", FirstName: "Select All" }, ...agentListData])
    }
  }, [agentListData])

  const handleChange = (event) => {

    const value = event.target.value;

    if (value.includes('All')) {

      if (selectAllAgent) {
        setPersonName([])
        setSelectAllAgent(false);
      } else {
        handleSelectAll()
        setSelectAllAgent(true);
      }

    } else {
      setPersonName(value.filter(option => option !== 'All'));
      setSelectAllAgent(false);
    }
  };

  const handleSelectAll = () => {
    const allValues = agentList
      .filter((option) => option.Agentcode !== 'All')
      .map((option) => option.Agentcode);
    setPersonName(allValues);
  };


  React.useEffect(() => {
    if (agentList.length > 0 && selectAllAgent) {
      handleSelectAll()
    }
  }, [selectAllAgent, agentList])

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 350,
      },
    },
  };


  // useEffect(() => {
  //   const type = localStorage.getItem("appDashboardFiltersParam")
  //   let params = "sortby=asc&search="
  //   if(type.type === "completed"){
  //     params += "&type=completed&perpage=100&page=1"
  //     localStorage.setItem("applctnsDashboardApiParam", params);
  //     dispatch(GetAgentApplications());
  //   }
  //   console.log("getItem", localStorage.getItem("appDashboardFiltersParam"));
  // }, [localStorage.getItem("applctnsDashboardApiParam")])

  const getAppAfterClearFilter = () => {
    let params = "sortby=asc&search="
    //console.log("enter else");
    if (searchedText) {
      params += searchedText;
    }
    if (selectedTab == 0) {
      params += "&type=inprogress";
      //console.log("select1", params);
    }
    if (selectedTab == 1) {
      params += "&type=completed";
      //console.log("select2", params);
    }
    if (selectedTab == 2) {
      params += "&type=rejected";
    }
    params += "&perpage=3&page=1";
    localStorage.setItem("applctnsDashboardApiParam", params);
    dispatch(GetAgentApplicants());
  }

  const getAppDetailsWithFilter = () => {
    let params = "sortby=DESC&";
    if (applicationProject) {
      params += "projectid=" + applicationProject
      //console.log("params", params);
    }
    if (searchedText) {
      params += "&search=" + searchedText;
      //console.log("search", params);
    }
    if (selectedTab == 0) {
      params += "&type=inprogress";
    }
    if (selectedTab == 1) {
      params += "&type=completed";
    }
    if (selectedTab == 2) {
      params += "&type=rejected";
    }
    if (selectedCategory) {
      params += "&category=" + selectedCategory;
    }
    if (selectedStatus) {
      params += "&status=" + selectedStatus;
    }
    if (personName.length) {
      params += "&agent_ids=" + personName
    }
    if (personName.length === 0 && !roles) {
      params += "&agent_ids=" + localStorage.getItem('agentcode')
    }
    if (page) {
      params += "&page=" + page;
    }
    params += `&perpage=${perPage}`;
    localStorage.setItem("applctnsDashboardApiParam", params);
    //console.log("selectedStatus", selectedStatus);
    dispatch(GetAgentApplicants());
  };

  const handleChangeRole = (event) => {
    const value = event.target.value;
    setRoles(value);
    setSelectAllAgent(true)
  }

  const clearFilter = () => {
    setAgentList([])
    setPersonName([])
    setRoles("")
    setSelectAllAgent(true)
  }

  return (
    <div className={classes.root}>
      {/* {console.log("agentApplicantsData.data",agentApplicantsData.data)} */}
      {isFetchingAgentApplicants && <Loading isOpen={isFetchingAgentApplicants} />}
      {isErrorAgentApplicants && <AlertBox severity="error">{errorMsgAgentApplicants}</AlertBox>}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setOpenSnackbar(false)}
          severity="warning"
        >
          Please select a role first
        </Alert>
      </Snackbar>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          {isSuccessResAgentApplicants &&
            <AntTabs
              variant="scrollable"
              scrollButtons="auto"
              value={selectedTab}
              onChange={handleTabChange}
              aria-label="ant example"
            >
              {/* <AntTab label={t("tabs.item1")} /> */}
              <AntTab label={`${t("tabs.item2")}  (${agentApplicantsData?.inprogess_count ? agentApplicantsData.inprogess_count : 0})`} />
              <AntTab label={`${t("tabs.item3")}  (${agentApplicantsData?.completed_count ? agentApplicantsData.completed_count : 0})`} />
              {/* <AntTab label={t("tabs.item4")}   /> */}
            </AntTabs>
          }
        </Grid>
        <Grid item>
          {(personName.length === 0 && !roles) ?
            <Typography variant="h6" style={{ color: '#fff' }}>Your Profile</Typography>
            :
            <Typography variant="h6" style={{ color: '#fff' }}>Team Profile</Typography>}
        </Grid>
        <Grid item>
          <Box display={"flex"} flexDirection={"row"}>
            {Object.keys(agentProfileData).length != 0 && agentProfileData?.AgentDetails[0].UserType !== 'Agent' && agentProfileData?.AgentDetails[0].UserType !== '' &&
              <Grid component={Paper} item style={{ padding: "0px 0px 8px 15px", marginRight: 3 }}>
                <Box>
                  <FormControl className={classes.formControl} >
                    <InputLabel id="roles-label">Select Role</InputLabel>
                    <Select
                      labelId="roles"
                      id="roles"
                      // multiple
                      value={roles}
                      onChange={handleChangeRole}
                      // input={<Input />}
                      // renderValue={(selected) => {
                      //   const extractedNames = roleList
                      //     .filter(rolesData => selected.includes(rolesData.value))
                      //     .map(rolesData => rolesData.label);

                      //   return extractedNames.join(', ')
                      // }}
                      style={{ width: "180px", marginRight: 10 }}
                      color="primary"
                      endAdornment={
                        roles && (
                          <InputAdornment position="end">
                            <IconButton title='Clear Filter' onClick={clearFilter}>
                              <ClearIcon
                                color="primary"
                              />
                            </IconButton>
                          </InputAdornment>
                        )
                      }
                    >
                      {roleList.map((role, i) => (
                        <MenuItem key={i} value={role.value}>
                          {`${role.label} ${role?.count ? `(${role?.count})` : ""}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            }
            {Object.keys(agentProfileData).length != 0 && agentProfileData?.AgentDetails[0].UserType !== 'Agent' && agentProfileData?.AgentDetails[0].UserType !== '' &&
              <Grid component={Paper} item style={{ padding: "0px 0px 8px 15px", marginRight: 3 }}>
                <Box>
                  <FormControl className={classes.formControl} >
                    <InputLabel id="demo-mutiple-checkbox-label">Select Account</InputLabel>
                    <Select
                      labelId="demo-mutiple-checkbox-label"
                      id="demo-mutiple-checkbox"
                      multiple
                      value={personName}
                      onChange={handleChange}
                      input={<Input />}
                      renderValue={(selected) => {

                        const extractedNames = agentList
                          .filter(agentData => selected.includes(agentData.Agentcode))
                          .map(agentData => agentData.FirstName);

                        return extractedNames.join(', ')
                      }}
                      MenuProps={MenuProps}
                      style={{ width: "180px", marginRight: 10 }}
                      color="primary"
                      disabled={roles === ""}
                      onClick={handleClickDisabled}
                    >
                      {agentList.map((agent, i) => (
                        <MenuItem key={i} value={agent.Agentcode} style={{ backgroundColor: agent.Agentcode === 'All' ? "blue" : "" }} >
                          <Checkbox color="primary" style={{ color: agent.Agentcode === 'All' ? "white" : "" }} checked={agent.Agentcode === 'All' ? selectAllAgent : personName.indexOf(agent.Agentcode) > -1} />
                          <ListItemText style={{ color: agent.Agentcode === 'All' ? "white" : "" }} primary={<span style={{ fontWeight: agent.Agentcode === 'All' ? "bold" : "" }}>{(agent.Agentcode === 'All' ? '' : ` ${agent.Agentcode} - `) + `${agent.FirstName}` + (agent.Agentcode === 'All' ? '' : ` (${agent?.TotalApplicants})`)}</span>} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            }
            <AgentSearchBox setSearchedApplicant={setSearchedText} />
          </Box>
        </Grid>
      </Grid>

      <Grid className={classes.applicationGridContainer}>
        <Paper elevation={4}>
          <div className={classes.container}>
            {/* {isSuccessResAgentApplicants &&
              <AgentApplicantsFilters applicationsData={agentApplicantsData.data}
                searchedText={searchedText}
                selectedCategory={selectedCategory}
                selectedStatus={selectedStatus}
                setSearchedText={setSearchedText}
                setSelectedCategory={setSelectedCategory}
                setSelectedStatus={setSelectedStatus}
                setApplicationProject={setApplicationProject}
                applicationProject={applicationProject}
                setClear = {setClear}
                clear = {clear}
                setSelectedTab={setSelectedTab} 
                disabledStatus={disabledStatus} />
            } */}
            <TabPanel value={selectedTab} index={0}>
              {isSuccessResAgentApplicants &&
                <AgentApplicantCard page={page} setPage={setPage} perPage={perPage} applicationsData={agentApplicantsData} type="inprogress" />
              }
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
              {isSuccessResAgentApplicants &&
                <AgentApplicantCard page={page} setPage={setPage} perPage={perPage} applicationsData={agentApplicantsData} type="completed" />
              }
            </TabPanel>
            <TabPanel value={selectedTab} index={2}>
              {isSuccessResAgentApplicants &&
                <AgentApplicantCard page={page} setPage={setPage} perPage={perPage}
                  applicationsData={agentApplicantsData}
                  type="rejected" />
              }
            </TabPanel>
          </div>
        </Paper>
      </Grid>
    </div>
  );
};

export default withWidth()(AgentApplicantsView);