import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import Fab from "@material-ui/core/Fab";
import Header from "../../atoms/Header/Header";
import AuthCard from "../../molecules/Cards/AuthCard/AuthCard";
import { PhoneIcon, ChatIcon, MenuVideoTtrlNavBtnIcon } from "../../atoms/SvgIcons/SvgIcons";
import GuideVideoDialogBox from "../../molecules/DialogBoxes/GuideVideoDialogBox/GuideVideoDialogBox";
import { AuthTemplateStyles } from "./AuthTemplate.styles";

const AuthTemplate = (props) => {
  const { children } = props;
  const classes = AuthTemplateStyles();
  const [helpVideoUrl, setHelpVideoUrl] = useState("");
  const [showGuideContent, setShowGuildeContent] = useState(true);
  const [guideVideoDialogOpenIs, setGuideVideoDialogOpenIs] = useState(false);
 


  return (
    <div className={classes.background}>
      {/* <Header kycPagesIs={true} /> */}
      <AuthCard >{children}</AuthCard>
  
     
      
    </div>
  );
};

export default AuthTemplate;
