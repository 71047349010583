import { Button, ButtonGroup, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@material-ui/core';
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import React, { useState } from 'react'
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { DownloadPrimaryIcon } from '../../../atoms/SvgIcons/SvgIcons';
import { ApiEndPoint } from '../../../../utils/Common';
import { agentLeadSelector, uploadAgentLeadData } from '../../../../redux/features/agent/AgentLeadSlice';
import { useDispatch, useSelector } from 'react-redux';
import SampleLead from "../../../../assets/sample_lead.csv";
import { green } from '@material-ui/core/colors';
import AlertBox from '../../../atoms/AlertBox/AlertBox';
import Loading from '../../../atoms/Loading/Loading';


const useStyles = makeStyles((theme) => ({
    dialogBoxCloseBtn: {
        float: 'right',
        padding: theme.spacing(1)
    },
    dialogBoxTitle: {
        padding: theme.spacing(1),
        textAlign: "center",
        "& .MuiDialogTitle-root": {
            padding: 0,
        },
        "& h2": {
            color: "#007AE7",
            fontSize: "1.5rem",
            fontWeight: "bold",
        },
    },
    dialogContentSec: {
        minWidth: "450px",
        padding: theme.spacing(0, 7, 4.5),
    },
    docsCard: {
        backgroundColor: "#F5FAFD",
        border: "1px dashed #00437E",
        borderRadius: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around",
        maxWidth: 235,
        textAlign: "center",
        margin: "0 auto",
        padding: theme.spacing(1, 1),
        minHeight: 150,
        height: "100%",
        position: "relative",
        "&.done": {
            background: "linear-gradient(0deg, #0038C0 20.1%, #006FD5 87.81%)",
            border: "none",
        },
    },
    browseBtn: {
        marginLeft: theme.spacing(1),
        padding: "4px",
    },
    uploadBtn: {
        marginLeft: theme.spacing(1),
        padding: "4px",
    },
    input: {
        display: "none",
    },
    validateText: {
        color: "#65707D",
        fontSize: "0.8rem",
    },
    divider: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        fontSize: "15px",
        "&::before,&::after": {
            content: "''",
            flex: "1 1",
            borderBottom: "2px solid #00000033",
            margin: "auto",
            marginRight: "10px",
            marginLeft: "10px",
        },
        "&.done": {
            color: "#FFFFFF",
            fontWeight: 700,
        },
        "&.verified": {
            color: "#263238",
        },
    },
}));

const ColorButton = withStyles((theme) => ({
    root: {
        color: "#fff",
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
}))(Button);

export default function AgentImportCSV(props) {
    const { openImportLead, onCloseImport } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const [leadFile, setLeadFile] = useState(null);
    const [validate, setValidate] = useState(false);
    const [leadFileName, setLeadFileName] = useState(null);
    const hiddenFileInput = React.useRef(null);
    const {
        isSuccessResUploadAgentLead,
        isErroruploadAgentLead,
        errorMessageUploadpdateLead,
        isFetchingUploadAgentLead,
        agentLeadFilePath
    } = useSelector(agentLeadSelector);



    const fileSelect = (file) => {
        setLeadFile(null);
        let fileName = file.name;
        let fileExtension = fileName.split(".").pop().trim().toLowerCase();
        if (fileExtension === "csv") {
            setValidate(true);
            setLeadFile(file);
        } else {
            setValidate(false);
            alert('Please upload file with .csv extension only')
        }
    };
    const onSubmit = () => {
        const requestData = new FormData();
        requestData.append("file", leadFile ? leadFile : null);
        requestData.append("AgentId", localStorage.getItem("agentcode"));
        dispatch(uploadAgentLeadData(requestData));
        // setStoreLeadData(updateAgentLeadData);

        setLeadFile(null);
        setLeadFileName(null)
    };


    return (
        <Dialog
            open={openImportLead}
            // onClose={() => onClose()}
            maxWidth="xs"
            fullWidth
        >
            {isFetchingUploadAgentLead && <Loading isOpen={isFetchingUploadAgentLead} />}
            <DialogTitle className={classes.dialogBoxTitle}>
                Import Lead CSV
                <IconButton
                    className={classes.dialogBoxCloseBtn}
                    onClick={() => onCloseImport()}
                >
                    <CloseOutlinedIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                {isErroruploadAgentLead && (
                    <AlertBox severity="error">{errorMessageUploadpdateLead}</AlertBox>
                )}
                <Grid item className={classes.docsCard}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Typography style={{ fontSize: "0.9rem", fontWeight: 600 }}>Import CSV</Typography>
                        <Button
                            startIcon={<DownloadPrimaryIcon />}
                            size='small'
                            color="primary"
                            className={classes.downloadBtn}
                            style={{ width: 68 }}
                            onClick={() => window.open(SampleLead)}
                        >
                            sample
                        </Button>
                    </Grid>
                    <input
                        className={classes.input}
                        ref={hiddenFileInput}
                        id="fileData"
                        name="file"
                        type="file"
                        accept=".csv"
                        required
                        onChange={(event) => {
                            if (event.currentTarget.files[0]) {
                                fileSelect(event.currentTarget.files[0]);
                                setLeadFileName(event.currentTarget.files[0].name)
                                event.currentTarget.value = ''
                            }
                        }}
                    />
                    <Typography className={classes.validateText}>
                        Support <strong> (csv Format) </strong>
                    </Typography>
                    {(leadFileName && validate) && <Typography style={{ fontSize: 12, fontWeight: 600 }}>{leadFileName?.length > 18 ? `${leadFileName.slice(0, 12)}...${leadFileName.slice(-6)}` : leadFileName}</Typography>}
                    <ButtonGroup variant="contained" color="primary" size="small">
                        <Button
                            component="span"
                            onClick={() => hiddenFileInput.current.click()}
                        >
                            Browse
                        </Button>

                        <ColorButton
                            component="span"
                            disabled={leadFile == null}
                            onClick={onSubmit}
                            color="secondry"
                        >
                            Submit
                        </ColorButton>
                    </ButtonGroup>
                    {isSuccessResUploadAgentLead && <>
                        <Typography className={classes.divider}>Download Last Result</Typography>
                        <Grid container alignItems="center" justifyContent="center">
                            <Button
                                startIcon={<DownloadPrimaryIcon />}
                                color="primary"
                                className={classes.downloadBtn}
                                variant="outlined"
                                onClick={() => window.open(`${ApiEndPoint}/${agentLeadFilePath?.filePath}`)}
                                size='small'
                            >
                                Download
                            </Button>
                        </Grid>
                    </>}
                </Grid>
            </DialogContent>
        </Dialog>
    )
}
