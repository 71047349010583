import React, { useState } from "react";
import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, makeStyles, withStyles } from "@material-ui/core";
import PieChartTwoToneIcon from '@material-ui/icons/PieChartTwoTone';
import CloseIcon from '@material-ui/icons/Close';
import { Cell, Label, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";

const CustomDialog = withStyles({
  paperWidthSm: {
    maxWidth: 750,
  },
})(Dialog);

const useStyles = makeStyles((theme) => ({
  legendList: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    listStyle: 'none',
  },
  legendListItem: {
    padding: 10,
    borderRadius: 4,
    border: '1px solid lightgray',
    margin: 6
  },
  bulletLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  circleBullet: {
    borderRadius: '50%',
  },
  bulletLabelText: {
    marginLeft: theme.spacing(1),
  },
  centeredLabelValue: {
    fontSize: '36px',
    fontWeight: 600,
    fill: '#0088fe',
  },
  chartContainer: {
    position: "relative",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
    "& .recharts-wrapper": {
      margin: "0 auto",
      zIndex: 1
    }
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    lineHeight: '1rem',
    backgroundColor: "#0038C0",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const Bullet = ({ backgroundColor, size }) => {
  const classes = useStyles();
  return (
    <div
      className={classes.circleBullet}
      style={{
        backgroundColor,
        width: size,
        height: size
      }}
    ></div>
  );
};

const CustomizedLegend = (props) => {
  const classes = useStyles();
  const { payload } = props;
  return (
    <ul className={classes.legendList}>
      {payload.map((entry, index) => (
        entry.value !== 'No Data' && <li key={`item-${index}`} className={classes.legendListItem}>
          <div className={classes.bulletLabel}>
            <Bullet backgroundColor={entry.payload.fill} size="10px" />
            <div className={classes.bulletLabelText}>{entry.value}</div>
          </div>
          <div style={{ marginLeft: "20px", fontWeight: 'bolder' }}>{entry.payload.value}</div>
        </li>
      ))}
    </ul>
  );
};

const CustomLabel = ({ viewBox, labelText, value }) => {
  const { cx, cy } = viewBox;
  return (
    <g>
      <text
        x={cx}
        y={cy}
        className="recharts-text recharts-label"
        textAnchor="middle"
        dominantBaseline="central"
        alignmentBaseline="middle"
        fontSize="15"
      >
        {labelText}
      </text>
      <text
        x={cx}
        y={cy + 20}
        className="recharts-text recharts-label"
        textAnchor="middle"
        dominantBaseline="central"
        alignmentBaseline="middle"
        fill="#0088FE"
        fontSize="26"
        fontWeight="600"
      >
        {value}
      </text>
    </g>
  );
};

const CustomEmptyLabel = ({ viewBox, labelText, value }) => {
  const { cx, cy } = viewBox;
  return (
    <g>
      <text
        x={cx}
        y={cy + 20}
        className="recharts-text recharts-label"
        textAnchor="middle"
        dominantBaseline="central"
        alignmentBaseline="middle"
        fill="#0088FE"
        fontSize="26"
        fontWeight="600"
      >
        {value}
      </text>
      <text
        x={cx}
        y={cy}
        className="recharts-text recharts-label"
        textAnchor="middle"
        dominantBaseline="central"
        alignmentBaseline="middle"
        fontSize="15"
      >
        {labelText}
      </text>
    </g>
  );
};

const AgentAnalyticsTable = ({ agentWiseData }) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [leadDialogOpen, setLeadDialogOpen] = React.useState(false);
  const [leadDetailData, setLeadDetailData] = React.useState([]);
  const [leadOpenOf, setLeadOpenOf] = useState();
  const [noLeadForAgent, setNoleadForAgent] = useState(false);
  const COLORS = ["#2196F3", "#FF9800", "#8BC34A", "#F44336", "#9E9E9E", "#da03ff"];

  const CustomTooltip = ({ active, payload, label }) => {
    const total = leadOpenOf.totalLead;
    if (active) {
      return (
        <div
          style={{
            backgroundColor: "#ffff",
            padding: "5px",
            border: "1px solid #cccc"
          }}
        >
          <label>{`${payload[0].name}: `}<strong>{`${((payload[0].value / total) * 100).toFixed(2)}%`}</strong></label>
        </div>
      );
    }
    return null;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const indianRupeeFormat = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR'
  });

  const handleClose = () => {
    setLeadDialogOpen(false)
    setLeadDetailData([]);
    setNoleadForAgent(false);
    setLeadOpenOf()
  }

  const openLeadDeatils = (data) => {
    setLeadDetailData(data.LeadData.StatusWiseCount)
    setLeadOpenOf({ name: data.Name, code: data.AgentCode, totalLead: data.LeadData.Total })
    if (data.LeadData.Total === 0) {
      setNoleadForAgent(true);
    }
    setLeadDialogOpen(true);
  }

  return (
    <Box p={1} marginRight={1}>
      <TableContainer component={Paper}>
        <Table size="small" className="tableView" aria-labelledby="tableTitle" aria-label="enhanced table" >
          <TableHead>
            <StyledTableRow>
              <StyledTableCell component="th">CFC Code</StyledTableCell>
              <StyledTableCell component="th">Name</StyledTableCell>
              <StyledTableCell component="th">Lead Counts</StyledTableCell>
              <StyledTableCell component="th" align="center">Applicant Registered</StyledTableCell>
              <StyledTableCell component="th" align="center">Paid Appl. Fee</StyledTableCell>
              <StyledTableCell component="th" align="right">Total Appl. Fee</StyledTableCell>
              <StyledTableCell component="th" align="center">Doc Verify Completed</StyledTableCell>
              <StyledTableCell component="th" align="center">Flat Selected</StyledTableCell>
              <StyledTableCell component="th" align="center">Paid Booking Amount</StyledTableCell>
              <StyledTableCell component="th" align="right">Total Booking Amount</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {agentWiseData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <StyledTableRow hover role="checkbox" tabIndex={-1} key={index} >
                    <StyledTableCell scope="row">
                      {row.AgentCode || "--"}
                    </StyledTableCell>
                    <StyledTableCell scope="row">
                      {row.Name || "--"}
                    </StyledTableCell>
                    <StyledTableCell scope="row">
                      <IconButton size="small" color="secondary" onClick={() => openLeadDeatils(row)} title="Click to view Lead Counts and Status">
                        <PieChartTwoToneIcon />
                      </IconButton>
                    </StyledTableCell>
                    <StyledTableCell align="center" scope="row">
                      {row.applicants_count || "0"}
                    </StyledTableCell>
                    <StyledTableCell align="center" scope="row">
                      {row.registration_paid_applicants_count || "0"}
                    </StyledTableCell>
                    <StyledTableCell align="right" scope="row">
                      {indianRupeeFormat.format(row.applications_amount) || "00.00"}
                    </StyledTableCell>
                    <StyledTableCell align="center" scope="row">
                      {row.doc_verification_completed_count || "0"}
                    </StyledTableCell>
                    <StyledTableCell align="center" scope="row">
                      {row.applications || "0"}
                    </StyledTableCell>
                    <StyledTableCell align="center" scope="row">
                      {row.successfull_payment_count || "0"}
                    </StyledTableCell>
                    <StyledTableCell align="right" scope="row">
                      {indianRupeeFormat.format(row.payment_amount) || "00.00"}
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })

            }
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={agentWiseData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
        />
      </TableContainer>
      {leadDetailData.length > 0 &&
        <CustomDialog open={leadDialogOpen} onClose={handleClose} maxWidth='sm' fullWidth>
          <Grid container justifyContent="space-between" alignItems="center" style={{ paddingLeft: 8, paddingRight: 8 }}>
            <Grid item>
              {leadOpenOf.name && <Typography variant="body2">Name : <strong>{leadOpenOf.name}</strong></Typography>}
              {leadOpenOf.code && <Typography variant="body2">Code : <strong>{leadOpenOf.code}</strong></Typography>}
            </Grid>
            <DialogTitle id="alert-dialog-title">Lead Status & Counts</DialogTitle>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <DialogContent dividers style={{ background: 'beige' }}>
            <div style={{ width: "100%", height: 400 }}>
              <ResponsiveContainer>
                <PieChart>
                  {noLeadForAgent && (
                    <Pie data={[{ name: 'No Data', value: 1 }]} fill="#eeeeee" innerRadius={80} outerRadius={100}>
                      <Label content={<CustomEmptyLabel value={0} labelText="Total Leads" />}
                        position="center" />
                    </Pie>
                  )}
                  <Pie
                    data={leadDetailData}
                    dataKey="value"
                    cx={200}
                    cy={200}
                    innerRadius={80}
                    outerRadius={100}
                    fill="#8884d8"
                    paddingAngle={0}
                  >
                    {leadDetailData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                    <Label
                      content={<CustomLabel labelText="Total Leads" value={leadOpenOf.totalLead} />}
                      position="center"
                    />
                  </Pie>
                  {!noLeadForAgent && <Tooltip content={<CustomTooltip />} />}
                  {<Legend content={<CustomizedLegend />} layout="vertical" verticalAlign="middle" align="right" />}
                </PieChart>
              </ResponsiveContainer>
            </div>
          </DialogContent>
        </CustomDialog>}
    </Box>
  );
};

export default AgentAnalyticsTable;
