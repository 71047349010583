import React, { useEffect, useState, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import withWidth from "@material-ui/core/withWidth";
import { Formik, Form, ErrorMessage } from "formik";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import CloseIcon from '@material-ui/icons/Close';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import IconTitle from "../../../atoms/IconTitle/IconTitle";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import moment from "moment";
import { agentApplicantsSelector } from "../../../../redux/features/agent/AgentAnalDashboardSlice";
import FormControl from "../../../molecules/FormControl/FormControl";
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import TablePagination from '@material-ui/core/TablePagination';
import FilterListIcon from '@material-ui/icons/FilterList';
// import AgentApplicantCard from "../AgentApplicantCard/AgentApplicantCard";
import {
  FilterIcon
} from "../../../atoms/SvgIcons/SvgIcons";
import { getProjectsListData, clearProjectListData, projectDataSelector } from "../../../../redux/features/projectdata/ProjectDataSlice";
import { AgentLeadViewsStyles } from "../AgentLeadViews.styles";
import Image from "../../../../assets/Profile.jpg";
import { masterDataSelector, getReservationCategories } from "../../../../redux/features/masterdata/MasterDataSlice";
import { debounce } from "lodash";
import SelectAgent from "./SelectAgent";
import { agentProfileSelector } from "../../../../redux/features/agent/AgentProfileSlice";
import { Badge, Popover } from "@material-ui/core";
import BackupIcon from '@material-ui/icons/Backup';
import AddIcon from '@material-ui/icons/Add';
import AgentAddLeadDialogBox from "../../../molecules/DialogBoxes/AgentLeadViewDialogBox/AgentAddLead";
import { agentLeadSelector, clearUpdateRes, getAgentLeadData } from "../../../../redux/features/agent/AgentLeadSlice";
import AgentImportCSV from "../../../molecules/DialogBoxes/AgentLeadViewDialogBox/AgentImportCSV";


// const applicationSortList = [
//   { value: "all", label: "All" },
//   // { value: "1", label: "Registration" },
//   { value: "PersonalDetails", label: "Personal Details" },
//   { value: "CategoryDetails", label: "Category Details" },
//   { value: "Submit Document", label: "Submit Document" },
//   { value: "Payment", label: "Payment" },
//   // { value: "6", label: "E-stamping and E-signing" }
// ];


const statusList = [
  {
    value: 0,
    label: "Unattended",
  },
  {
    value: 1,
    label: "Call Back (Warm)",
  },
  // {
  //   value: 2,
  //   label: "Reminder",
  // },
  {
    value: 3,
    label: "Call Successful (Warm)",
  },
  {
    value: 4,
    label: "Call not Picked up (Warm)",
  },
  {
    value: 5,
    label: "Not interested (Cold)",
  },
  // {
  //   value: 6,
  //   label: "Not connected",
  // },
  // {
  //   value: 7,
  //   label: "Connected",
  // },
  {
    value: 8,
    label: "Site Visit (Hot)"
  }
];



const AgentLeadFilters = (props) => {
  const { width, applicationsData, setSearchedText, searchedText, selectedCategory, setSelectedCategory, setSelectedSort, setSelectedStatus, selectedStatus, setClear, clear, setApplicationProject, applicationProject, setSelectedTab, disabledStatus, filterCategoryData, team, setTeam, setPage } = props;
  const { t } = useTranslation("AgentLeadPageTrans");
  const classes = AgentLeadViewsStyles();
  const dispatch = useDispatch();
  const [applicantsFullName, setApplicantsFullName] = React.useState("");
  const [gender, setGender] = React.useState("");
  const [marritalStatus, setMarritalStatus] = React.useState("");
  const [formValues, setFormValues] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [filterProjectListData, setFilterProjectListData] = useState([]);
  const { isFetchingAddLead, isSuccessAddLead, isErrorAddLead, errorMsgAddLead, addLeadData, alreadyExist } = useSelector(agentLeadSelector);
  const [value, setValue] = useState('');
  const [openAddLead, setOpenAddLead] = useState(false);
  const formikRef = useRef();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openImportLead, setOpenImportLead] = useState(false);

  const onCloseImport = () => {
    setOpenImportLead(false);
    dispatch(clearUpdateRes());
  }

  const handleOpenFilterMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const onClose = () => {
    setOpenAddLead(false);
    if (isSuccessAddLead) {
      dispatch(getAgentLeadData());
    }
  }

  const initialValues = {
    searchLead: "",
    applicationCategory: "",
    applicationProject: "",
    applicationSort: "dsc",
    applicationStatus: selectedStatus,
  };

  const {
    projectListData,
    ProListData,
    isSuccessProjectListData,
  } = useSelector(projectDataSelector)


  const {
    allCategory,
    isSuccessMasterData
  } = useSelector(masterDataSelector);


  const sortOptions = [
    { value: "asc", label: t("filterSection.ascTxt") },
    { value: "dsc", label: t("filterSection.dscTxt") },
  ];

  const {
    isFetchingAgentList,
    agentListData,
    agentProfileData
  } = useSelector(agentProfileSelector)

  // useEffect(()=>{
  //   if(isSuccessMasterData){
  //     clearProjectListData()
  //   }
  // },[isSuccessMasterData])

  //   useEffect(() => {
  //     let getFilterParamsObj = localStorage.getItem("appDashboardFiltersParam");
  //     if (getFilterParamsObj !== null) {
  //       let newObj = JSON.parse(getFilterParamsObj);
  //       //console.log("filters1", newObj)
  //       if (newObj.status) {
  //         for (let p = 0; p < applicationSortList.length; p++) {
  //           const element = applicationSortList[p];
  //           if (element.label == newObj.status) {
  //             setSelectedStatus(element.value);
  //             const savedValues = {
  //               applicationSort: element.value,
  //               applicationProject: "",
  //               applicationCategory: ""
  //             };
  //             setFormValues(savedValues);
  //           }
  //         }
  //       }
  //     }
  //   }, []);

  const onSubmit = (values, { setSubmitting }) => {
    // console.log("valuesdgd",values);
    setSelectedCategory(values.applicationCategory);
    setSelectedSort(values.applicationSort);
    setSelectedStatus(values.applicationStatus)
    setSubmitting(false);

    setSearchedText(values.searchLead);

  };

  const formClearEvent = () => {
    const formik = formikRef.current;
    formik.resetForm();
    setClear(true);
    setSelectedCategory("");
    setSelectedSort("dsc");
    setSelectedStatus("");
  };

  useEffect(() => {
    const formik = formikRef.current;
    formik.resetForm();
  }, [t]);


  const debounceFn = useMemo(() => debounce(handleDebounce, 500), []);

  function handleDebounce(inputValue) {
    setSearchedText(inputValue);
  };

  function handleChange(event) {
    setValue(event.target.value);
    debounceFn(event.target.value);
  };

  function handleClear(val) {
    setValue(val);
    debounceFn(val);
  };

  return (
    <Box m={2}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        innerRef={formikRef}
        enableReinitialize
      >
        {({ submitForm, values }) => (
          <Form noValidate autoComplete="off">
            <Grid container spacing={2} alignItems="center" justifyContent="space-between">
              {/* {
                Object.keys(agentProfileData).length !== 0 && agentProfileData?.AgentDetails[0].UserType !== '' && agentProfileData?.AgentDetails[0].UserType === 'CSMO' &&
                <Grid item xs>
                  <SelectAgent value={team} setValue={setTeam} setPage={setPage}/>
                </Grid>
              } */}
              <Grid item className={classes.searchBoxCol}>
                <FormControl
                  control="input"
                  variant="outlined"
                  label="search by name or mobile number"
                  placeholder="Name or Mobile Number"
                  name="searchLead"
                  type="text"
                  value={value}
                  onChange={handleChange}
                  id="searchLead"
                  className={`${classes.filterInputBox} searchBox`}
                  style={{ borderRadius: 25 }}
                  margin="dense"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {value ?
                          <IconButton
                            edge="end"
                            size="small"
                            title="clear search"
                            onClick={() => handleClear('')}
                          >
                            <CloseIcon />
                          </IconButton>
                          :
                          <IconButton
                            disabled
                            edge="end"
                            size="small"
                          >
                            <SearchOutlinedIcon />
                          </IconButton>}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {/* <Grid item>
                <Typography className={classes.appFilterLabel}><FilterIcon />{t("filterSection.filterTitle")}</Typography>
              </Grid> */}
              {/* <Grid item xs>
                <FormControl
                  control="selectbox"
                  variant="outlined"
                  label={t("filterSection.categoryLabel")}
                  name="applicationCategory"
                  id="applicationCategory"
                  options={filterCategoryData}
                  className={`${classes.filterInputBox} filterInputs`}
                  margin="dense"
                  style={{ maxWidth: values.applicationCategory ? '196px' : '' }}
                />
              </Grid> */}
              {/* <Grid item sm xs={12}>
                  <FormControl
                    control="selectbox"
                    variant="outlined"
                    label={t("filterSection.projectLabel")}
                    name="applicationProject"
                    id="applicationProject"
                    options={filterProjectListData}
                    className={`${classes.filterInputBox} filterInputs`}
                  />
                </Grid> */}
              {/* <Grid item sm xs={12}>
                <FormControl
                  control="selectbox"
                  variant="outlined"
                  label="Filter By Status"
                  name="applicationStatus"
                  id="applicationStatus"
                  //value={formValues.applicationSort}
                  options={statusList}
                  className={`${classes.filterInputBox} filterInputs`}
                  disabled={disabledStatus}
                  margin="dense"
                />
              </Grid> */}
              <Grid item>
                <Button variant="outlined" startIcon={<AddIcon />} style={{ marginRight: 10 }} size="small" color="primary" onClick={() => setOpenAddLead(true)}>
                  Add
                </Button>
                <Button variant="outlined" startIcon={<BackupIcon />} style={{ marginRight: 10 }} size="small" color="primary" onClick={() => setOpenImportLead(true)}>
                  Import CSV
                </Button>
                <Badge color="error" badgeContent=" " invisible={!selectedCategory}>
                  <Button variant="outlined" startIcon={<FilterListIcon />} size="small" color="primary" onClick={handleOpenFilterMenu}>
                    Filter
                  </Button>
                </Badge>
                <Popover
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <Box p={2} width='400px'>
                    <Typography variant="h6" style={{ marginBottom: 10 }}>Apply Filter</Typography>
                    <Grid container direction="column" spacing={2}>
                      <Grid item xs={12}>
                        <FormControl
                          control="selectbox"
                          fullwidth
                          variant="outlined"
                          label={t("filterSection.categoryLabel")}
                          name="applicationCategory"
                          id="applicationCategory"
                          options={filterCategoryData}
                          value={selectedCategory}
                          onChange={(e) => setSelectedCategory(e.target.value)}
                          margin="dense"
                        />
                      </Grid>
                    </Grid>
                    <Grid style={{ marginTop: 24 }} container xs={12} spacing={1} justifyContent="flex-end">
                      {/* <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        type="submit"
                        style={{ marginRight: 8 }}
                      >
                        {t("filterSection.filterBtnText")}
                      </Button> */}
                      {(values.searchLead || values.applicationCategory || values.applicationSort || values.applicationStatus || values.applicationStatus == 0) &&
                        <Grid item sm={"auto"} xs={12}>
                          <Button
                            size="small"
                            color="primary"
                            type="button"
                            onClick={() => formClearEvent()}>
                            {t("filterSection.clearBtnText")}
                          </Button>
                        </Grid>
                      }
                    </Grid>
                  </Box>
                </Popover>
              </Grid>
              {/* <Grid item sm={"auto"} xs={12}>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  {t("filterSection.filterBtnText")}
                </Button>

              </Grid>
              {(values.searchLead || values.applicationCategory || values.applicationSort || values.applicationStatus || values.applicationStatus == 0) &&
                <Grid item sm={"auto"} xs={12}>
                  <Button
                    size="small"
                    color="primary"
                    type="button"
                    onClick={() => formClearEvent()}>
                    {t("filterSection.clearBtnText")}
                  </Button>
                </Grid>
              } */}
            </Grid>
          </Form>
        )}
      </Formik>
      <AgentAddLeadDialogBox openAddLeadDialog={openAddLead} onClose={onClose} filterCategoryData={filterCategoryData} />
      <AgentImportCSV openImportLead={openImportLead} onCloseImport={onCloseImport} />
    </Box>
  );
};

export default withWidth()(AgentLeadFilters);
