import React, { useEffect, useState } from 'react';
import { Box, Popover, Card, CardContent, Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Divider } from '@mui/material';
import HorizontalLinearStepper from '../Stepper/HorizontalLinearStepper';
import { Button, Chip, makeStyles, withStyles } from '@material-ui/core';
import barcode from '../../../../assets/barcode.png';
import { useDispatch, useSelector } from 'react-redux';
import { PmayNonPmaySelector, getSummaryDetails } from '../../../../redux/features/pmayNonPmay/pmayNonPmaySlice';
import { docDeclarationSelector, getUploadDocumentsList } from "../../../../redux/features/file/DocDeclarationSlice";
import { Alert, AlertTitle } from '@material-ui/lab';
import { useHistory } from "react-router-dom";

import moment from 'moment/moment';
import mmrda from '../../../../assets/mmrda.png';
import jsPDF from 'jspdf';

import Loading from '../../../atoms/Loading/Loading';
import { ApiEndPoint } from '../../../../utils/Common';
import { getRegistrationProgress } from '../../../../redux/features/registration/registrationStepperSlice';
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: 'white !important',
    fontWeight: 'bold !important'
  },
  body: {
    fontSize: 10,
    padding: '0px',
    margin: '0px'
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);


const GradientChip = withStyles({
  root: {
    background: "linear-gradient(296deg, #00a74a, #15489f)",
    color: "#fff",
    "& .MuiChip-label": {
      color: "#fff",
    },
  },
})(Chip);

const SummaryDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { summaryData, isSuccessResSummary } = useSelector(PmayNonPmaySelector);
  const [summary, setSummary] = useState({});
  const [payment, setPayment] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [pdfLoading, setPdfLoading] = useState(false);
  const [verifiedDocs, setVerifiedDocs] = useState([]);
  const [rejectedDocs, setRejectedDocs] = useState([]);
  const [pendingDocs, setPendingDocs] = useState([]);

  const {
    isFetchingGetUploadList,
    isSuccessResUploadList,
    isErrorGetUploadList,
    errorMsgGetUploadList,
    getUploadListData,
  } = useSelector(docDeclarationSelector);



  
  useEffect(() => {
    dispatch(getRegistrationProgress())
    dispatch(getSummaryDetails());
    let sendData = {
      ApplicantId: localStorage.getItem("applicantId"),
      Lang: localStorage.getItem("i18nextLng"),
    };
    dispatch(getUploadDocumentsList(sendData));
  }, []);

  useEffect(() => {
    if (isSuccessResSummary) {
        setSummary(summaryData[0]);
       setPayment(summaryData?.PaymentDetails[0]); 
       setFormData(summaryData[0]);
    }
}, [isSuccessResSummary, summaryData]);


  const useStyles = makeStyles({
    table: {
      minWidth: 700,
    },
    rightAlign: {
      textAlign: 'right',
  },
    smallColumn: {
      maxWidth: 100,
    },
  });
  const classes = useStyles();
  const ApiEndPointPdf = ApiEndPoint + "/MortgageNoc/applicationOverviewPdf/";

  const initialFormData = {
    MortgageApplicationId:"",
    premises_type: "",
    property_id: "",
    pro_plot_no: "",
    pro_block_no: "",
    pro_unit_no: "",
    pro_floor: "",
    pro_wing: "",
    pro_area_sqmr: "",
    pro_approved_plan_doc_a: "",
    pro_approved_plan_doc_b: "",
    property_name: "",
    lessee_name: "",
    sub_lessee_name: "",
    lessee_society_form: "",
    lessee_certificate_no: "",
    lessee_share_certificate_doc: "",
    lessee: "",
    auth_person_name: "",
    auth_person_designation: "",
    auth_person_mobile: "",
    auth_person_aadhar_no: "",
    auth_person_pan_no: "",
    auth_person_aadhar_card_doc: "",
    auth_person_pan_card_doc: "",
    original_bua: "",
    original_total_bua: "",
    original_bua_already: "",
    original_bua_now: "",
    original_bua_bal: "",
    addl_bua: "",
    addl_total_bua: "",
    addl_bua_already: "",
    addl_bua_now: "",
    addl_bua_bal: "",
    bank_name: "",
    bank_branch_name: "",
    bank_ifsc_code: "",
    bank_address: "",
    mode_of_assignment: "",
    loan_sanction_letter_doc: "",
    mode_of_assignment_other: "",
    pre_noc_letter_no: "",
    pre_noc_date: "",
    pre_noc_doc_reg: "",
    pre_noc_reg_no: "",
    pre_noc_letter_doc: "",
    pre_noc_index_ii_doc: "",
    pre_noc_receipt_doc: "",
    company_name: "",
    doc_company_certifiate: "",
    doc_memorandum_Association: "",
    doc_deed_of_partnership: "",
    doc_registration_certificate: "",
    doc_merger_nclt_order: "",
    doc_llp_agreement: "",
    doc_establishment_certificate: "",
    post_facto_approval: "",
    processing_fee: "",
    processing_fee_gst: "",
    assignment_charges: "",
    penalty: "",
    penalty_gst: "",
    terms_and_conditions: ""
};


  const [formData, setFormData] = useState(initialFormData);


  const downloadPdf = () => {
    setPdfLoading(true);
    let fileUrl = ApiEndPointPdf +
    summaryData[0]?.MortgageApplicationId
    fetch(fileUrl, {
      method: "GET",
      headers: {
        Authorization: localStorage.getItem("jwtToken"),
      },
    }).then((response) => response.blob()).then((blob) => {
      setPdfLoading(false);
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      let today = new Date();
      const dateTime = moment(new Date(today.getFullYear(), today.getMonth() + 1, 0)).format('YYYY-MM-DD-h:mm');
      link.download = `${localStorage.getItem("applicantId")} -${dateTime}`;
      document.body.append(link);
      link.click();
      link.remove();
      // in case the Blob uses a lot of memory
      setTimeout(() => URL.revokeObjectURL(link.href), 300);
    }).catch(function (error) {
      setPdfLoading(false);
    });
  };
  const calculateTotalAmount = () => {
    let total = 64200.00; // Base amount without penalty
    if (formData.post_facto_approval === 'yes' ) {
      total += 11800.00; // Penalty + GST
    }
    return total.toFixed(2);
  };
  useEffect(() => {
    if (getUploadListData.DocumentDetails) {
      const verified = [];
      const rejected = [];
      const pending = [];

      getUploadListData.DocumentDetails.forEach((doc) => {
        switch (doc.Verified) {
          case 1:
            verified.push(doc);
            break;
          case 2:
            if(doc.IsResubmitted == 0){
            rejected.push(doc);
            }
            break;
          default:
            pending.push(doc);
            break;
        }
      });

      setVerifiedDocs(verified);
      setRejectedDocs(rejected);
      setPendingDocs(pending);
    }
  }, [getUploadListData]);


  console.log(verifiedDocs, rejectedDocs, pendingDocs);
  return (
    <Card sx={{ maxWidth: 1200, margin: 'auto', mt: 4 }}>
      {(pdfLoading || isFetchingGetUploadList) && (
        <Loading isOpen={pdfLoading || isFetchingGetUploadList} />
      )}

      <CardContent>
        {pendingDocs.length > 0 && rejectedDocs.length === 0 && verifiedDocs.length === 0 && (
          <Alert severity="warning" style={{ marginBottom: 12 }}>
            <AlertTitle>Document Verification in Progress</AlertTitle>
            Your Document Verification is UnderProcess.
          </Alert>
        )}

        {rejectedDocs.length > 0 && (
          <Alert severity="error" style={{ marginBottom: 12 }} action={
            <Button color="inherit" variant="outlined" size="small" onClick={() => history.push("/registration?step=9")}>
              Re-Upload
            </Button>}>
            <AlertTitle>Document Rejected</AlertTitle>
            <ul>
              {rejectedDocs.map((doc) => (
                <li key={doc.DocumentId}>
                  {doc.DocumentName}: {doc.VerifiedReason || 'Verification failed. Please'}{" "}<span style={{ fontWeight: 600, cursor: "pointer", textDecoration: "underline" }} onClick={() => history.push(`/registration?step=9&docId=${doc.DocumentId}`)}>Re-Upload</span>
                </li>
              ))}
            </ul>
          </Alert>
        )}

        {verifiedDocs.length === getUploadListData?.DocumentDetails?.length && (
          <Alert severity="success" style={{ marginBottom: 12 }}>
            <AlertTitle>All Documents Verified</AlertTitle>
            All documents have been successfully verified.
          </Alert>
        )}
       {/* { console.log(summaryData[0]?.VerificationStatus)}
        {summaryData[0]?.VerificationStatus == "1" && (
          <Alert severity="success" style={{ marginBottom: 12 }}>
            <AlertTitle>All Documents Verified</AlertTitle>
            All documents have been successfully verified.
          </Alert>
        )} */}
        {/* {summaryData[0]?.VerificationStatus === "0" && (
          <Alert severity="warning" style={{ marginBottom: 12 }}>
          <AlertTitle>Document Verification in Progress</AlertTitle>
          Your Document Verification is UnderProcess.
        </Alert>
        )}  */}
         {/* {summaryData[0]?.VerificationStatus === "0" && (
          <Alert severity="warning" style={{ marginBottom: 12 }}>
          <AlertTitle>Mortgage NOC Not Yet Generated</AlertTitle>
          
        </Alert>
        )}  */}
          {summaryData[0]?.VerificationStatus == "1" && (
          <Alert severity="success" style={{ marginBottom: 12 }}>
            <AlertTitle>Mortgage NOC Generated Successfully</AlertTitle>
            <Button variant='outlined' onClick={() => history.push("/my-mortgage-noc")}>  View </Button>
          </Alert>
        )} 
         {/* {summaryData[0]?.VerificationStatus == "3" && (
          <Alert severity="warning" style={{ marginBottom: 12 }}>
            <AlertTitle>Mortgage NOC Generation Under Progress</AlertTitle>
          
          </Alert>
        )}  */}
        
        <Typography variant="h6" component="div" gutterBottom align="center" sx={{ fontWeight: 'bold', mb: 1, background: "linear-gradient(126deg, #00a74a, #15489f)", color: 'white' }}>
          Mortgage NOC Application Details
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <img width={150} height={100} src={barcode} alt='barcode' />
          <Box>
            <Typography variant="body" component='div' sx={{ fontWeight: 'bold', color: 'grey' }}>
              Application No : {summary?.ApplicationNo}
            </Typography>
            <Typography variant="body" component='div' sx={{ fontWeight: 'bold' }}>
              Application Date : {payment?.CreatedOn ? moment(payment.CreatedOn).format("DD-MMM-YYYY") : ''}
            </Typography>

          </Box>
        </Box>
        <Divider />
        <Grid container spacing={2} sx={{ mt: 1 }}>

          <Grid item xs={12} marginX={2} alignItems='center'>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <GradientChip label="Steps Completed" />
              {/* <a href={applicationform} download="application_form.pdf" style={{ textDecoration: 'none' }}>
                                    <Button variant="contained" color="primary">
                                        Download Application Form
                                    </Button>
                                    </a> */}
              <Button color='primary' onClick={downloadPdf} variant='contained'>DownLoad Application Form </Button>
            </Box>
            <HorizontalLinearStepper />
          </Grid>
        </Grid>
        <Divider />

        <Box margin={2}>
          <Grid item xs={12}>
            <GradientChip label="Property Detail" />
          </Grid>

          <Grid
                container
                spacing={1}
                style={{ paddingInline: 20, marginTop: 5 }}
              >
                <Grid item xs={3}>
                  {" "}
                  <Typography variant="body1">
                    <strong>Property Type:</strong> {formData?.premises_type}
                  </Typography>{" "}
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    <strong>Property Name:</strong> {formData.property_name}
                  </Typography>{" "}
                </Grid>

                <Grid item xs={3}>
                  {" "}
                  <Typography variant="body1">
                    <strong>Block/Plot No.:</strong> {formData.pro_block_no }
                  </Typography>{" "}
                </Grid>
                <Grid item xs={3}>
                  {" "}
                  <Typography variant="body1">
                    <strong>Property Code:</strong> {formData?.property_code }
                  </Typography>{" "}
                </Grid>
                <Grid item xs={2}>
                  {" "}
                  <Typography variant="body1">
                    <strong>Unit No.:</strong> {formData.pro_unit_no}
                  </Typography>{" "}
                </Grid>
                <Grid item xs={2}>
                  {" "}
                  <Typography variant="body1">
                    <strong>Floor:</strong> {formData.pro_floor}
                  </Typography>{" "}
                </Grid>
                <Grid item xs={2}>
                  {" "}
                  <Typography variant="body1">
                    <strong>Wing:</strong> {formData.pro_wing}
                  </Typography>{" "}
                </Grid>
                <Grid item xs={2}>
                  {" "}
                  <Typography variant="body1">
                    <strong>Area Sq. Mtr.:</strong> {formData.property_area}
                  </Typography>{" "}
                </Grid>
              </Grid>
        </Box>

        {/* Lessee Details Section */}
        <Divider />
        <Box margin={2}>
          <Grid item xs={12}>
            <GradientChip label="Lessee Detail" />
          </Grid>
          <Grid
                container
                spacing={1}
                style={{ paddingInline: 20, marginTop: 5 }}
              >
                <Grid item xs={4}>
                  <Typography variant="body1">
                    <strong>Lessee:</strong> {formData.lessee_name}
                  </Typography></Grid>
                <Grid item xs={4}>  <Typography variant="body1">
                  <strong>Sub-Lessee Mortgagor:</strong>{" "}
                  {formData.sub_lessee_name}
                </Typography></Grid>
                <Grid item xs={4}>   <Typography variant="body1">
                  <strong>Society Formed:</strong> {formData.lessee_society_form}
                </Typography>

                </Grid>
              </Grid>
        </Box>
        {/* Liaison Person Details Section */}
        <Divider />
        <Box margin={2}>
          <Grid item xs={12}>
            <GradientChip label="Authorized Liaison Person" />
          </Grid>
          <Grid
                container
                spacing={1}
                style={{ paddingInline: 20, marginTop: 5 }}
              >
                <Grid item xs={4}>
                  <Typography variant="body1">
                    <strong>Is Leesee ? :</strong> {formData.lessee}
                  </Typography></Grid>
                <Grid item xs={4}>
                  <Typography variant="body1">
                    <strong>Person Name:</strong> {formData.auth_person_name}
                  </Typography></Grid>
                <Grid item xs={4}>  <Typography variant="body1">
                  <strong>Designation:</strong> {formData.auth_person_designation}
                </Typography></Grid>
                <Grid item xs={4}>  <Typography variant="body1">
                  <strong>Mobile No.:</strong> {formData.auth_person_mobile}
                </Typography></Grid>
                <Grid item xs={4}>  <Typography variant="body1">
                  <strong>Aadhar No:</strong> {formData.auth_person_aadhar_no}
                </Typography></Grid>

                <Grid item xs={4}><Typography variant="body1">
                  <strong>PAN No:</strong> {formData.auth_person_pan_no}
                </Typography>

                </Grid>
              </Grid>
        </Box>
        {/* Bank Details Section */}
        <Divider sx={{ my: 3 }} />
        <Box margin={2}>
          <Grid item xs={12}>
            <GradientChip label="Bank Detail" />
          </Grid>
          <Grid
                container
                spacing={1}
                style={{ paddingInline: 20, marginTop: 5 }}
              >
                <Grid item xs={4}>


                  <Typography variant="body1">
                    <strong>Name of Bank:</strong> {formData.bank_name}
                  </Typography></Grid>
                <Grid item xs={4}>  <Typography variant="body1">
                  <strong>IFSC Code:</strong> {formData.bank_ifsc_code}
                </Typography></Grid>
                <Grid item xs={4}> <Typography variant="body1">
                  <strong>Branch Name:</strong> {formData.bank_name}
                </Typography></Grid>
                <Grid item xs={12}> <Typography variant="body1">
                  <strong>Bank Address:</strong> {formData.bank_address}
                </Typography>


                </Grid>
              </Grid>
        </Box>
        {/* Mode of Assignment Section */}
        <Divider />
        <Box margin={2}>
          <Grid item xs={12}>
            <GradientChip label="Mode Of Assignment" />
          </Grid>
          <Grid
                container
                spacing={1}
                style={{ paddingInline: 20, marginTop: 5 }}
              >
                <Grid item xs={6}>

                  <Typography variant="body1">
                    <strong>Mode Of Assignment:</strong>{" "}
                    {formData.mode_of_assignment}
                  </Typography>

                </Grid>
                <Grid item xs={6}>

                  <Typography variant="body1">
                    <strong> Other Details:</strong>{" "}
                    {formData.mode_of_assignment_other}
                  </Typography>

                </Grid>
              </Grid>

          {/* Previous NOC Details Section */}
        </Box>
        <Divider />
        <Box margin={2}>
          <Grid item xs={12}>
            <GradientChip label="Previous NOC Details" />
          </Grid>

          <Grid
                container
                spacing={1}
                style={{ paddingInline: 20, marginTop: 5 }}
              >


                <Grid item xs={3}>
                  <Typography variant="body1">
                    <strong>Letter No.:</strong>{" "}
                    {formData.pre_noc_letter_no}
                  </Typography></Grid>
                <Grid item xs={2}>   <Typography variant="body1">
                  <strong>NOC Date:</strong>{" "}
                  {formData.pre_noc_date}
                </Typography></Grid>
                <Grid item xs={4}>  <Typography variant="body1">
                  <strong>Document Reg. Details:</strong>{" "}
                  {formData.pre_noc_doc_reg}
                </Typography></Grid>
                <Grid item xs={2}><Typography variant="body1">
                  <strong>Reg. No:</strong>{" "}
                  {formData.pre_noc_reg_no}
                </Typography>



                </Grid>
              </Grid>
        </Box>
        <Divider />
        <Box margin={2}>
          <Grid item xs={12}>
            <GradientChip label="Processing Fee Detail" />
          </Grid>
        </Box>
        <Box margin={2}>
        <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="customized table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell>Qty</StyledTableCell>
                                <StyledTableCell>Fee Type</StyledTableCell>
                                <StyledTableCell>Serial #</StyledTableCell>
                                <StyledTableCell>Description</StyledTableCell>
                                <StyledTableCell className={classes.rightAlign}>Subtotal</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            <StyledTableRow>
                                <StyledTableCell>1</StyledTableCell>
                                <StyledTableCell>Processing Fee</StyledTableCell>
                                <StyledTableCell>455-981-221</StyledTableCell>
                                <StyledTableCell>Processing Fee Details</StyledTableCell>
                                <StyledTableCell className={classes.rightAlign}>₹ {formData.processing_fee}</StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <StyledTableCell>2</StyledTableCell>
                                <StyledTableCell>Processing Fee GST</StyledTableCell>
                                <StyledTableCell>455-981-222</StyledTableCell>
                                <StyledTableCell>Processing Fee - 18% GST</StyledTableCell>
                                <StyledTableCell className={classes.rightAlign}>₹ {formData.processing_fee_gst}</StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <StyledTableCell>3</StyledTableCell>
                                <StyledTableCell>Assignment Charges</StyledTableCell>
                                <StyledTableCell>735-845-642</StyledTableCell>
                                <StyledTableCell>Stamp Duty 10% on Document Register Amount</StyledTableCell>
                                <StyledTableCell className={classes.rightAlign}>₹ {formData.assignment_charges}</StyledTableCell>
                            </StyledTableRow>
                            {formData.post_facto_approval === 'yes' && (
                                <>
                                    <StyledTableRow>
                                        <StyledTableCell>4</StyledTableCell>
                                        <StyledTableCell>Penalty</StyledTableCell>
                                        <StyledTableCell>422-568-642</StyledTableCell>
                                        <StyledTableCell>Post Facto Consent</StyledTableCell>
                                        <StyledTableCell className={classes.rightAlign}>₹ {formData.penalty}</StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell>5</StyledTableCell>
                                        <StyledTableCell>Penalty GST</StyledTableCell>
                                        <StyledTableCell>422-568-643</StyledTableCell>
                                        <StyledTableCell>Post Facto Consent - 18% GST</StyledTableCell>
                                        <StyledTableCell className={classes.rightAlign}>₹ {formData.penalty_gst}</StyledTableCell>
                                    </StyledTableRow>
                                </>
                            )}
                            <StyledTableRow>
                                <StyledTableCell colSpan={4}>
                                    <Typography style={{ fontWeight: 600 }}>Total:</Typography>
                                </StyledTableCell>
                                <StyledTableCell className={classes.rightAlign}>
                                    <Typography style={{ fontWeight: 600 }}>₹ {calculateTotalAmount()}</Typography>
                                </StyledTableCell>
                            </StyledTableRow>
                        </TableBody>
                    </Table>
                </TableContainer>




        </Box>


        <Grid item xs={12} mt={2}>
          {/* <Typography variant="body" sx={{ fontWeight: 'bold' }}>Payment Details:</Typography> */}
          <GradientChip label="Payment Detail" />
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="body" sx={{ fontWeight: 'bold' }}>Transaction Id</Typography>
            <Typography>{payment?.TransId}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body" sx={{ fontWeight: 'bold' }}>Application No.</Typography>
            <Typography>{summary?.ApplicationNo}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body" sx={{ fontWeight: 'bold' }}>Applicant Name</Typography>
            <Typography>{summary?.FirstName}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body" sx={{ fontWeight: 'bold' }}>Paid By</Typography>
            <Typography>Card</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body" sx={{ fontWeight: 'bold' }}>Total Paid Amount</Typography>
            <Typography>₹ {payment?.Amount}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body" sx={{ fontWeight: 'bold' }}>Paid At</Typography>
            <Typography>{payment?.CreatedOn}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SummaryDetails;


// DocumentLayout.js
// import React from 'react';
// import { Container, Typography, Grid, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
// import mmrda from '../../../../assets/mmrda.png';
// import barcode from '../../../../assets/barcode.png';
// const DocumentLayout = () => {
//   return (
//     <Container maxWidth="lg" sx={{ marginTop: 2 }}>
//       <Grid container spacing={2}>
//         <Grid item xs={12} style={{ backgroundColor: '#f8f8f8', padding: 16 }}>
//           <Grid container spacing={2}>
//             <Grid item xs={12} md={4}>
//               <img src={mmrda} alt="Logo" width="150" />
//             </Grid>
//             <Grid item xs={12} md={8} style={{ backgroundColor: '#15479E', color: '#FFF', textAlign: 'center', padding: 16 }}>
//               <Typography variant="h6">MUMBAI METROPOLITAN REGION DEVELOPMENT AUTHORITY</Typography>
//               <Typography variant="h6">मुंबई महानगर प्रदेश विकास प्राधिकरण</Typography>
//             </Grid>
//           </Grid>
//         </Grid>
        
//         <Grid item xs={12} style={{ backgroundColor: '#f2f9ff', padding: 16 }}>
//           <Grid container spacing={2}>
//             <Grid item xs={6}>
//               <Typography variant="h6" color="#00437E">Mortgage Application Details</Typography>
//             </Grid>
//             <Grid item xs={6} style={{ textAlign: 'right' }}>
//               <Typography variant="h6" color="#00437E">Applied on: <strong>22 Jul, 2024</strong></Typography>
//             </Grid>
//           </Grid>
//         </Grid>

//         {/* Other Sections */}

//         <Grid item xs={12}>
//           <Paper elevation={3} style={{ padding: 16, borderRadius: 16 }}>
//             <Grid container spacing={2}>
//               <Grid item xs={4}>
//                 <Typography variant="h6">Application ID: 23000001954</Typography>
//               </Grid>
//               <Grid item xs={4} />
//               <Grid item xs={4}>
//                 <img src={barcode} alt="Barcode" width="150" />
//               </Grid>
//             </Grid>
//             <Box mt={2}>
//               <Typography variant="h6" color="#00437E">Mortgage Details</Typography>
//               <TableContainer component={Paper}>
//                 <Table>
//                   <TableHead>
//                     <TableRow>
//                       <TableCell><Typography variant="h6">Field</Typography></TableCell>
//                       <TableCell><Typography variant="h6">Value</Typography></TableCell>
//                     </TableRow>
//                   </TableHead>
//                   <TableBody>
//                     <TableRow>
//                       <TableCell><Typography>Full Name</Typography></TableCell>
//                       <TableCell><Typography>Pavan</Typography></TableCell>
//                     </TableRow>
//                     <TableRow>
//                       <TableCell><Typography>Gender</Typography></TableCell>
//                       <TableCell><Typography>Other</Typography></TableCell>
//                     </TableRow>
//                     <TableRow>
//                       <TableCell><Typography>Age</Typography></TableCell>
//                       <TableCell><Typography>29 Years</Typography></TableCell>
//                     </TableRow>
//                     <TableRow>
//                       <TableCell><Typography>Marital Status</Typography></TableCell>
//                       <TableCell><Typography>Unmarried</Typography></TableCell>
//                     </TableRow>
//                     <TableRow>
//                       <TableCell><Typography>Category</Typography></TableCell>
//                       <TableCell><Typography>General</Typography></TableCell>
//                     </TableRow>
//                     <TableRow>
//                       <TableCell><Typography>Type of Employment</Typography></TableCell>
//                       <TableCell><Typography>Employee</Typography></TableCell>
//                     </TableRow>
//                     <TableRow>
//                       <TableCell><Typography>Annual Income</Typography></TableCell>
//                       <TableCell><Typography>₹ 8,40,000</Typography></TableCell>
//                     </TableRow>
//                     <TableRow>
//                       <TableCell><Typography>Occupation</Typography></TableCell>
//                       <TableCell><Typography>Software Engineer</Typography></TableCell>
//                     </TableRow>
//                     <TableRow>
//                       <TableCell><Typography>Property Address</Typography></TableCell>
//                       <TableCell><Typography>Address Here</Typography></TableCell>
//                     </TableRow>
//                     <TableRow>
//                       <TableCell><Typography>Contact Number</Typography></TableCell>
//                       <TableCell><Typography>1234567890</Typography></TableCell>
//                     </TableRow>
//                     <TableRow>
//                       <TableCell><Typography>Email Address</Typography></TableCell>
//                       <TableCell><Typography>pavan@example.com</Typography></TableCell>
//                     </TableRow>
//                     <TableRow>
//                       <TableCell><Typography>Reference Number</Typography></TableCell>
//                       <TableCell><Typography>REF123456</Typography></TableCell>
//                     </TableRow>
//                     <TableRow>
//                       <TableCell><Typography>Date of Birth</Typography></TableCell>
//                       <TableCell><Typography>01 Jan 1995</Typography></TableCell>
//                     </TableRow>
//                   </TableBody>
//                 </Table>
//               </TableContainer>
//             </Box>
//           </Paper>
//         </Grid>

//         {/* Terms and Conditions Section */}
//         <Grid item xs={12} style={{ padding: 16 }}>
//           <Typography variant="h6" color="#00437E">Terms and Conditions</Typography>
//           <ul>
//             <li>I/We have read carefully the terms and conditions mentioned in the scheme booklet and declare that, I shall abide by the same.</li>
//             <li>I/We hereby confirm that, I/We are eligible to participate in the scheme.</li>
//             <li>I/We agree to pay the full amount of the apartment as and when allotted to me/us.</li>
//             <li>I/We undertake that, I/We shall sign all such document/s and do all such acts, so as to enable me/us to get allotment of the apartment through computerized lottery system.</li>
//             <li>The information given herein above is true and correct to the best of my knowledge. I/We are aware that, if the above information is found to be false and misleading, MMRDA has all the rights to cancel the apartment at any stage at my/our cost and consequences, without assigning any reason thereof.</li>
//             <li>MMRDA has the right to amend, revoke the scheme/ layout / specification / elevation of building and I/We shall accept the allotment of apartment as may be offered to me/us.</li>
//             <li>The allotment of the apartment is strictly in accordance with the terms and conditions framed thereto. In case of breach of any of the condition of allotment, MMRDA has sole right to exercise its power to take any action it deems fit including cancellation of allotment of apartment which shall be binding on me/us.</li>
//             <li>I/We will submit all requisite document/s required by MMRDA within specified time in support of my application.</li>
//           </ul>
//         </Grid>

//         {/* Signature Section */}
//         <Grid item xs={12} style={{ textAlign: 'left', padding: 16 }}>
//           <Typography>Date: [Date Here]</Typography>
//           <Typography>Signature: [Signature Here]</Typography>
//         </Grid>
//       </Grid>
//     </Container>
//   );
// };

// export default DocumentLayout;