// import React, { useEffect } from 'react';
// import { TextField, FormControlLabel, Radio, RadioGroup, Grid } from '@material-ui/core';
// import { useFormik } from 'formik';
// import * as Yup from 'yup';
// import { Button } from '@material-ui/core';
// import { useDispatch, useSelector } from 'react-redux';
// import { MortgageBankDetailed, PmayNonPmaySelector } from '../../../../redux/features/pmayNonPmay/pmayNonPmaySlice';
// import { RegistrationStepperSave } from '../../../../redux/features/registration/registrationStepperSlice';

// const BankDetailsForm = (props) => {
//   const { active, data } = props;
//   const dispatch = useDispatch();
//   const { isFetchingMortgageBankDetail, isSuccessMortgageBankDetail } = useSelector(PmayNonPmaySelector);

//   const defaultValues = {
//     bank_name: '',
//     bank_branch_name: '',
//     bank_ifsc_code: '',
//     bank_address: '',
//     loanAvailed: 'option22', // Default to 'No'
//   };

//   const formik = useFormik({
//     initialValues: data || defaultValues,
//     validationSchema: Yup.object({
//       bank_ifsc_code: Yup.string()
//         .max(11, 'Must be 11 characters or less')
//         .required('IFSC Code is required'),
//       // Add more validations as needed
//     }),
//     onSubmit: (values) => {
//       console.log(values); // Handle form submission logic here
//       dispatch(MortgageBankDetailed(values));
//     },
//   });

//   useEffect(() => {
//     if (isSuccessMortgageBankDetail) {
//       dispatch(RegistrationStepperSave('7'));
//     }
//   }, [isSuccessMortgageBankDetail]);

//   return (
//     <div>
//       <form onSubmit={formik.handleSubmit}>
//         <Grid container spacing={1}>
       

       
//           <Grid container spacing={3}>
//             <Grid item xs={6}>
//             <TextField
//                 fullWidth
//                 id="bank_ifsc_code"
//                 name="bank_ifsc_code"
//                 label="IFSC Code"
//                 placeholder="IFSC Code"
//                 variant="outlined"
//                 margin="dense"
//                 onChange={formik.handleChange}
//                 value={formik.values.bank_ifsc_code}
//                 error={formik.touched.bank_ifsc_code && Boolean(formik.errors.bank_ifsc_code)}
//                 helperText={formik.touched.bank_ifsc_code && formik.errors.bank_ifsc_code}
//               />
//               <TextField
//                 fullWidth
//                 id="bank_name"
//                 name="bank_name"
//                 label="Name of Bank"
//                 placeholder="Name of Bank"
//                 variant="outlined"
//                 margin="dense"
//                 onChange={formik.handleChange}
//                 value={formik.values.bank_name}
//               />
              
//             </Grid>
//             <Grid item xs={6}>
//               <TextField
//                 fullWidth
//                 id="bank_branch_name"
//                 name="bank_branch_name"
//                 label="Branch Name"
//                 placeholder="Branch Name"
//                 variant="outlined"
//                 margin="dense"
//                 onChange={formik.handleChange}
//                 value={formik.values.bank_branch_name}
//               />
//               <TextField
//                 fullWidth
//                 id="bank_address"
//                 name="bank_address"
//                 label="Address"
//                 placeholder="Enter Address"
//                 multiline
//                 rows={5}
//                 variant="outlined"
//                 margin="dense"
//                 onChange={formik.handleChange}
//                 value={formik.values.bank_address}
//               />
//             </Grid>
//           </Grid>
       
//           </Grid>

//         <Button type="submit" variant="contained" color="primary" style={{ marginTop: '10px' }}>
//           Submit
//         </Button>
//       </form>
//     </div>
//   );
// };

// export default BankDetailsForm;


import React, { useEffect } from 'react';
import { TextField, FormControlLabel, Radio, RadioGroup, Grid, CircularProgress } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { MortgageBankDetailed, PmayNonPmaySelector } from '../../../../redux/features/pmayNonPmay/pmayNonPmaySlice';
import { RegistrationStepperSave } from '../../../../redux/features/registration/registrationStepperSlice';
import AlertBox from '../../../atoms/AlertBox/AlertBox';

const BankDetailsForm = (props) => {
  const { data } = props;
  const dispatch = useDispatch();
  const { isSuccessMortgageBankDetail,isErrorMortgageBankDetail,errorMsgMortgageBankDetail,isFetchingMortgageBankDetail } = useSelector(PmayNonPmaySelector);

  const defaultValues = {
    bank_name: '',
    bank_branch_name: '',
    bank_ifsc_code: '',
    bank_address: '',
    
  };

  const formik = useFormik({
    initialValues: data || defaultValues,
    validationSchema: Yup.object({
      bank_ifsc_code: Yup.string()
        .max(11, 'Must be 11 characters or less')
        .required('IFSC Code is required'),
    }),
    onSubmit: (values) => {
     // console.log(values); // Handle form submission logic here
      dispatch(MortgageBankDetailed(values));
    },
  });

  useEffect(() => {
    if (isSuccessMortgageBankDetail) {
      dispatch(RegistrationStepperSave('7'));
    }
  }, [isSuccessMortgageBankDetail, dispatch]);

  useEffect(() => {
    const fetchIFSCDetails = async () => {
      const { bank_ifsc_code } = formik.values;
      if (bank_ifsc_code && bank_ifsc_code.length === 11) {
        try {
          const response = await fetch(`https://ifsc.razorpay.com/${bank_ifsc_code}`);
          if (response.ok) {
            const data = await response.json();
            formik.setFieldValue('bank_name', data.BANK);
            formik.setFieldValue('bank_branch_name', data.BRANCH);
            formik.setFieldValue('bank_address', `${data.ADDRESS}, ${data.CITY}, ${data.STATE}`);
          } else {
            formik.setFieldError('bank_ifsc_code', 'Invalid IFSC Code');
          }
        } catch (error) {
          formik.setFieldError('bank_ifsc_code', 'Invalid IFSC Code');
        }
      }
    };

    fetchIFSCDetails();
  }, [formik.values.bank_ifsc_code]);

  return (
    <div>
        {isErrorMortgageBankDetail && (
                  <AlertBox severity="error">{errorMsgMortgageBankDetail}</AlertBox>
                )}
      
      <form onSubmit={formik.handleSubmit}>
      

       
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="bank_ifsc_code"
                name="bank_ifsc_code"
                label="IFSC Code"
                placeholder="IFSC Code"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                margin="dense"
                onChange={formik.handleChange}
                value={formik.values.bank_ifsc_code}
                error={formik.touched.bank_ifsc_code && Boolean(formik.errors.bank_ifsc_code)}
                helperText={formik.touched.bank_ifsc_code && formik.errors.bank_ifsc_code}
              />
              <TextField
                fullWidth
                id="bank_name"
                name="bank_name"
                label="Name of Bank"
                placeholder="Name of Bank"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                margin="dense"
                onChange={formik.handleChange}
                value={formik.values.bank_name}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="bank_branch_name"
                name="bank_branch_name"
                label="Branch Name"
                placeholder="Branch Name"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                margin="dense"
                onChange={formik.handleChange}
                value={formik.values.bank_branch_name}
              />
              <TextField
                fullWidth
                id="bank_address"
                name="bank_address"
                label="Address"
                placeholder="Enter Address"
                InputLabelProps={{ shrink: true }}
                multiline
                rows={5}
                variant="outlined"
                margin="dense"
                onChange={formik.handleChange}
                value={formik.values.bank_address}
              />
            </Grid>
          </Grid>
       

          <Grid container alignItems="flex-end" style={{ marginTop: 8 }} justifyContent="flex-end">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          disabled={isFetchingMortgageBankDetail}
        >
          {isFetchingMortgageBankDetail && <CircularProgress size={20} style={{ marginRight: "10px" }} />}
          {isFetchingMortgageBankDetail ? 'Saving...' : 'Save & Continue'}
        </Button>
        </Grid>
      </form>
    </div>
  );
};

export default BankDetailsForm;
