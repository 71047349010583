// import React, { useEffect, useState } from 'react';
// import {
//     Box,
//     Typography,
//     FormControl,
//     RadioGroup,
//     FormControlLabel,
//     Radio,
//     Table,
//     TableBody,
//     TableCell,
//     TableContainer,
//     TableHead,
//     TableRow,
//     Paper,
//     Divider,
//     FormLabel,
//     Grid,
//     Button,
//     makeStyles,
//     withStyles,
// } from '@material-ui/core';
// import { useFormik } from 'formik';
// import AlertBox from '../../../atoms/AlertBox/AlertBox';
// import { RegistrationStepperSave, RegistrationStepperSelector } from '../../../../redux/features/registration/registrationStepperSlice';
// import { useDispatch, useSelector } from 'react-redux';
// import { useHistory } from 'react-router-dom';

// const useStyles = makeStyles({
//     table: {
//         minWidth: 700,
//     },
// });

// const StyledTableCell = withStyles((theme) => ({
//     head: {
//         backgroundColor: '#15489f',
//         color: theme.palette.common.white,
//         fontSize: 12,
//     },
//     body: {
//         fontSize: 12,
//     },
// }))(TableCell);

// const StyledTableRow = withStyles((theme) => ({
//     root: {
//         '&:nth-of-type(odd)': {
//             backgroundColor: theme.palette.action.hover,
//         },
//     },
// }))(TableRow);

// const ProcessingFeesDetails = () => {
//     const {
//         isSuccessRegStepper,
//     } = useSelector(RegistrationStepperSelector);
//     const history = useHistory();
//     const dispatch=useDispatch();
//     const classes = useStyles();
//     const formik = useFormik({
//         initialValues: {
//             post_facto_approval -		enum(yes,no)
// processing_fee		-	float(11,2) (25,000)
// processing_fee_gst  - 	float(11,2) (Processing Fee - 18% GST)
// assignment_charges	- 	float(11,2) (Stamp Duty 10% on Document Register Amount)
// penalty				-	float(11,2) (10,000)
// penalty_gst  		- 	float(11,2) (Post Facto Consent - 18% GST)
// terms_and_conditions -
//             penalty: 'no',
//         },
//         onSubmit: (values) => {
//             console.log(values);
//             if(values){
//             dispatch(RegistrationStepperSelector("11"))}
//         },
//     });


// useEffect(()=>{
//     if (isSuccessRegStepper) {
//         history.push('/summary');
//     }
// },[isSuccessRegStepper])

//     const getCurrentDate = () => {
//         const now = new Date();
//         const day = String(now.getDate()).padStart(2, '0');
//         const month = String(now.getMonth() + 1).padStart(2, '0');
//         const year = now.getFullYear();
//         return `${day}-${month}-${year}`;
//     };

//     const calculateTotalAmount = () => {
//         let total = 64200; // Base amount without penalty
//         if (formik.values.penalty === 'yes') {
//             total += 11800; // Penalty + GST
//         }
//         return total;
//     };
//     const handleProceedToPayment = () => {
//         dispatch(RegistrationStepperSave('11'));
//     };
//     return (
//         <Box>
//             <form onSubmit={formik.handleSubmit}>
//                 <Grid item xs={12}>
//                     <FormControl component="fieldset">
//                         <FormLabel component="legend">
//                             Is This Application For Post Facto Approval?
//                         </FormLabel>
//                         <RadioGroup
//                             row
//                             aria-label="penalty"
//                             name="penalty"
//                             value={formik.values.penalty}
//                             onChange={formik.handleChange}
//                         >
//                             <FormControlLabel value="yes" control={<Radio />} label="Yes" />
//                             <FormControlLabel value="no" control={<Radio />} label="No" />
//                         </RadioGroup>
//                     </FormControl>
//                 </Grid>
//                 {formik.values.penalty === 'yes' && (
//                     <AlertBox severity="error">
//                         <Typography variant="body1" color="error">
//                             10% + GST Penalty will be Applicable For Post Facto Approval
//                         </Typography>
//                     </AlertBox>
//                 )}
//                 <Box display="flex" justifyContent="space-between" alignItems="center">
//                     <Typography style={{ fontWeight: 600 }}>Payment Details</Typography>
//                     <Typography style={{ fontWeight: 600 }}>Date: {getCurrentDate()}</Typography>
//                 </Box>
//                 <Divider />
//                 <TableContainer component={Paper}>
//                     <Table className={classes.table} aria-label="customized table">
//                         <TableHead>
//                             <StyledTableRow>
//                                 <StyledTableCell>Qty</StyledTableCell>
//                                 <StyledTableCell>Fee Type</StyledTableCell>
//                                 <StyledTableCell>Serial #</StyledTableCell>
//                                 <StyledTableCell>Description</StyledTableCell>
//                                 <StyledTableCell>Subtotal</StyledTableCell>
//                             </StyledTableRow>
//                         </TableHead>
//                         <TableBody>
//                             <StyledTableRow>
//                                 <StyledTableCell>1</StyledTableCell>
//                                 <StyledTableCell>Processing Fee</StyledTableCell>
//                                 <StyledTableCell>455-981-221</StyledTableCell>
//                                 <StyledTableCell>Processing Fee Details</StyledTableCell>
//                                 <StyledTableCell>₹ 25,000</StyledTableCell>
//                             </StyledTableRow>
//                             <StyledTableRow>
//                                 <StyledTableCell>2</StyledTableCell>
//                                 <StyledTableCell>Processing Fee GST</StyledTableCell>
//                                 <StyledTableCell>455-981-222</StyledTableCell>
//                                 <StyledTableCell>Processing Fee - 18% GST</StyledTableCell>
//                                 <StyledTableCell>₹ 4,500</StyledTableCell>
//                             </StyledTableRow>
//                             <StyledTableRow>
//                                 <StyledTableCell>3</StyledTableCell>
//                                 <StyledTableCell>Assignment Charges</StyledTableCell>
//                                 <StyledTableCell>735-845-642</StyledTableCell>
//                                 <StyledTableCell>Stamp Duty 10% on Document Register Amount</StyledTableCell>
//                                 <StyledTableCell>₹ 35,000</StyledTableCell>
//                             </StyledTableRow>
//                             {formik.values.penalty === 'yes' && (
//                                 <>
//                                     <StyledTableRow>
//                                         <StyledTableCell>4</StyledTableCell>
//                                         <StyledTableCell>Penalty</StyledTableCell>
//                                         <StyledTableCell>422-568-642</StyledTableCell>
//                                         <StyledTableCell>Post Facto Consent</StyledTableCell>
//                                         <StyledTableCell>₹ 10,000</StyledTableCell>
//                                     </StyledTableRow>
//                                     <StyledTableRow>
//                                         <StyledTableCell>5</StyledTableCell>
//                                         <StyledTableCell>Penalty GST</StyledTableCell>
//                                         <StyledTableCell>422-568-643</StyledTableCell>
//                                         <StyledTableCell>Post Facto Consent - 18% GST</StyledTableCell>
//                                         <StyledTableCell>₹ 1,800</StyledTableCell>
//                                     </StyledTableRow>
//                                 </>
//                             )}
//                             <StyledTableRow>
//                                 <StyledTableCell colSpan={4}>
//                                     <Typography style={{ fontWeight: 600 }}>Total:</Typography>
//                                 </StyledTableCell>
//                                 <StyledTableCell>
//                                     <Typography style={{ fontWeight: 600 }}>₹ {calculateTotalAmount()}</Typography>
//                                 </StyledTableCell>
//                             </StyledTableRow>
//                         </TableBody>
//                     </Table>
//                 </TableContainer>
//                 <Divider />
//                 <Box margin={2}>
//                     <Button
//                        // type="submit"
//                         variant="contained"
//                         color="primary"
//                         size="large"
//                         onClick={handleProceedToPayment}
//                     >
//                         Proceed To Payment
//                     </Button>
//                 </Box>
//             </form>
//         </Box>
//     );
// };

// export default ProcessingFeesDetails;

import React, { useEffect } from 'react';
import {
    Box,
    Typography,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Divider,
    FormLabel,
    Grid,
    Button,
    Checkbox,
    makeStyles,
    withStyles,
    CircularProgress,
} from '@material-ui/core';
import { useFormik } from 'formik';
import AlertBox from '../../../atoms/AlertBox/AlertBox';
import { RegistrationStepperSave, RegistrationStepperSelector } from '../../../../redux/features/registration/registrationStepperSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PmayNonPmaySelector, PostFactoApproval } from '../../../../redux/features/pmayNonPmay/pmayNonPmaySlice';

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
    rightAlign: {
        textAlign: 'right',
    },
});

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#15489f',
        color: theme.palette.common.white,
        fontSize: 12,
    },
    body: {
        fontSize: 12,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const ProcessingFeesDetails = (props) => {
    const { active, data } = props;
    const { isSuccessRegStepper } = useSelector(RegistrationStepperSelector);
    const { isFetchingPostFactoDetail, isSuccessPostFactoDetail } = useSelector(PmayNonPmaySelector);
    const history = useHistory();
    const dispatch = useDispatch();
    const classes = useStyles();

    const formik = useFormik({
        initialValues: {
            post_facto_approval: data?.post_facto_approval || 'no',
            processing_fee: 25000.00,
            processing_fee_gst: 4500.00,
            assignment_charges: 35000.00,
            penalty: 10000.00,
            penalty_gst: 1800.00,
            terms_and_conditions: data?.terms_and_conditions || false,
        },
        onSubmit: (values) => {
            console.log(values);
            dispatch(PostFactoApproval(values));
        },
    });

    useEffect(() => {
        if (isSuccessPostFactoDetail) {
            dispatch(RegistrationStepperSave('11'));
        }
    }, [isSuccessPostFactoDetail]);

    useEffect(() => {
        if (isSuccessRegStepper) {
            history.push('/summary');
        }
    }, [isSuccessRegStepper, history]);

    const getCurrentDate = () => {
        const now = new Date();
        const day = String(now.getDate()).padStart(2, '0');
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const year = now.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const calculateTotalAmount = () => {
        let total = formik.values.processing_fee + formik.values.processing_fee_gst + formik.values.assignment_charges;
        if (formik.values.post_facto_approval === 'yes') {
            total += formik.values.penalty + formik.values.penalty_gst;
        }
        return total.toFixed(2);
    };

    return (
        <Box>
            <form onSubmit={formik.handleSubmit}>
                <Grid item xs={12}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">
                            Is This Application For Post Facto Approval?
                        </FormLabel>
                        <RadioGroup
                            row
                            aria-label="post_facto_approval"
                            name="post_facto_approval"
                            value={formik.values.post_facto_approval}
                            onChange={formik.handleChange}
                        >
                            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                            <FormControlLabel value="no" control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                {formik.values.post_facto_approval === 'yes' && (
                    <AlertBox severity="error">
                        <Typography variant="body1" color="error">
                            10% + GST Penalty will be Applicable For Post Facto Approval
                        </Typography>
                    </AlertBox>
                )}
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography style={{ fontWeight: 600 }}>Payment Details</Typography>
                    <Typography style={{ fontWeight: 600 }}>Date: {getCurrentDate()}</Typography>
                </Box>
                <Divider />
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="customized table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell>Qty</StyledTableCell>
                                <StyledTableCell>Fee Type</StyledTableCell>
                                <StyledTableCell>Serial #</StyledTableCell>
                                <StyledTableCell>Description</StyledTableCell>
                                <StyledTableCell className={classes.rightAlign}>Subtotal</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            <StyledTableRow>
                                <StyledTableCell>1</StyledTableCell>
                                <StyledTableCell>Processing Fee</StyledTableCell>
                                <StyledTableCell>455-981-221</StyledTableCell>
                                <StyledTableCell>Processing Fee Details</StyledTableCell>
                                <StyledTableCell className={classes.rightAlign}>₹ {formik.values.processing_fee.toFixed(2)}</StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <StyledTableCell>2</StyledTableCell>
                                <StyledTableCell>Processing Fee GST</StyledTableCell>
                                <StyledTableCell>455-981-222</StyledTableCell>
                                <StyledTableCell>Processing Fee - 18% GST</StyledTableCell>
                                <StyledTableCell className={classes.rightAlign}>₹ {formik.values.processing_fee_gst.toFixed(2)}</StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <StyledTableCell>3</StyledTableCell>
                                <StyledTableCell>Assignment Charges</StyledTableCell>
                                <StyledTableCell>735-845-642</StyledTableCell>
                                <StyledTableCell>Stamp Duty 10% on Document Register Amount</StyledTableCell>
                                <StyledTableCell className={classes.rightAlign}>₹ {formik.values.assignment_charges.toFixed(2)}</StyledTableCell>
                            </StyledTableRow>
                            {formik.values.post_facto_approval === 'yes' && (
                                <>
                                    <StyledTableRow>
                                        <StyledTableCell>4</StyledTableCell>
                                        <StyledTableCell>Penalty</StyledTableCell>
                                        <StyledTableCell>422-568-642</StyledTableCell>
                                        <StyledTableCell>Post Facto Consent</StyledTableCell>
                                        <StyledTableCell className={classes.rightAlign}>₹ {formik.values.penalty.toFixed(2)}</StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell>5</StyledTableCell>
                                        <StyledTableCell>Penalty GST</StyledTableCell>
                                        <StyledTableCell>422-568-643</StyledTableCell>
                                        <StyledTableCell>Post Facto Consent - 18% GST</StyledTableCell>
                                        <StyledTableCell className={classes.rightAlign}>₹ {formik.values.penalty_gst.toFixed(2)}</StyledTableCell>
                                    </StyledTableRow>
                                </>
                            )}
                            <StyledTableRow>
                                <StyledTableCell colSpan={4}>
                                    <Typography style={{ fontWeight: 600 }}>Total:</Typography>
                                </StyledTableCell>
                                <StyledTableCell className={classes.rightAlign}>
                                    <Typography style={{ fontWeight: 600 }}>₹ {calculateTotalAmount()}</Typography>
                                </StyledTableCell>
                            </StyledTableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <Divider />
                {active === 10 && (
                    <Box margin={2}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="terms_and_conditions"
                                    color="primary"
                                    checked={formik.values.terms_and_conditions}
                                    onChange={formik.handleChange}
                                />
                            }
                            label="I agree to the terms and conditions"
                        />
                        <Grid container alignItems="flex-end" style={{ marginTop: 8 }} justifyContent="flex-end">
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="large"
                                disabled={isFetchingPostFactoDetail || !formik.values.terms_and_conditions}
                            >
                                {isFetchingPostFactoDetail && <CircularProgress size={20} style={{ marginRight: "10px" }} />}
                                {isFetchingPostFactoDetail ? 'Saving...' : 'Save & Continue'}
                            </Button>
                        </Grid>
                    </Box>
                )}
            </form>
        </Box>
    );
};

export default ProcessingFeesDetails;
