import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import withWidth from "@material-ui/core/withWidth";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import Loading from "../../atoms/Loading/Loading";
import AlertBox from "../../atoms/AlertBox/AlertBox";
import CloseIcon from "@material-ui/icons/Close";
import MailIcon from "@material-ui/icons/Mail";
import {
  Chip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Slide,
  Snackbar,
  Tab,
  Tabs,
} from "@material-ui/core";
import _ from "lodash";
import { Alert } from "@material-ui/lab";
import { green } from "@material-ui/core/colors";
import BankLeadTable from "./BankLeadTable/BankLeadTable";
import BankLeadFilter from "./BankLeadFilter/BankLeadFilter";
import {
  agentProfileSelector,
  clearGetAgentData,
  getAgentProfileData,
} from "../../../redux/features/agent/AgentProfileSlice";
import {
  agentApplicantSelector,
  getAgentReservationCategories,
} from "../../../redux/features/agent/AgentApplicantsSlice";
import { ManagebankLeadStyles } from "./ManagebankLead.styles";
import {
  bankLeadSelector,
  clearBankLeadMailState,
  getBankLeads,
} from "../../../redux/features/agent/AgentBankLeadSlice";
import moment from "moment";

const ColorButton = withStyles((theme) => ({
  root: {
    color: "#fff",
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
}))(Button);

function TransitionLeft(props) {
  return <Slide {...props} direction="right" />;
}

const ManageBankLeadView = (props) => {
  const { width } = props;
  const { t } = useTranslation("AgentLeadPageTrans");
  const classes = ManagebankLeadStyles();
  const dispatch = useDispatch();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSort, setSelectedSort] = useState("dsc");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [isPmay, setIsPmay] = useState(null);
  const [selfFunding, setSelfFunding] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedBank, setSelectedBank] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [storeLeadData, setStoreLeadData] = useState([]);
  const history = useHistory();
  const [searchedText, setSearchedText] = React.useState("");
  const [clear, setClear] = React.useState(false);
  const [leadsCounts, setLeadsCount] = useState();
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [agentProfile, setAgentProfile] = useState(null);
  const { isFetchingAgentProfile, agentProfileData, isSuccessResAgentProfile } =
    useSelector(agentProfileSelector);
  const [filterCategoryData, setFilterCategoryData] = useState([]);
  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [selectedToDate, setSelectedToDate] = useState(null);
  const [allLeadData, setAllLeadData] = useState();
  const [isHOD, setIsHOD] = useState(false);
  const [banksList, setBanksList] = React.useState([]);
  const [team, setTeam] = React.useState([]);

  const {
    isFetchingBankLead,
    isSuccessBankLead,
    isErrorBankLead,
    errorMsgBankLead,
    bankLeadData,
    isFetchingAssignLead,
    isSuccessAssignLead,
    isErrorAssignLead,
    errorMsgAssignLead,
    assignLeadData,
  } = useSelector(bankLeadSelector);
  const {
    isFetchingSendBankLeadMail,
    isSuccessSendBankLeadMail,
    isErrorSendBankLeadMail,
    errorMsgSendBankLeadMail,
    sendBankLeadMailData,
  } = useSelector(bankLeadSelector);

  const handleListItemClick = (event, index) => {
    setSelectedStatus(index);
  };

  const { allCategory, isSuccessMasterData } = useSelector(
    agentApplicantSelector
  );

  React.useEffect(() => {
    dispatch(getAgentReservationCategories());
    dispatch(getAgentProfileData());
    return () => {
      dispatch(clearGetAgentData());
    };
  }, []);

  useEffect(() => {
    if (isSuccessMasterData && allCategory) {
      const uniqueData = _.uniqBy(allCategory, "value");
      setFilterCategoryData(uniqueData);
    }
  }, [t, isSuccessMasterData, allCategory]);

  React.useEffect(() => {
    if (isSuccessResAgentProfile && agentProfileData.AgentDetails.length > 0) {
      setAgentProfile(agentProfileData?.AgentDetails[0]);
    }
  }, [isSuccessResAgentProfile, agentProfileData]);

  useEffect(() => {
    if (agentProfile) {
      if (agentProfile.source === 'sales') {
        history.push('/cfc-applicants-analytics-dashboard')
      }
      if (agentProfile.UserType === 'HOD') {
        setIsHOD(true);
      }
    }
  }, [agentProfile])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    setPage(1);
  }, [searchedText]);

  useEffect(() => {
    if (isSuccessResAgentProfile) {
      getAppDetailsWithFilter();
      dispatch(getBankLeads(agentProfileData?.AgentDetails[0]?.UserType));
      // setPage(0);
    }
  }, [
    isSuccessResAgentProfile,
    searchedText,
    selectedCategory,
    selectedSort,
    selectedStatus,
    team,
    page,
    rowsPerPage,
    isPmay,
    selfFunding,
    selectedBank,
    selectedFromDate,
    selectedToDate,
  ]);

  useEffect(() => {
    if (isSuccessAssignLead) {
      getAppDetailsWithFilter();
      dispatch(getBankLeads(agentProfileData?.AgentDetails[0]?.UserType));
      // setPage(0);
    }
  }, [isSuccessAssignLead]);

  useEffect(() => {
    if (isSuccessBankLead) {
      setStoreLeadData(bankLeadData.CustomerList);
      setAllLeadData(bankLeadData);
      setLeadsCount(bankLeadData.Total);
    }
  }, [bankLeadData, isSuccessBankLead]);

  const getAppDetailsWithFilter = () => {
    let params = "";
    if (rowsPerPage) {
      params += "&perpage=" + rowsPerPage;
    }
    if (page) {
      params += "&page=" + page;
    }
    if (searchedText) {
      params += "&search=" + searchedText;
    }
    if (selectedCategory) {
      params += "&category=" + selectedCategory;
    }
    if (selectedSort) {
      params += "&sortby=" + selectedSort;
    }
    if (isPmay) {
      params += "&isPmay=" + isPmay;
    }
    if (selfFunding) {
      params += "&selfFunding=" + selfFunding;
    }

    if (selectedBank) {
      const selectedBanklabel = banksList.find(bank => bank.value === selectedBank);
      if (selectedBanklabel) {
        params += "&bank=" + selectedBanklabel?.label;
      }
    }
    if (selectedStatus) {
      params += "&status=" + selectedStatus;
    }
    if (selectedFromDate) {
      params += "&start_date=" + moment(selectedFromDate).format("YYYY-MM-DD");
    }
    if (selectedToDate) {
      params += "&end_date=" + moment(selectedToDate).format("YYYY-MM-DD");
    }
    if (team.length > 0) {
      params += "&agent_ids=" + team;
    }
    localStorage.setItem("bankLeadApiParam", params);
    window.scroll({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
    // dispatch(getBankLeads());
  };


  return (
    <div className={classes.root}>
      {isFetchingBankLead && <Loading isOpen={isFetchingBankLead} />}
      {isErrorBankLead && (
        <AlertBox elevation={1} severity="error">
          {errorMsgBankLead}
        </AlertBox>
      )}
      <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} open={isErrorSendBankLeadMail} autoHideDuration={3000} TransitionComponent={TransitionLeft} onClose={() => dispatch(clearBankLeadMailState())}>
        <Alert severity="error" variant="filled" onClose={() => dispatch(clearBankLeadMailState())}>
          {errorMsgSendBankLeadMail}
        </Alert>
      </Snackbar>
      <Snackbar open={isSuccessSendBankLeadMail}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        autoHideDuration={4000}
        TransitionComponent={TransitionLeft}
        onClose={() => dispatch(clearBankLeadMailState())}
      >
        <Alert severity="success" variant="filled" onClose={() => dispatch(clearBankLeadMailState())}>
          {"Mail Sent Successfully"}
        </Alert>
      </Snackbar>
      <Paper elevation={4}>
        <div className={classes.container}>
          <Grid xs={3} className={`${classes.leadHeader} innerSection`}>
            <Grid container className={classes.innerGrid}>
              <Grid item>
                <Typography
                  variant="h6"
                  className={classes.sectionCardTitle}
                  style={{ margin: "10px" }}
                >
                  Fire NOC Applications
                </Typography>
              </Grid>
              <List component="nav" className={classes.NavList}>
                <ListItem
                  button
                  selected={selectedStatus === ""}
                  className={selectedStatus === "" ? "active" : ""}
                  onClick={(event) => handleListItemClick(event, "")}
                >
                  <ListItemText primary="All Applications" />
                  <Chip
                    label={allLeadData?.AllLeadsCount || 0}
                    size="small"
                    color="primary"
                    className={`${classes.chipColor}`}
                  />
                </ListItem>
                {isHOD && <ListItem
                  button
                  selected={selectedStatus === 1}
                  className={selectedStatus === 1 ? "active" : ""}
                  onClick={(event) => handleListItemClick(event, 1)}
                >
                  <ListItemText primary="Unassigned Applications" />
                  <Chip
                    label={allLeadData?.StatusWiseCount[1]?.LeadCount || 0}
                    size="small"
                    color="primary"
                    className={`${classes.chipColor} _1`}
                  />
                </ListItem>}
                <ListItem
                  button
                  selected={selectedStatus === 2}
                  className={selectedStatus === 2 ? "active" : ""}
                  onClick={(event) => handleListItemClick(event, 2)}
                >
                  <ListItemText primary="Assigned Applications" />
                  <Chip
                    label={allLeadData?.StatusWiseCount[2]?.LeadCount || 0}
                    size="small"
                    color="primary"
                    className={`${classes.chipColor} _2`}
                  />
                </ListItem>
                <ListItem
                  button
                  selected={selectedStatus === 3}
                  className={selectedStatus === 3 ? "active" : ""}
                  onClick={(event) => handleListItemClick(event, 3)}
                >
                  <ListItemText primary="Challan Uploaded" />
                  {console.log(allLeadData?.StatusWiseCount[3]?.LeadCount,"gdgdgdg")}
                  <Chip
                    label={allLeadData?.StatusWiseCount[3]?.LeadCount || 0}
                    size="small"
                    color="primary"
                    className={`${classes.chipColor} _3`}
                  />
                </ListItem>
                <ListItem
                  button
                  selected={selectedStatus === 4}
                  className={selectedStatus === 4 ? "active" : ""}
                  onClick={(event) => handleListItemClick(event, 4)}
                >
                  <ListItemText primary="Challan Approved" />
                  <Chip
                    label={allLeadData?.StatusWiseCount[4]?.LeadCount || 0}
                    size="small"
                    color="primary"
                    className={`${classes.chipColor} _4`}
                  />
                </ListItem>
                <ListItem
                  button
                  selected={selectedStatus === 5}
                  className={selectedStatus === 5 ? "active" : ""}
                  onClick={(event) => handleListItemClick(event, 5)}
                >
                  <ListItemText primary="Challan Rejected" />
                  <Chip
                    label={allLeadData?.StatusWiseCount[5]?.LeadCount || 0}
                    size="small"
                    color="primary"
                    className={`${classes.chipColor} _5`}
                  />
                </ListItem>
                <ListItem
                  button
                  selected={selectedStatus === 6}
                  className={selectedStatus === 6 ? "active" : ""}
                  onClick={(event) => handleListItemClick(event, 6)}
                >
                  <ListItemText primary="Receipt Uploaded" />
                  <Chip
                    label={allLeadData?.StatusWiseCount[6]?.LeadCount || 0}
                    size="small"
                    color="primary"
                    className={`${classes.chipColor} _6`}
                  />
                </ListItem>
                <ListItem
                  button
                  selected={selectedStatus === 7}
                  className={selectedStatus === 7 ? "active" : ""}
                  onClick={(event) => handleListItemClick(event, 7)}
                >
                  <ListItemText primary="NOC Generated" />
                  <Chip
                    label={allLeadData?.StatusWiseCount[7]?.LeadCount || 0}
                    size="small"
                    color="primary"
                    className={`${classes.chipColor} _7`}
                  />
                </ListItem>
                <ListItem
                  button
                  selected={selectedStatus === 8}
                  className={selectedStatus === 8 ? "active" : ""}
                  onClick={(event) => handleListItemClick(event, 7)}
                >
                  <ListItemText primary="Receipt Rejected" />
                  <Chip
                    label={allLeadData?.StatusWiseCount[8]?.LeadCount || 0}
                    size="small"
                    color="primary"
                    className={`${classes.chipColor} _8`}
                  />
                </ListItem>
         
                
                
              </List>
            </Grid>
          </Grid>
          <Grid item style={{ marginLeft: "22%" }}>
            {filterCategoryData && (
              <BankLeadFilter
                searchedText={searchedText}
                setSearchedText={setSearchedText}
                setSelectedCategory={setSelectedCategory}
                selectedCategory={selectedCategory}
                setSelectedSort={setSelectedSort}
                setSelectedStatus={setSelectedStatus}
                setClear={setClear}
                clear={clear}
                setSelectedTab={setSelectedTab}
                filterCategoryData={filterCategoryData}
                selectedStatus={selectedStatus}
                team={team}
                setTeam={setTeam}
                setPage={setPage}
                isPmay={isPmay}
                setIsPmay={setIsPmay}
                selfFunding={selfFunding}
                setSelfFunding={setSelfFunding}
                selectedBank={selectedBank}
                setSelectedBank={setSelectedBank}
                selectedFromDate={selectedFromDate}
                setSelectedFromDate={setSelectedFromDate}
                selectedToDate={selectedToDate}
                setSelectedToDate={setSelectedToDate}
                banksList={banksList}
                setBanksList={setBanksList}
              />
            )}
            {isSuccessBankLead && (
              <BankLeadTable
                managerList={allLeadData?.ManagerList}
                storeLeadData={storeLeadData}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                page={page}
                setPage={setPage}
                setSelectedSort={setSelectedSort}
                selectedSort={selectedSort}
                filterCategoryData={filterCategoryData}
                agentProfile={agentProfile}
                selectedStatus={selectedStatus}
                count={leadsCounts}
                isHOD={isHOD}
              />
            )}
          </Grid>
        </div>
      </Paper>
    </div>
  );
};

export default withWidth()(ManageBankLeadView);
