import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    FormHelperText,
    Grid,
    IconButton,
    TextField,
    Typography,
    useEventCallback,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";


import { clearImageUrl, fileDataUpload, fileUploadSelector } from "../../../../redux/features/file/FileUploadSlice";
import { useDispatch, useSelector } from "react-redux";
import AlertBox from "../../../atoms/AlertBox/AlertBox";
import { SupportedFormats } from "../../../../utils/Common";
import Upload from "../../../../assets/Upload.svg";
import UploadedKyc from "../../../../assets/UploadedKyc.svg";
import { useTranslation } from "react-i18next";
import { FileUploadIcon } from "../../../atoms/SvgIcons/SvgIcons";
import { initialPagesStyles } from "../../InitialPagesComponents/InitialPages.styles";
import { LeeseeDetailed, PmayNonPmaySelector } from "../../../../redux/features/pmayNonPmay/pmayNonPmaySlice";



export default function UploadChallanDialog(props) {
    const { open, onClose,dataaa } = props;
    const classes = initialPagesStyles();
    const dispatch = useDispatch();
    const [data, setData] = useState({});
    const [isUploaded, setUploaded] = useState(false);
    const [isImage, setIsImage] = useState(false);
    const { t } = useTranslation("InitialPageTrans");
    const {
        isFileFetching,
        isFileSuccess,
        imageUrl,
        isFileError,
        fileErrorMessage,
    } = useSelector(fileUploadSelector);
    const { isFetchingLeeseeDetail,
        isSuccessLeeseeDetail ,isErrorLeeseeDetail,
        errorMsgLeeseeDetail,} = useSelector(PmayNonPmaySelector);
    // Form state
    const [dragBoxClass, setDragBoxClass] = useState("");
    const [formData, setFormData] = useState({
        amountDue: "",
        amountDate: "",
        challanFile: imageUrl,
    });
    useEffect(() => {
        if (isSuccessLeeseeDetail) {
            onClose();
            window.location.reload();  // This will refresh the page
        }
    }, [isSuccessLeeseeDetail, onClose]);
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFormData({
                ...formData,
                challanFile: file,
            });
            setData(file);
        }
    };

    const handleSubmit = () => {
        const data={
            ...formData,
            challanFile: imageUrl,
            RequestId:dataaa.RequestId,
            
        }
        console.log("Form data submitted:", data);
        dispatch(LeeseeDetailed(data));
      
    };
 
    
    const dragOver = (e) => {
        e.preventDefault();
        setDragBoxClass("dragover");
    };

    const dragEnter = (e) => {
        e.preventDefault();
        setDragBoxClass("dragover");
    };

    const dragLeave = (e) => {
        e.preventDefault();
        setDragBoxClass("");
    };

    const fileDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file) {
            setFormData({
                ...formData,
                challanFile: file,
            });
            setData(file);
            setDragBoxClass("");
        }
    };

    const onReUpload = () => {
        setFormData({
            ...formData,
            challanFile: null,
        });
        setData({});
        setUploaded(false);
        dispatch(clearImageUrl());
    };

    useEffect(() => {
        if (data.name) {
            if (SupportedFormats.DocsFormats.includes(data.type)) {
                setIsImage(data.type.startsWith("image"));
                const sendData = {
                    doc: data,
                    docName: "Challan",
                };
                dispatch(fileDataUpload(sendData));
            }
        }
    }, [data, dispatch]);

    useEffect(() => {
        if (isFileSuccess) {
            setUploaded(true);
        }
    }, [isFileSuccess]);

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle className={classes.dialogBoxTitle}>
                <Typography variant="h2">Upload Challan</Typography>
                <IconButton
                    className={classes.closeButton}
                    onClick={onClose}
                    size="small"
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            label="Payable Amount"
                            name="amountDue"
                            fullWidth
                            variant="outlined"
                            value={formData.amountDue}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Due Date"
                            name="amountDate"
                            type="date"
                            fullWidth
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={formData.amountDate}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        {!isUploaded && !isFileFetching && (
                            <Box
                                textAlign="center"
                                margin={2}
                                p={5}
                                className={`${classes.kycDragnDropBox} ${dragBoxClass}`}
                                onDragOver={dragOver}
                                onDragEnter={dragEnter}
                                onDragLeave={dragLeave}
                                onDrop={fileDrop}
                            >
                                {isFileError && (
                                    <AlertBox severity="error">
                                        {fileErrorMessage}
                                    </AlertBox>
                                )}
                                <Box textAlign="center" marginBottom={3}>
                                    <img
                                        className={classes.iconStyle}
                                        src={Upload}
                                        alt="Upload"
                                    />
                                </Box>
                                <Box>
                                    {t("kycBank.formControl.upload.dragNdDropText")}
                                    <input
                                        accept="image/jpeg,image/png,application/pdf,image/x-eps"
                                        className={classes.input}
                                        id="fileData"
                                        type="file"
                                        name="fileData"
                                        onChange={handleFileChange}
                                    />
                                    <label htmlFor="fileData">
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            component="span"
                                            className={classes.kycUploadBtn}
                                            size="small"
                                        >
                                             Upload Payment Challan
                                        </Button>
                                    </label>
                                </Box>
                                <FormHelperText
                                    error
                                    variant="filled"
                                    className={classes.helperTextBox}
                                >
                                  
                                </FormHelperText>
                                <Box margin={2}>
                                    <Typography style={{ fontSize: 12 }}>
                                        {t("kycBank.formControl.upload.fileLimit0")}
                                        <strong>
                                            {" "}
                                            {t("kycBank.formControl.upload.fileLimit1")}{" "}
                                        </strong>
                                        {t("kycBank.formControl.upload.fileLimit2")}
                                    </Typography>
                                </Box>
                            </Box>
                        )}
                        {isUploaded && !isFileFetching && (
                            <Box>
                                <Typography
                                    className={classes.kycCaption}
                                    variant="subtitle1"
                                >
                                    {t("kycBank.formControl.upload.kycCaptionTxt1")}
                                </Typography>
                                {isImage ? (
                                    <Box className={classes.panCardPreviewCard}>
                                        <img
                                            className={classes.panPreviewImg}
                                            src={imageUrl}
                                            alt="uploaded successfully"
                                        />
                                    </Box>
                                ) : (
                                    <Box
                                        textAlign="center"
                                        className={classes.panCardPreviewCard}
                                    >
                                        <img
                                            className={classes.panPreviewImg}
                                            src={UploadedKyc}
                                            alt="uploaded successfully"
                                        />
                                        <Typography className={classes.kycCaption}>
                                            {t("kycBank.formControl.upload.success")}
                                        </Typography>
                                    </Box>
                                )}
                                <Box textAlign="left" marginTop={2}>
                                    <Typography
                                        className={classes.kycCaption}
                                        variant="subtitle1"
                                    >
                                        {t("kycBank.formControl.upload.uploaded")}
                                    </Typography>
                                </Box>
                                <Box className={classes.fileViewArea}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item xs="auto">
                                            <FileUploadIcon color="primary" />
                                        </Grid>
                                        <Grid item xs zeroMinWidth>
                                        <Typography
                            variant="body2"
                            className={classes.fileUrlPreview}
                          >
                            {imageUrl || "--"}
                          </Typography>
                        </Grid>
                        <Grid item xs="auto">
                          <Button
                            variant="text"
                            size="small"
                            color="primary"
                            onClick={onReUpload}
                          >
                            {t("kycBank.formControl.upload.reupload")}
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                )}
                  </Grid>
                 </Grid>
                 <Box mt={3} display="flex" justifyContent="flex-end">
                     <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
}
