import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndPoint, fallback_msg } from "../../../utils/Common";
import { handleErrMsg } from "../../../utils/UtilsFunction";


export const getBankLeads = createAsyncThunk(
  "agent/BankLead",
  async (UserType, thunkAPI) => {
    try {
      const response = await axios.get(`${ApiEndPoint}/FireNocLeads/getAgentLeads/${localStorage.getItem("agentcode")}?roles=${UserType}${localStorage.getItem("bankLeadApiParam")}`);
      let responseData = await response.data;

      if (response.status === 200) {
        return responseData;
      } else {
        return thunkAPI.rejectWithValue(responseData);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const assignBankLeads = createAsyncThunk(
  "agent/assignLead",
  async ({ leadIds, managerID }, thunkAPI) => {
    const sendData = { FireNocLeadId: leadIds }
    try {
      const response = await axios.post(`${ApiEndPoint}/FireNocLeads/AssignFireNocLeads/${managerID}`, sendData);
      let responseData = await response.data;

      if (response.status === 200) {
        return responseData;
      } else {
        return thunkAPI.rejectWithValue(responseData);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// bank Lead Mail Api
export const sendBankLeadsMail = createAsyncThunk(
  "agent/sendBankLeadMail",
  async (data, thunkAPI) => {

    try {
      const response = await axios.post(`${ApiEndPoint}/AgentCSV/sendBankLeadEmail`, data);
      let responseData = await response.data;

      if (response.status === 200) {
        return responseData;
      } else {
        return thunkAPI.rejectWithValue(responseData);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getBankLeadComment = createAsyncThunk(
  "agent/BankLead/getComment",
  async (leadID, thunkAPI) => {
    try {
      const response = await axios.get(`${ApiEndPoint}/AgentLeads/BankLeadLogs/${localStorage.getItem("agentcode")}?LeadId=${leadID}`);
      let responseData = await response.data;
      if (response.status === 200) {
        return responseData;
      } else {
        return thunkAPI.rejectWithValue(responseData);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const addBankLeadComment = createAsyncThunk(
  "agent/BankLead/addComment",
  async (requestData, thunkAPI) => {
    try {
      const response = await axios.post(
        ApiEndPoint + "/AgentLeads/AddBankLeadComment",
        requestData
      );
      let responseData = await response.data;
      if (response.status === 200) {
        return responseData;
      } else {
        return thunkAPI.rejectWithValue(responseData);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e);
    }
  }
);

export const updateBankLeadStatus = createAsyncThunk(
  "agent/BankLead/updateLeadStatus",
  async (requestData, thunkAPI) => {
    try {
      const response = await axios.patch(
        ApiEndPoint + `/AgentLeads/ChangeBankLeadStatus/${localStorage.getItem('agentcode')}`,
        requestData
      );
      let responseData = await response.data;
      if (response.status === 200) {
        return responseData;
      } else {
        return thunkAPI.rejectWithValue(responseData);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e);
    }
  }
);

export const bankLeadSlice = createSlice({
  name: "bankLead",
  initialState: {
    isFetchingBankLead: false,
    isSuccessBankLead: false,
    isErrorBankLead: false,
    errorMsgBankLead: "",
    bankLeadData: {},
    //bank lead state
    isFetchingSendBankLeadMail: false,
    isSuccessSendBankLeadMail: false,
    isErrorSendBankLeadMail: false,
    errorMsgSendBankLeadMail: "",
    sendBankLeadMailData: {},

    isFetchingAssignLead: false,
    isSuccessAssignLead: false,
    isErrorAssignLead: false,
    errorMsgAssignLead: "",
    assignLeadData: {},

    isFetchingBankComment: false,
    isSuccessBankComment: false,
    isErrorBankComment: false,
    errorMsgBankComment: "",
    bankCommentData: {},

    isFetchingBankAddComment: false,
    isSuccessBankAddComment: false,
    isErrorBankAddComment: false,
    errorMsgBankAddComment: "",
    bankAddCommentData: {},

    isFetchingChangeBankStatus: false,
    isSuccessChangeBankStatus: false,
    isErrorChangeBankStatus: false,
    errorMsgChangeBankStatus: "",
    changeStatusBankData: {},

  },
  reducers: {
    clearBankLeadState: (state) => {
      state.isFetchingBankLead = false;
      state.isSuccessBankLead = false;
      state.isErrorBankLead = false;
      state.errorMsgBankLead = "";

    },
    clearBankLeadMailState: (state) => {
      state.isFetchingSendBankLeadMail = false;
      state.isSuccessSendBankLeadMail = false;
      state.isErrorSendBankLeadMail = false;
      state.errorMsgSendBankLeadMail = "";
      state.sendBankLeadMailData = {};
    },

    clearAssignLeadState: (state) => {
      state.isFetchingAssignLead = false;
      state.isSuccessAssignLead = false;
      state.isErrorAssignLead = false;
      state.errorMsgAssignLead = "";

    },
    clearbankLeadData: (state) => {
      state.bankLeadData = {};
      state.updatebankLeadData = {};
    },
    clearBankCommentState: (state) => {
      state.isFetchingBankComment = false;
      state.isSuccessBankComment = false;
      state.isErrorBankComment = false;
      state.errorMsgBankComment = "";
      state.bankCommentData = {}
    },
    clearBankAddCommentState: (state) => {
      state.isFetchingBankAddComment = false;
      state.isSuccessBankAddComment = false;
      state.isErrorBankAddComment = false;
      state.errorMsgBankAddComment = "";
    },
    clearBankChangeStatusState: (state) => {
      state.isFetchingChangeBankStatus = false;
      state.isSuccessChangeBankStatus = false;
      state.isErrorChangeBankStatus = false;
      state.errorMsgChangeBankStatus = "";
      state.successMsgChangeStatus = "";
    },
  },
  extraReducers: {
    [getBankLeads.fulfilled]: (state, { payload }) => {
      state.isFetchingBankLead = false;
      state.bankLeadData = payload.data;
      state.isSuccessBankLead = payload.success;
      state.isErrorBankLead = payload.error;
      state.errorMsgBankLead = handleErrMsg(payload);
    },
    [getBankLeads.pending]: (state) => {
      state.isFetchingBankLead = true;
    },
    [getBankLeads.rejected]: (state, { payload }) => {
      state.isFetchingBankLead = false;
      state.isErrorBankLead = true;
      state.errorMsgBankLead = handleErrMsg(payload);
    },
    // send bank lead data mail
    [sendBankLeadsMail.fulfilled]: (state, { payload }) => {
      state.isFetchingSendBankLeadMail = false;
      state.sendBankLeadMailData = payload.data;
      state.isSuccessSendBankLeadMail = payload.success;
      state.isErrorSendBankLeadMail = payload.error;
      state.errorMsgSendBankLeadMail = handleErrMsg(payload);
    },
    [sendBankLeadsMail.pending]: (state) => {
      state.isFetchingSendBankLeadMail = true;
    },
    [sendBankLeadsMail.rejected]: (state, { payload }) => {
      state.isFetchingSendBankLeadMail = false;
      state.isErrorSendBankLeadMail = true;
      state.errorMsgSendBankLeadMail = handleErrMsg(payload);
    },

    [assignBankLeads.fulfilled]: (state, { payload }) => {
      state.isFetchingAssignLead = false;
      state.assignLeadData = payload.data;
      state.isSuccessAssignLead = payload.success;
      state.isErrorAssignLead = payload.error;
      state.errorMsgAssignLead = handleErrMsg(payload);
    },
    [assignBankLeads.pending]: (state) => {
      state.isFetchingAssignLead = true;
    },
    [assignBankLeads.rejected]: (state, { payload }) => {
      state.isFetchingAssignLead = false;
      state.isErrorAssignLead = true;
      state.errorMsgAssignLead = handleErrMsg(payload);
    },
    [getBankLeadComment.fulfilled]: (state, { payload }) => {
      state.isFetchingBankComment = false;
      state.bankCommentData = payload.data;
      state.isSuccessBankComment = payload.success;
      state.errorMsgBankComment = payload.message
    },
    [getBankLeadComment.pending]: (state) => {
      state.isFetchingBankComment = true;
    },
    [getBankLeadComment.rejected]: (state, { payload }) => {
      state.isFetchingBankComment = false;
      state.isErrorBankComment = payload?.error ? payload?.error : true;
      state.errorMsgBankComment = payload?.message ? payload?.message : fallback_msg;
    },
    [addBankLeadComment.fulfilled]: (state, { payload }) => {
      state.isFetchingBankAddComment = false;
      state.isSuccessBankAddComment = payload.success;
      state.isErrorBankAddComment = payload?.error
      state.errorMsgBankAddComment = payload.message
    },
    [addBankLeadComment.pending]: (state) => {
      state.isFetchingBankAddComment = true;
    },
    [addBankLeadComment.rejected]: (state, { payload }) => {
      state.isFetchingBankAddComment = false;
      state.isErrorBankAddComment = payload?.error ? payload?.error : true;
      state.errorMsgBankAddComment = payload?.message ? payload?.message : fallback_msg;
    },
    [updateBankLeadStatus.fulfilled]: (state, { payload }) => {
      state.isFetchingChangeBankStatus = false;
      state.changeStatusBankData = payload.data;
      state.isSuccessChangeBankStatus = payload.success;
      state.isErrorChangeBankStatus = payload?.error;
      state.errorMsgChangeBankStatus = payload?.message;
    },
    [updateBankLeadStatus.pending]: (state) => {
      state.isFetchingChangeBankStatus = true;
    },
    [updateBankLeadStatus.rejected]: (state, { payload }) => {
      state.isFetchingChangeBankStatus = false;
      state.isErrorChangeBankStatus = payload?.error ? payload?.error : true;
      state.errorMsgChangeBankStatus = payload?.message ? payload?.message : fallback_msg;
    },

  },
});

export const { clearBankLeadState, clearAssignLeadState, clearBankLeadMailState, clearbankLeadData, clearBankCommentState, clearBankAddCommentState, clearBankChangeStatusState, clearAddLeadState, clearEditLeadState } = bankLeadSlice.actions;

export const bankLeadSelector = (state) => state.bankLead;
