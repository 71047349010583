// import React, { useState, useEffect } from 'react';
// import { useFormik } from 'formik';
// import * as Yup from 'yup';
// import {
//     TextField,
//     MenuItem,
//     Button,
//     Grid,
// } from '@material-ui/core';
// import { useDispatch, useSelector } from 'react-redux';
// import { clearImageUrl, fileDataUpload, fileUploadSelector } from '../../../../redux/features/file/FileUploadSlice';
// import { PmayNonPmaySelector, PreviousNocDetailed } from '../../../../redux/features/pmayNonPmay/pmayNonPmaySlice';
// import { RegistrationStepperSave } from '../../../../redux/features/registration/registrationStepperSlice';

// const validationSchema = Yup.object({
//     letterNo: Yup.string().required('Letter No is required'),
//     date: Yup.string().required('Date is required'),
//     documentRegDetails: Yup.string().required('Document Reg. Details is required'),
//     regNo: Yup.string().required('Reg. No is required'),
//     nocLetter: Yup.mixed().required('NOC Letter is required'),
//     index2: Yup.mixed().required('Index-II is required'),
//     receiptUpload: Yup.mixed().required('Receipt Upload is required'),
//     receiptDate: Yup.string().required('Receipt Date is required'),
// });

// const PreviousNOCDetailsForm = (props) => {
//     const { active, data } = props;
//     const [nocLetterPreview, setNocLetterPreview] = useState(null);
//     const [indexIIPreview, setIndexIIPreview] = useState(null);
//     const [receiptUploadPreview, setReceiptUploadPreview] = useState(null);
//     const [uploadingField, setUploadingField] = useState('');
//     const [nocLetterUrl, setNocLetterUrl] = useState(null);
//     const [indexIIUrl, setIndexIIUrl] = useState(null);
//     const [receiptUploadUrl, setReceiptUploadUrl] = useState(null);
//     const dispatch = useDispatch();
//     const { isFileFetching, isFileSuccess, imageUrl, isFileError, fileErrorMessage } = useSelector(fileUploadSelector);
//     const { isFetchingPreviousNocDetail,
//         isSuccessPreviousNocDetail } = useSelector(PmayNonPmaySelector)
//         const initialPrevNocDetails = JSON.parse(data?.prevNOCDetails || '{}');
//         const formik = useFormik({
//             initialValues: {
//                 // letterNo: initialPrevNocDetails?.letterNo || '',
//                 // date: initialPrevNocDetails?.date || '',
//                 // documentRegDetails: initialPrevNocDetails?.documentRegDetails || '',
//                 // regNo: initialPrevNocDetails?.regNo || '',
//                 // nocLetter: initialPrevNocDetails?.nocLetter || null,
//                 // index2: initialPrevNocDetails?.index2 || null,
//                 // receiptUpload: initialPrevNocDetails?.receiptUpload || null,
//                 // receiptDate: initialPrevNocDetails?.receiptDate || '',
//                 loan_sanction_letter_doc - 	varchar 255
// pre_noc_letter_no - 	varchar 255
// pre_noc_date - 	date
// pre_noc_doc_reg - 	varchar 255
// pre_noc_reg_no	 - 	varchar 255
// pre_noc_letter_doc	 - 	varchar 255
// pre_noc_index_ii_doc	 - 	varchar 255
// pre_noc_receipt_doc	 - 	varchar 255
// company_name - 	varchar 255

//             },
//         validationSchema,
//         onSubmit: (values) => {
//             const data = {
//                 ...values,
//                 nocLetter: nocLetterUrl,
//                 index2: indexIIUrl,
//                 receiptUpload: receiptUploadUrl,
//             };
//             console.log('Form data on submit:', data);
//             dispatch(PreviousNocDetailed(JSON.stringify({  prevNOCDetails: data })));
//             // prevNOCDetails
//         },
//     });


//     useEffect(() => {
//         if ( isSuccessPreviousNocDetail) {

//             dispatch(clearImageUrl());
//             dispatch(RegistrationStepperSave("9"))
//         }
//     }, [ isSuccessPreviousNocDetail]);

//     useEffect(() => {
//         if (isFileSuccess && imageUrl) {
//             if (uploadingField === 'nocLetter') {
//                 setNocLetterUrl(imageUrl);
//                 formik.setFieldValue('nocLetter', imageUrl);
//                 console.log('Noc Letter URL:', imageUrl);
//             } else if (uploadingField === 'index2') {
//                 setIndexIIUrl(imageUrl);
//                 formik.setFieldValue('index2', imageUrl);
//                 console.log('Index-II URL:', imageUrl);
//             } else if (uploadingField === 'receiptUpload') {
//                 setReceiptUploadUrl(imageUrl);
//                 formik.setFieldValue('receiptUpload', imageUrl);
//                 console.log('Receipt Upload URL:', imageUrl);
//             }
//             dispatch(clearImageUrl());
//             setUploadingField('');
//         }
//     }, [isFileSuccess, imageUrl, uploadingField, formik, dispatch]);

//     const handleFileChange = (event, fieldName) => {
//         const file = event.currentTarget.files[0];
//         if (file) {
//             formik.setFieldValue(fieldName, file);
//             const data = { doc: file, docName: fieldName };
//             dispatch(fileDataUpload(data));
//             const previewUrl = URL.createObjectURL(file);
//             if (fieldName === 'nocLetter') {
//                 setNocLetterPreview(previewUrl);
//             } else if (fieldName === 'index2') {
//                 setIndexIIPreview(previewUrl);
//             } else if (fieldName === 'receiptUpload') {
//                 setReceiptUploadPreview(previewUrl);
//             }
//             setUploadingField(fieldName);
//         }
//     };


    
//     return (
//         <form onSubmit={formik.handleSubmit}>
//             <Grid container spacing={1}>
//                 <Grid item xs={12} md={6}>
//                     <TextField
//                         fullWidth
//                         id="letterNo"
//                         name="letterNo"
//                         label="Letter No"
//                         placeholder="Letter No"
//                         value={formik.values.letterNo}
//                         onChange={formik.handleChange}
//                         onBlur={formik.handleBlur}
//                         margin="dense"
//                         variant="outlined"
//                         error={formik.touched.letterNo && Boolean(formik.errors.letterNo)}
//                         helperText={formik.touched.letterNo && formik.errors.letterNo}
//                     />
//                 </Grid>
//                 <Grid item xs={12} md={6}>
//                     <TextField
//                         id="nocLetter"
//                         name="nocLetter"
//                         label="Upload NOC Letter"
//                         fullWidth
//                         type="file"
//                         variant="outlined"
//                         margin="dense"
//                         onChange={(event) => handleFileChange(event, 'nocLetter')}
//                         onBlur={formik.handleBlur}
//                         InputLabelProps={{ shrink: true }}
//                         error={formik.touched.nocLetter && Boolean(formik.errors.nocLetter)}
//                         helperText={formik.touched.nocLetter && formik.errors.nocLetter}
//                     />
//                     {nocLetterPreview && (
//                         <img src={nocLetterPreview} alt="NOC Letter Preview" style={{ maxWidth: '100px', marginTop: '10px' }} />
//                     )}
//                 </Grid>
//                 <Grid item xs={12} md={6}>
//                     <TextField
//                         fullWidth
//                         id="date"
//                         name="date"
//                         label="Date"
//                         placeholder="Date"
//                         variant="outlined"
//                         margin="dense"
//                         type='date'
//                         InputLabelProps={{ shrink: true }}
//                         value={formik.values.date}
//                         onChange={formik.handleChange}
//                         onBlur={formik.handleBlur}
//                         error={formik.touched.date && Boolean(formik.errors.date)}
//                         helperText={formik.touched.date && formik.errors.date}
//                     />
//                 </Grid>
//                 <Grid item xs={12} md={6}>
//                     <TextField
//                         id="index2"
//                         name="index2"
//                         fullWidth
//                         type="file"
//                         variant="outlined"
//                         label="Upload Index-II of register document (Both Sides)"
//                         margin="dense"
//                         onChange={(event) => handleFileChange(event, 'index2')}
//                         onBlur={formik.handleBlur}
//                         InputLabelProps={{ shrink: true }}
//                         error={formik.touched.index2 && Boolean(formik.errors.index2)}
//                         helperText={formik.touched.index2 && formik.errors.index2}
//                     />
//                     {indexIIPreview && (
//                         <img src={indexIIPreview} alt="Index-II Preview" style={{ maxWidth: '100px', marginTop: '10px' }} />
//                     )}
//                 </Grid>
//                 <Grid item xs={12} md={6}>
//                     <TextField
//                         fullWidth
//                         labelId="documentRegDetails-label"
//                         label="Document Reg. Details"
//                         id="documentRegDetails"
//                         name="documentRegDetails"
//                         select
//                         value={formik.values.documentRegDetails}
//                         onChange={formik.handleChange}
//                         onBlur={formik.handleBlur}
//                         error={formik.touched.documentRegDetails && Boolean(formik.errors.documentRegDetails)}
//                         helperText={formik.touched.documentRegDetails && formik.errors.documentRegDetails}
//                         margin="dense"
//                         variant="outlined"
//                     >
//                         <MenuItem value="Jt Registrar Office">Jt Registrar Office</MenuItem>
//                         <MenuItem value="Sub Registrar Office">Sub Registrar Office</MenuItem>
//                     </TextField>
//                 </Grid>
//                 <Grid item xs={12} md={6}>
//                     <TextField
//                         id="receiptUpload"
//                         name="receiptUpload"
//                         fullWidth
//                         type="file"
//                         label="Upload Receipt"
//                         variant="outlined"
//                         margin="dense"
//                         onChange={(event) => handleFileChange(event, 'receiptUpload')}
//                         onBlur={formik.handleBlur}
//                         InputLabelProps={{ shrink: true }}
//                         error={formik.touched.receiptUpload && Boolean(formik.errors.receiptUpload)}
//                         helperText={formik.touched.receiptUpload && formik.errors.receiptUpload}
//                     />
//                     {receiptUploadPreview && (
//                         <img src={receiptUploadPreview} alt="Receipt Upload Preview" style={{ maxWidth: '100px', marginTop: '10px' }} />
//                     )}
//                 </Grid>
//                 <Grid item xs={12} md={6}>
//                     <TextField
//                         fullWidth
//                         id="regNo"
//                         name="regNo"
//                         label="Reg. No"
//                         placeholder="Reg. No"
//                         variant="outlined"
//                         margin="dense"
//                         value={formik.values.regNo}
//                         onChange={formik.handleChange}
//                         onBlur={formik.handleBlur}
//                         error={formik.touched.regNo && Boolean(formik.errors.regNo)}
//                         helperText={formik.touched.regNo && formik.errors.regNo}
//                     />
//                 </Grid>
//                     <Grid item xs={12} md={6}>
//                      <TextField
//                         fullWidth
//                         id="receiptDate"
//                         name="receiptDate"
//                         label="Receipt Date"
//                         placeholder="Receipt Date"
//                         variant="outlined"
//                         margin="dense"
//                         type='date'
//                         value={formik.values.receiptDate}
//                         onChange={formik.handleChange}
//                         onBlur={formik.handleBlur}
//                         error={formik.touched.receiptDate && Boolean(formik.errors.receiptDate)}
//                         helperText={formik.touched.receiptDate && formik.errors.receiptDate}
//                         InputLabelProps={{ shrink: true }}
//                     />
//                 </Grid>
//             </Grid>
//             <Button color="primary" variant="contained" type="submit" style={{ marginTop: '10px' }}>
//                 Submit
//             </Button>
//         </form>
//     );
// };

// export default PreviousNOCDetailsForm;

import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
    TextField,
    MenuItem,
    Button,
    Grid,
    CircularProgress,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { clearImageUrl, fileDataUpload, fileUploadSelector } from '../../../../redux/features/file/FileUploadSlice';
import { getSummaryDetails, PmayNonPmaySelector, PreviousNocDetailed } from '../../../../redux/features/pmayNonPmay/pmayNonPmaySlice';
import { RegistrationStepperSave, RegistrationStepperSelector } from '../../../../redux/features/registration/registrationStepperSlice';
import AlertBox from '../../../atoms/AlertBox/AlertBox';

const validationSchema = Yup.object({
    pre_noc_letter_no: Yup.string().required('Letter No is required'),
    pre_noc_date: Yup.string().required('Date is required'),
    pre_noc_doc_reg: Yup.string().required('Document Reg. Details is required'),
    pre_noc_reg_no: Yup.string().required('Reg. No is required'),
    pre_noc_letter_doc: Yup.mixed().required('NOC Letter is required'),
    pre_noc_index_ii_doc: Yup.mixed().required('Index-II is required'),
    pre_noc_receipt_doc: Yup.mixed().required('Receipt Upload is required'),
    company_name: Yup.string().required('Company Name is required'),
});

const PreviousNOCDetailsForm = (props) => {
    const { active, data } = props;
    const [nocLetterPreview, setNocLetterPreview] = useState(null);
    const [indexIIPreview, setIndexIIPreview] = useState(null);
    const [receiptUploadPreview, setReceiptUploadPreview] = useState(null);
    const [uploadingField, setUploadingField] = useState('');
    const [nocLetterUrl, setNocLetterUrl] = useState(null);
    const [indexIIUrl, setIndexIIUrl] = useState(null);
    const [receiptUploadUrl, setReceiptUploadUrl] = useState(null);
    const dispatch = useDispatch();
    const { isFileFetching, isFileSuccess, imageUrl, isFileError, fileErrorMessage } = useSelector(fileUploadSelector);
    const { isFetchingPreviousNocDetail, isSuccessPreviousNocDetail,isErrorPreviousNocDetail,errorMsgPreviousNocDetail } = useSelector(PmayNonPmaySelector);
    const { summaryData, isSuccessResSummary } = useSelector(PmayNonPmaySelector);
    const { isFetchingRegStepper}=useSelector(RegistrationStepperSelector)
   
    useEffect(() => {
        dispatch(getSummaryDetails());
        dispatch(clearImageUrl());
    }, [])

    
    const formik = useFormik({
        initialValues: {
            pre_noc_letter_no: data?.pre_noc_letter_no || '',
            pre_noc_date: data?.pre_noc_date || '',
            pre_noc_doc_reg: data?.pre_noc_doc_reg || '',
            pre_noc_reg_no: data?.pre_noc_reg_no || '',
            pre_noc_letter_doc:'',
            pre_noc_index_ii_doc: '',
            pre_noc_receipt_doc:  '',
           
        },
      
        onSubmit: (values) => {
            const data = {
                ...values,
                pre_noc_letter_doc: nocLetterUrl,
                pre_noc_index_ii_doc: indexIIUrl,
                pre_noc_receipt_doc: receiptUploadUrl,
            };
           // console.log('Form data on submit:', data);
            dispatch(PreviousNocDetailed(data ));
        },
    });
    function hasImageExtension(url) {
        if (!url) {
          return false;
        }
        const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webp', '.tiff'];
        return imageExtensions.some(extension => url.toLowerCase().endsWith(extension));
      }

    useEffect(()=>{
        if(isSuccessResSummary && summaryData)
        {
          const proApprovedPlanDocA = summaryData?.DocumentDetails?.pre_noc_letter_doc
          const proApprovedPlanDocB = summaryData?.DocumentDetails?.pre_noc_index_ii_doc
          const proApprovedPlanDocC = summaryData?.DocumentDetails?.pre_noc_receipt_doc
        
          formik.setFieldValue( " pre_noc_letter_doc",proApprovedPlanDocA)
          formik.setFieldValue( "pre_noc_index_ii_doc",proApprovedPlanDocB)
          formik.setFieldValue( "pre_noc_receipt_doc",proApprovedPlanDocC)
          if (hasImageExtension(proApprovedPlanDocA)) {
          
            setNocLetterPreview(proApprovedPlanDocA);
           } else {
            setNocLetterPreview("")
           }
           if (hasImageExtension(proApprovedPlanDocB)) {
             
             setIndexIIPreview(proApprovedPlanDocB);
            } else {
                setIndexIIPreview("")
            }
            if (hasImageExtension(proApprovedPlanDocC)) {
             
                setReceiptUploadPreview(proApprovedPlanDocC);
               } else {
                   setReceiptUploadPreview("")
               }            
       
          
         
         }
        
           
      
        
        
      
      },[isSuccessResSummary,summaryData])
    useEffect(() => {
        if (isSuccessPreviousNocDetail) {
            dispatch(clearImageUrl());
            dispatch(RegistrationStepperSave("9"));
        }
    }, [isSuccessPreviousNocDetail]);

    useEffect(() => {
        if (isFileSuccess && imageUrl) {
            if (uploadingField === 'pre_noc_letter_doc') {
                setNocLetterUrl(imageUrl);
                formik.setFieldValue('pre_noc_letter_doc', imageUrl);
                console.log('Noc Letter URL:', imageUrl);
            } else if (uploadingField === 'pre_noc_index_ii_doc') {
                setIndexIIUrl(imageUrl);
                formik.setFieldValue('pre_noc_index_ii_doc', imageUrl);
                console.log('Index-II URL:', imageUrl);
            } else if (uploadingField === 'pre_noc_receipt_doc') {
                setReceiptUploadUrl(imageUrl);
                formik.setFieldValue('pre_noc_receipt_doc', imageUrl);
                console.log('Receipt Upload URL:', imageUrl);
            }
            dispatch(clearImageUrl());
            setUploadingField('');
        }
    }, [isFileSuccess, imageUrl, uploadingField, formik, dispatch]);

    const handleFileChange = (event, fieldName) => {
        const file = event.currentTarget.files[0];
        if (file) {
            formik.setFieldValue(fieldName, file);
            const data = { doc: file, docName: fieldName };
            dispatch(fileDataUpload(data));
            const previewUrl = URL.createObjectURL(file);
            if (fieldName === 'pre_noc_letter_doc') {
                setNocLetterPreview(previewUrl);
            } else if (fieldName === 'pre_noc_index_ii_doc') {
                setIndexIIPreview(previewUrl);
            } else if (fieldName === 'pre_noc_receipt_doc') {
                setReceiptUploadPreview(previewUrl);
            }
            setUploadingField(fieldName);
        }
    };

    return (
        <>
        {isErrorPreviousNocDetail && (
                  <AlertBox severity="error">{errorMsgPreviousNocDetail}</AlertBox>
                )}
                  { isFileError && (
                  <AlertBox severity="error">{fileErrorMessage}</AlertBox>
                )}
                 {isFileError && (
        <AlertBox severity="error">{fileErrorMessage}</AlertBox>
      )}

        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        id="pre_noc_letter_no"
                        name="pre_noc_letter_no"
                        label="Letter No"
                        placeholder="Letter No"
                        value={formik.values.pre_noc_letter_no}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        margin="dense"
                        variant="outlined"
                        error={formik.touched.pre_noc_letter_no && Boolean(formik.errors.pre_noc_letter_no)}
                        helperText={formik.touched.pre_noc_letter_no && formik.errors.pre_noc_letter_no}
                        />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        id="pre_noc_letter_doc"
                        name="pre_noc_letter_doc"
                        label="Upload NOC Letter"
                        fullWidth
                        type="file"
                        variant="outlined"
                        margin="dense"
                        onChange={(event) => handleFileChange(event, 'pre_noc_letter_doc')}
                        onBlur={formik.handleBlur}
                        InputLabelProps={{ shrink: true }}
                        error={formik.touched.pre_noc_letter_doc && Boolean(formik.errors.pre_noc_letter_doc)}
                        helperText={formik.touched.pre_noc_letter_doc && formik.errors.pre_noc_letter_doc}
                        />
                    {nocLetterPreview && (
                        <img src={nocLetterPreview} alt="NOC Letter Preview" style={{ maxWidth: '100px', marginTop: '10px' }} />
                    )}
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        id="pre_noc_date"
                        name="pre_noc_date"
                        label="Date"
                        placeholder="Date"
                        variant="outlined"
                        margin="dense"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        value={formik.values.pre_noc_date}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.pre_noc_date && Boolean(formik.errors.pre_noc_date)}
                        helperText={formik.touched.pre_noc_date && formik.errors.pre_noc_date}
                        />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        id="pre_noc_index_ii_doc"
                        name="pre_noc_index_ii_doc"
                        fullWidth
                        type="file"
                        variant="outlined"
                        label="Upload Index-II of register document (Both Sides)"
                        margin="dense"
                        onChange={(event) => handleFileChange(event, 'pre_noc_index_ii_doc')}
                        onBlur={formik.handleBlur}
                        InputLabelProps={{ shrink: true }}
                        error={formik.touched.pre_noc_index_ii_doc && Boolean(formik.errors.pre_noc_index_ii_doc)}
                        helperText={formik.touched.pre_noc_index_ii_doc && formik.errors.pre_noc_index_ii_doc}
                        />
                    {indexIIPreview && (
                        <img src={indexIIPreview} alt="Index-II Preview" style={{ maxWidth: '100px', marginTop: '10px' }} />
                    )}
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        labelId="pre_noc_doc_reg-label"
                        label="Document Reg. Details"
                        id="pre_noc_doc_reg"
                        name="pre_noc_doc_reg"
                        select
                        value={formik.values.pre_noc_doc_reg}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.pre_noc_doc_reg && Boolean(formik.errors.pre_noc_doc_reg)}
                        helperText={formik.touched.pre_noc_doc_reg && formik.errors.pre_noc_doc_reg}
                        margin="dense"
                        variant="outlined"
                        >
                        <MenuItem value="Jt Registrar Office">Jt Registrar Office</MenuItem>
                        <MenuItem value="Sub Registrar Office">Sub Registrar Office</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        id="pre_noc_receipt_doc"
                        name="pre_noc_receipt_doc"
                        fullWidth
                        type="file"
                        label="Upload Receipt"
                        variant="outlined"
                        margin="dense"
                        onChange={(event) => handleFileChange(event, 'pre_noc_receipt_doc')}
                        onBlur={formik.handleBlur}
                        InputLabelProps={{ shrink: true }}
                        error={formik.touched.pre_noc_receipt_doc && Boolean(formik.errors.pre_noc_receipt_doc)}
                        helperText={formik.touched.pre_noc_receipt_doc && formik.errors.pre_noc_receipt_doc}
                        />
                    {receiptUploadPreview  && (
                        <img src={receiptUploadPreview} alt="Receipt Upload Preview" style={{ maxWidth: '100px', marginTop: '10px' }} />
                    )}
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        id="pre_noc_reg_no"
                        name="pre_noc_reg_no"
                        label="Reg. No"
                        placeholder="Reg. No"
                        variant="outlined"
                        margin="dense"
                        value={formik.values.pre_noc_reg_no}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.pre_noc_reg_no && Boolean(formik.errors.pre_noc_reg_no)}
                        helperText={formik.touched.pre_noc_reg_no && formik.errors.pre_noc_reg_no}
                        />
                </Grid>
               
            </Grid>
            <Grid container alignItems="center" style={{ marginTop: 8 }} justifyContent="space-between">
            <Button
          onClick={() => dispatch(RegistrationStepperSave("9"))}
          variant="outlined"
          color="primary"
          size="large"
          disabled={ isFetchingRegStepper}
          >
          { isFetchingRegStepper && <CircularProgress size={20} style={{ marginRight: "10px" }} />}
          { isFetchingRegStepper ? 'Saving...' : 'Skip & Continue'}
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          disabled={isFetchingPreviousNocDetail}
          >
          {isFetchingPreviousNocDetail && <CircularProgress size={20} style={{ marginRight: "10px" }} />}
          {isFetchingPreviousNocDetail ? 'Saving...' : 'Save & Continue'}
        </Button>
        </Grid>
        </form>
            </>
    );
};

export default PreviousNOCDetailsForm;
