import React, { useEffect, useState, useRef, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import withWidth from "@material-ui/core/withWidth";
import { Formik, Form, ErrorMessage } from "formik";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import CloseIcon from '@material-ui/icons/Close';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import IconTitle from "../../../atoms/IconTitle/IconTitle";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import FilterListIcon from '@material-ui/icons/FilterList';
import moment from "moment";
import { agentApplicantsSelector } from "../../../../redux/features/agent/AgentAnalDashboardSlice";
import FormControl from "../../../molecules/FormControl/FormControl";
import MuiFormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import GetAppIcon from '@material-ui/icons/GetApp';
import TablePagination from '@material-ui/core/TablePagination';
import ClearIcon from '@material-ui/icons/Clear';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
// import AgentApplicantCard from "../AgentApplicantCard/AgentApplicantCard";
import {
  FilterIcon
} from "../../../atoms/SvgIcons/SvgIcons";
import { getProjectsListData, clearProjectListData, projectDataSelector } from "../../../../redux/features/projectdata/ProjectDataSlice";
import Image from "../../../../assets/Profile.jpg";
import { masterDataSelector, getReservationCategories } from "../../../../redux/features/masterdata/MasterDataSlice";
import { debounce } from "lodash";
import SelectAgent from "./SelectAgent";
import { agentProfileSelector } from "../../../../redux/features/agent/AgentProfileSlice";
import { ManagebankLeadStyles } from "../ManagebankLead.styles";
import { Badge, Checkbox, Dialog, DialogActions, DialogContent, FormControlLabel, FormLabel, Popover, Radio, RadioGroup } from "@material-ui/core";
import axios from "axios";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { bankLeadSelector } from "../../../../redux/features/agent/AgentBankLeadSlice";
import { MailOutline } from "@material-ui/icons";
import BankLeadMailDialog from "../BankLeadDocumentDialog/BankLeadMailDialog";
import SnackBox from "../../../atoms/Snackbar/Snackbar";
import { Alert } from "@material-ui/lab";





// const applicationSortList = [
//   { value: "all", label: "All" },
//   // { value: "1", label: "Registration" },
//   { value: "PersonalDetails", label: "Personal Details" },
//   { value: "CategoryDetails", label: "Category Details" },
//   { value: "Submit Document", label: "Submit Document" },
//   { value: "Payment", label: "Payment" },
//   // { value: "6", label: "E-stamping and E-signing" }
// ];


const statusList = [
  {
    value: 1,
    label: "Unassigned Application",
  },
  {
    value: 2,
    label: "Assigned Application",
  },
  {
    value: 3,
    label: "Challan Uploaded",
  },
  {
    value: 4,
    label: "Challan Approved",
  },
  {
    value: 5,
    label: "Challan Rejected",
  },
  {
    value: 6,
    label: "Receipt Uploaded",
  },
  {
    value: 7,
    label: "NOC Generated",
  },
  {
    value: 8,
    label: "Receipt Rejected",
  },
];


const BankLeadFilter = (props) => {
  const {
    width,
    applicationsData,
    setSearchedText,
    searchedText,
    selectedCategory,
    setSelectedCategory,
    setSelectedSort,
    setSelectedStatus,
    selectedStatus,
    setClear,
    clear,
    setApplicationProject,
    applicationProject,
    setSelectedTab,
    disabledStatus,
    filterCategoryData,
    team,
    setTeam,
    setPage,
    isPmay,
    setIsPmay,
    selfFunding,
    setSelfFunding,
    setSelectedBank,
    selectedBank,
    selectedFromDate,
    setSelectedFromDate,
    selectedToDate,
    setSelectedToDate,
    banksList = { banksList },
    setBanksList = { setBanksList }
  } = props;
  const { t } = useTranslation("AgentLeadPageTrans");
  const classes = ManagebankLeadStyles();
  const [applicantsFullName, setApplicantsFullName] = React.useState("");
  const [gender, setGender] = React.useState("");
  const [marritalStatus, setMarritalStatus] = React.useState("");
  const [formValues, setFormValues] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [filterProjectListData, setFilterProjectListData] = useState([]);
  const [ReportsNotFound, setReportsNotFound] = React.useState(false);
  const [value, setValue] = useState('');
  const formikRef = useRef();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [composeOpen, setComposeOpen] = React.useState(false);

  const handleClickOpen = () => {
    setComposeOpen(true);
  };

  const handleCloseMail = () => {
    setComposeOpen(false);
  };

  const handleOpenFilterMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const initialValues = {
    searchLead: "",
    applicationCategory: "",
    applicationProject: "",
    applicationSort: "dsc",
    applicationStatus: selectedStatus,
  };

  const {
    projectListData,
    ProListData,
    isSuccessProjectListData,
  } = useSelector(projectDataSelector);
  const {
    isFetchingSendBankLeadMail,
    isSuccessSendBankLeadMail,
    isErrorSendBankLeadMail,
    errorMsgSendBankLeadMail,
    sendBankLeadMailData,
  } = useSelector(bankLeadSelector);
  const handleFromDateChange = (date) => {
    setSelectedFromDate(date);
  };
  const handleToDateChange = (date) => {
    setSelectedToDate(date);
  };

  const clearDateFilter = () => {
    setSelectedFromDate(null);
    setSelectedToDate(null);
  }


  const {
    allCategory,
    isSuccessMasterData
  } = useSelector(masterDataSelector);


  const { bankLeadData } = useSelector(bankLeadSelector);
  const sortOptions = [
    { value: "asc", label: t("filterSection.ascTxt") },
    { value: "dsc", label: t("filterSection.dscTxt") },
  ];

  React.useEffect(() => {
    axios.get(`https://cidcohomes.com/wp-json/wp/v2/categories?lang=en&category=2&banking_partners=1&restapi=1`)
      .then((res) => {
        var data = res?.data;
        if (data) {
          var status = data?.status;
          if (status === '200') {
            setBanksList((typeof data?.banks === 'object' && Object.getPrototypeOf(data?.banks).isPrototypeOf(Object)) ? Object.keys(data?.banks).map((bankitem, bankkey) => ({ value: data?.banks[bankitem]?.id, label: data?.banks[bankitem]?.title })) : [])
          }
        }
      }).catch((e) => {
        console.log(e, ': Error fetching banks')
      })
  }, []);

  const {
    isFetchingAgentList,
    agentListData,
    agentProfileData
  } = useSelector(agentProfileSelector)

  // useEffect(()=>{
  //   if(isSuccessMasterData){
  //     clearProjectListData()
  //   }
  // },[isSuccessMasterData])

  //   useEffect(() => {
  //     let getFilterParamsObj = localStorage.getItem("appDashboardFiltersParam");
  //     if (getFilterParamsObj !== null) {
  //       let newObj = JSON.parse(getFilterParamsObj);
  //       //console.log("filters1", newObj)
  //       if (newObj.status) {
  //         for (let p = 0; p < applicationSortList.length; p++) {
  //           const element = applicationSortList[p];
  //           if (element.label == newObj.status) {
  //             setSelectedStatus(element.value);
  //             const savedValues = {
  //               applicationSort: element.value,
  //               applicationProject: "",
  //               applicationCategory: ""
  //             };
  //             setFormValues(savedValues);
  //           }
  //         }
  //       }
  //     }
  //   }, []);

  const onSubmit = (values, { setSubmitting }) => {
    console.log("valuesdgd", values);
    setSelectedCategory(values.applicationCategory);
    setSelectedSort(values.applicationSort);
    setSelectedStatus(values.applicationStatus)
    setSubmitting(false);

    setSearchedText(values.searchLead);

  };
  function getStatusLabel(value) {
    const statusObject = statusList.find(status => status.value == value);
    return statusObject?.Title;
  }
  const formClearEvent = () => {
    // const formik = formikRef.current;
    // formik.resetForm();
    // setClear(true);
    setSelectedCategory("");
    // setSelectedSort("dsc");
    setSelectedBank('');
    setIsPmay(null);
    setSelfFunding(false);
    setSelectedFromDate(null);
    setSelectedToDate(null);
  };

  useEffect(() => {
    const formik = formikRef.current;
    formik.resetForm();
  }, [t]);


  const debounceFn = useMemo(() => debounce(handleDebounce, 500), []);

  function handleDebounce(inputValue) {
    setSearchedText(inputValue)
  };

  function handleChange(event) {
    setValue(event.target.value);
    debounceFn(event.target.value);
  };

  function handleClear(val) {
    setValue(val);
    debounceFn(val);
  };


  function convertJSONtoCSV(data) {
    const newData = data.map((item, index) => {
      const newItem = {
        "Sr.No": index + 1,
        "Date": item.bookingPaymentDate,
        "Customer Name": item.name,
        "Contact No": item.mobileNo,
        "Status": getStatusLabel(item.status),
        "Assigned To": item.AgentName,
        // "Category":item.category,
        "Age": item.age,
        "Bank Interested": item.bankInterested,
        "Assign To": item.AgentName,
        "Flat Detail": (item.flatDetails ? item.flatDetails.replace(/[{}]|,/g, '|').replace(/\|+/g, '|') : "") || "",
        "Comment": item.latestComment?.Comment.replace(/,/g, "|") || "",
      };
      return newItem;
    });

    const headers = Object.keys(newData[0]);
    const csv = [headers.join(',')];

    newData.forEach((item) => {
      const values = headers.map((header) => item[header]);
      csv.push(values.join(','));
    });

    return csv.join('\n');
  }
  const download = () => {
    if (bankLeadData?.CustomerList?.length === 0) {
      setReportsNotFound(true)
    } else {

      let filename = "report.csv"
      // const csv = convertJSONtoCSV(agentApplicantsAnalDashboardData?.agentWiseData)
      const csv = convertJSONtoCSV(bankLeadData?.CustomerList)
      const blob = new Blob([csv], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }
  }
  return (
    <Box m={2}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        innerRef={formikRef}
        enableReinitialize
      >
        {({ submitForm, values }) => (
          <Form noValidate autoComplete="off">
            <Grid container spacing={2} alignItems="center" justifyContent="space-between">
              {/* {
                Object.keys(agentProfileData).length !== 0 && agentProfileData?.AgentDetails[0].UserType !== '' && agentProfileData?.AgentDetails[0].UserType === 'CSMO' &&
                <Grid item xs>
                  <SelectAgent value={team} setValue={setTeam} setPage={setPage}/>
                </Grid>
              } */}
              <Grid item className={classes.searchBoxCol}>
                <FormControl
                  control="input"
                  variant="outlined"
                  label="search by name "
                  placeholder="Name "
                  name="searchLead"
                  type="text"
                  value={value}
                  onChange={handleChange}
                  id="searchLead"
                  className={`${classes.filterInputBox} searchBox`}
                  style={{ borderRadius: 25 }}
                  margin="dense"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {value ?
                          <IconButton
                            edge="end"
                            size="small"
                            title="clear search"
                            onClick={() => handleClear('')}
                          >
                            <CloseIcon />
                          </IconButton>
                          :
                          <IconButton
                            disabled
                            edge="end"
                            size="small"
                          >
                            <SearchOutlinedIcon />
                          </IconButton>}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {/* <Grid item>
                <Typography className={classes.appFilterLabel}><FilterIcon />{t("filterSection.filterTitle")}</Typography>
              </Grid> */}
              {/* <Grid item xs>
                <FormControl
                  control="selectbox"
                  variant="outlined"
                  label={t("filterSection.categoryLabel")}
                  name="applicationCategory"
                  id="applicationCategory"
                  options={filterCategoryData}
                  className={`${classes.filterInputBox} filterInputs`}
                  margin="dense"
                  style={{ maxWidth: values.applicationCategory ? '196px' : '' }}
                />
              </Grid> */}
              {/* <Grid item sm xs={12}>
                  <FormControl
                    control="selectbox"
                    variant="outlined"
                    label={t("filterSection.projectLabel")}
                    name="applicationProject"
                    id="applicationProject"
                    options={filterProjectListData}
                    className={`${classes.filterInputBox} filterInputs`}
                  />
                </Grid> */}
              {/* <Grid item sm xs={12}>
                <FormControl
                  control="selectbox"
                  variant="outlined"
                  label="Filter By Status"
                  name="applicationStatus"
                  id="applicationStatus"
                  options={statusList}
                  className={`${classes.filterInputBox} filterInputs`}
                  disabled={disabledStatus}
                  margin="dense"
                />
              </Grid>
              <Grid item sm={"auto"} xs={12}>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  {t("filterSection.filterBtnText")}
                </Button>
              </Grid> */}
              <Box display='flex' alignItems='center' style={
                { marginRight: 15 }
              }>
                {/* <Grid container alignItems="center">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      id="from-date-picker-dialog"
                      label='From Date'
                      format="MMM-dd-yyyy"
                      value={selectedFromDate}
                      onChange={handleFromDateChange}
                      className={classes.datePickerCont}
                      maxDate={new Date()}
                      invalidDateMessage=""
                      InputLabelProps={{ shrink: true }}
                    />
                    <KeyboardDatePicker
                      id="to-date-picker-dialog"
                      label='To Date'
                      format="MMM-dd-yyyy"
                      value={selectedToDate}
                      onChange={handleToDateChange}
                      className={classes.datePickerCont}
                      maxDate={new Date()}
                      invalidDateMessage=""
                      InputLabelProps={{ shrink: true }}
                    />
                  </MuiPickersUtilsProvider>
                  <Button color="primary" onClick={clearDateFilter} disabled={!(selectedFromDate || selectedToDate)} title="Remove date filter" variant="outlined" style={{ minWidth: 7, padding: 7, marginRight: 10 }}>
                    <ClearIcon />
                  </Button>
                </Grid> */}
                {/* <Grid item style={{ marginRight: 5 }}>
                  <Button variant="outlined" startIcon={<MailOutline />} color="primary" onClick={handleClickOpen}
                    disabled={bankLeadData.applicantWiseData?.length === 0}>
                    Mail
                  </Button>
                </Grid> */}
                {/* <Grid item style={{ marginRight: 5 }}>
                  <Button variant="outlined" startIcon={<GetAppIcon />} color="primary" onClick={download}
                    disabled={bankLeadData.applicantWiseData?.length === 0}>
                    Reports
                  </Button>
                </Grid> */}
                {/* <Grid item>
                  <Badge color="error" badgeContent=" " invisible={!(selectedBank || selectedCategory || isPmay || isPmay == 0 || selfFunding)}>
                    <Button variant="outlined" startIcon={<FilterListIcon />} color="primary" onClick={handleOpenFilterMenu}>
                      Filter
                    </Button>
                  </Badge>
                  <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <Box p={2} width='400px'>
                      <Typography variant="h6" style={{ marginBottom: 10 }}>Apply Filter</Typography>
                      <Grid container direction="column" spacing={2}>
                        <Grid item>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              id="from-date-picker-dialog"
                              label='Booking Date From'
                              format="MMM-dd-yyyy"
                              value={selectedFromDate}
                              onChange={handleFromDateChange}
                              className={classes.datePickerCont}
                              maxDate={new Date()}
                              invalidDateMessage=""
                              InputLabelProps={{ shrink: true }}
                            />
                            <KeyboardDatePicker
                              id="to-date-picker-dialog"
                              label='Booking Date To'
                              format="MMM-dd-yyyy"
                              value={selectedToDate}
                              onChange={handleToDateChange}
                              className={classes.datePickerCont}
                              maxDate={new Date()}
                              invalidDateMessage=""
                              InputLabelProps={{ shrink: true }}
                            />
                          </MuiPickersUtilsProvider>
                     
                        </Grid>
                        <Grid item>
                          <FormControl
                            control="selectbox"
                            variant="outlined"
                            label='Filter by category'
                            name="applicationCategory"
                            id="applicationCategory"
                            options={filterCategoryData}
                            value={selectedCategory}
                            onChange={(e) => setSelectedCategory(e.target.value)}
                            margin="dense"
                          />
                        </Grid>
                        <Grid item>
                          <FormControl
                            control="selectbox"
                            variant="outlined"
                            name="bank"
                            label='Filter by bank intrested'
                            placeholder='Filter by bank intrested'
                            value={selectedBank}
                            onChange={(e) =>
                              setSelectedBank(e.target.value)
                            }
                            margin="dense"
                            options={banksList}
                          // disabled={selfFunding}
                          />
                        </Grid>
                        <Grid item>
                          <MuiFormControl component="fieldset">
                            <FormLabel component="legend">PMAY/NON-PMAY</FormLabel>
                            <RadioGroup style={{ flexDirection: 'row' }} name="isPmay" value={isPmay} onChange={(e) => setIsPmay(e.target.value)}>
                              <FormControlLabel value="1" control={<Radio color="primary" />} label="PMAY" />
                              <FormControlLabel value="0" control={<Radio color="primary" />} label="NON-PMAY" />
                            </RadioGroup>
                          </MuiFormControl>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selfFunding}
                                onChange={(e) => setSelfFunding(e.target.checked)}
                                name="selfFunding"
                                color="primary"
                              />
                            }
                            label="Self Funding customer"
                          />
                        </Grid>
                      </Grid>
                      <Grid container xs={12} spacing={1} justifyContent="flex-end">
                        {(selectedBank || selectedCategory || isPmay || isPmay === 0 || selfFunding || selectedFromDate || selectedToDate) &&
                          <Button
                            size="small"
                            color="primary"
                            type="button"
                            onClick={() => formClearEvent()}>
                            {t("filterSection.clearBtnText")}
                          </Button>
                        }
                      </Grid>
                    </Box>
                  </Popover>
                </Grid> */}
                {/* {(values.searchLead || values.applicationCategory || values.applicationSort || values.applicationStatus) &&
                  <Button
                    size="small"
                    color="primary"
                    type="button"
                    style={{ marginLeft: 8 }}
                    onClick={() => formClearEvent()}>
                    {t("filterSection.clearBtnText")}
                  </Button>
                } */}
              </Box>
            </Grid>
          </Form>
        )}
      </Formik>
      <BankLeadMailDialog open={composeOpen} onClose={() => handleCloseMail()} />
    </Box>
  );
};

export default withWidth()(BankLeadFilter);
