import React, { useEffect } from "react";
import {
  Checkbox,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Slide,
  Snackbar,
} from "@material-ui/core";
import { agentProfileSelector, clearGetAgentData, getAgentList, getRolesForDropdown } from "../../../../redux/features/agent/AgentProfileSlice";
import { useDispatch, useSelector } from "react-redux";
import ClearIcon from '@material-ui/icons/Clear';
import { Alert } from "@material-ui/lab";

function TransitionLeft(props) {
  return <Slide {...props} direction="right" />;
}


const SelectAgent = ({ value, setValue, setPage }) => {
  const [agentList, setAgentList] = React.useState([]);
  const [selectAllAgent, setSelectAllAgent] = React.useState(true);
  const [roles, setRoles] = React.useState("");
  const [roleList, setRoleList] = React.useState([]);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const {
    isFetchingAgentList,
    agentListData,
    agentProfileData,
    isFetchingRoles,
    isSuccessRoles,
    isErrorRoles,
    errorMsgRoles,
    rolesData,
    isSuccessResAgentProfile
  } = useSelector(agentProfileSelector)

  const dispatch = useDispatch();

  const handleClickDisabled = () => {
    if (roles === "") {
      setOpenSnackbar(true);
    }
  };


  useEffect(() => {
    if (isSuccessResAgentProfile) {
      dispatch(getRolesForDropdown(agentProfileData?.AgentDetails[0]?.source));
    }
    return () => {
      dispatch(clearGetAgentData())
    }
  }, [isSuccessResAgentProfile]);

  useEffect(() => {
    if (roles && isSuccessResAgentProfile) {
      const requestData = {
        roles: roles,
        source: agentProfileData?.AgentDetails[0]?.source
      }
      dispatch(getAgentList(requestData));
      setPage(1)
    }
  }, [roles, isSuccessResAgentProfile]);

  useEffect(() => {
    if (isSuccessRoles && rolesData?.length > 0) {
      setRoleList([...rolesData])
    }
  }, [isSuccessRoles])

  // React.useEffect(() => {
  //   if (agentListData.length === 0) {
  //     dispatch(getAgentList());
  //   }
  // }, [agentListData]);

  React.useEffect(() => {

    if (agentListData?.length > 0) {
      setAgentList([{ Agentcode: "All", FirstName: "Select All" }, ...agentListData])
    }
  }, [agentListData])

  const handleChange = (event) => {
    const value = event.target.value;
    setPage(1)
    if (value.includes("All")) {
      if (selectAllAgent) {
        setValue([]);
        setSelectAllAgent(false);
      } else {
        handleSelectAll();
        setSelectAllAgent(true);
      }
    } else {
      setValue(value.filter((option) => option !== "All"));
      setSelectAllAgent(false);
    }
  };

  const handleSelectAll = () => {
    const allValues = agentList
      .filter((option) => option.Agentcode !== "All")
      .map((option) => option.Agentcode);
    setValue(allValues);
  };


  React.useEffect(() => {
    if (agentList.length > 0 && selectAllAgent) {
      handleSelectAll()
    }
  }, [selectAllAgent, agentList])


  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 350,
      },
    },
  };

  const handleChangeRole = (event) => {
    const value = event.target.value;
    setRoles(value);
    setSelectAllAgent(true)
  }

  const clearFilter = () => {
    setAgentList([])
    setValue([]);
    setRoles("")
    setSelectAllAgent(true);
    setPage(1)
  }

  return (
    <>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
        TransitionComponent={TransitionLeft}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setOpenSnackbar(false)}
          severity="warning"
        >
          Please select a role first
        </Alert>
      </Snackbar>
      <FormControl variant="outlined" size="small" style={{ marginBottom: 8 }}>
        <InputLabel id="roles-label">Select Role</InputLabel>
        <Select
          labelId="roles"
          id="roles"
          value={roles}
          onChange={handleChangeRole}
          label="Select Role"
          style={{ width: "180px", marginRight: 10 }}
          color="primary"
          endAdornment={
            roles && (
              <InputAdornment position="end">
                <IconButton title='Clear Filter' onClick={clearFilter}>
                  <ClearIcon
                    color="primary"
                  />
                </IconButton>
              </InputAdornment>
            )
          }
        >
          {roleList.map((role, i) => (
            <MenuItem key={i} value={role.value}>
              {`${role.label} ${role?.count ? `(${role?.count})` : ""}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl>
        <InputLabel id="demo-mutiple-checkbox-label">Select Account</InputLabel>
        <Select
          labelId="demo-mutiple-checkbox-label"
          id="demo-mutiple-checkbox"
          multiple
          value={value}
          onChange={handleChange}
          input={<Input />}
          renderValue={(selected) => {
            const extractedNames = agentList
              .filter((agentData) => selected.includes(agentData.Agentcode))
              .map((agentData) => agentData.FirstName);

            return extractedNames.join(", ");
          }}
          MenuProps={MenuProps}
          style={{ width: "180px", marginRight: 10 }}
          color="primary"
          onClick={handleClickDisabled}
          disabled={roles === ""}
        >
          {agentList.map((agent, i) => (
            <MenuItem
              key={i}
              value={agent.Agentcode}
              style={{ backgroundColor: agent.Agentcode === "All" ? "blue" : "" }}
            >
              <Checkbox
                color="primary"
                style={{ color: agent.Agentcode === "All" ? "white" : "" }}
                checked={
                  agent.Agentcode === "All"
                    ? selectAllAgent
                    : value.indexOf(agent.Agentcode) > -1
                }
              />
              <ListItemText
                style={{ color: agent.Agentcode === "All" ? "white" : "" }}
                primary={
                  <span
                    style={{
                      fontWeight: agent.Agentcode === "All" ? "bold" : "",
                    }}
                  >
                    {(agent.Agentcode === "All" ? "" : ` ${agent.Agentcode} - `) +
                      `${agent.FirstName}` + (agent.Agentcode === 'All' ? '' : ` (${agent?.TotalLeads})`)}
                  </span>
                }
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default SelectAgent;
