import React from "react";
import Layout from "../Layout/Layout";
import MyDocuments from "../../organisms/ProfilePageComponents/MyDocuments/MyDocuments";
import ProfileLayout from "../Layout/ProfileLayout";

function MyDocumentsPage() {
    return (
        <ProfileLayout isStepper={false} noScrollIs={true}>
            <MyDocuments />
        </ProfileLayout>
    );
}

export default MyDocumentsPage;
