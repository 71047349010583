import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import { FormControlLabel, Checkbox, TextField, Grid, CircularProgress, Box, Typography, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { initialPagesStyles } from '../../InitialPagesComponents/InitialPages.styles';
import { useDispatch, useSelector } from 'react-redux';
import { clearImageUrl, fileDataUpload, fileUploadSelector } from '../../../../redux/features/file/FileUploadSlice';
import { SupportedFormats } from '../../../../utils/Common';
import UploadedKyc from "../../../../assets/UploadedKyc.svg";
import { FileUploadIcon } from '../../../atoms/SvgIcons/SvgIcons';
import { getSummaryDetails, LeeseeDetailed, PmayNonPmaySelector } from '../../../../redux/features/pmayNonPmay/pmayNonPmaySlice';
import { RegistrationStepperSave } from '../../../../redux/features/registration/registrationStepperSlice';
import AlertBox from '../../../atoms/AlertBox/AlertBox';
import * as Yup from 'yup';


const LesseeDetails = (props) => {
    const { active, data } = props;
    const { t } = useTranslation("InitialPageTrans");
    const classes = initialPagesStyles();
    const dispatch = useDispatch();

    const [uploadingField, setUploadingField] = useState('');
    const [isUploaded, setUploaded] = useState(false);

    const fileInputRefA = useRef(null);
    const [isImageA, setIsImageA] = useState(false);
    const [approvedPlanAUrl, setApprovedPlanAUrl] = useState('');
    const [approvalPlanAPreview, setApprovalPlanAPreview] = useState(null);
    const { summaryData, isSuccessResSummary } = useSelector(PmayNonPmaySelector);

    const {
        isFileFetching,
        isFileSuccess,
        imageUrl,
        isFileError,
        fileErrorMessage,
    } = useSelector(fileUploadSelector);
    const { isFetchingLeeseeDetail,
        isSuccessLeeseeDetail ,isErrorLeeseeDetail,
        errorMsgLeeseeDetail,} = useSelector(PmayNonPmaySelector)
   
   
        const getInitialValue = (field) => {
            return summaryData ? summaryData[0]?.[field] : data?.[field] || '';
          };
        const initialValues = {
        lessee_name: getInitialValue('lessee_name'),
        sub_lessee_name: getInitialValue('sub_lessee_name'),
        lessee_society_form: getInitialValue('lessee_society_form'),
        lessee_certificate_no: getInitialValue('lessee_certificate_no'),
        lessee_share_certificate_doc: data?.lessee_share_certificate_doc || '',
        lessee_pan_no:getInitialValue('lessee_pan_no')
    };

    const validationSchema = Yup.object({
        lessee_name: Yup.string().required('Lessee name is required'),
       
        lessee_certificate_no: Yup.string().required('Certificate No. is required'),
        lessee_pan_no: Yup.string().required('PAN Card No. is required'),
        lessee_share_certificate_doc: Yup.string().required('Share certificate is required'),
        lessee_society_form: Yup.boolean(),
    });
    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize:true,
        onSubmit: values => {
            // Handle form submission logic here
            const data = {
                ...values,
                lessee_share_certificate_doc: approvedPlanAUrl,
                lessee_society_form: formik.values.lessee_society_form ? 'yes' : 'no',
            }
            console.log(data,"data");
         dispatch(LeeseeDetailed(data));
        },
    });
    useEffect(() => {
        dispatch(getSummaryDetails());
        dispatch(clearImageUrl());
    }, [])

    function hasImageExtension(url) {
        if (!url) {
            return false;
          }
        const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webp', '.tiff'];
        return imageExtensions.some(extension => url.toLowerCase().endsWith(extension));
      }
      
      // Usage
      
      
    useEffect(()=>{
      if(isSuccessResSummary && summaryData)
      {
        const proApprovedPlanDocA = summaryData?.DocumentDetails?.lessee_share_certificate_doc;
      
        formik.setFieldValue( "lessee_share_certificate_doc",proApprovedPlanDocA)
       
        if (hasImageExtension(proApprovedPlanDocA)) {
         setIsImageA(true)
         setApprovalPlanAPreview(proApprovedPlanDocA);
        } else {
         setIsImageA(false)
        }
       
         
    
      
      }
    
    },[isSuccessResSummary,summaryData])

    useEffect(() => {
        if (isSuccessLeeseeDetail) {
          
            dispatch(RegistrationStepperSave("3"))
        }
    }, [isSuccessLeeseeDetail]);

    const onReUpload = (fieldName) => {
        if (fieldName === 'lessee_share_certificate_doc' && fileInputRefA.current) {
            fileInputRefA.current.click();
        }
    };

    useEffect(() => {
        if (isFileSuccess && imageUrl) {
            if (uploadingField === 'lessee_share_certificate_doc') {
                setApprovedPlanAUrl(imageUrl);
                dispatch(clearImageUrl());
            }
            setUploadingField('');
        }
    }, [isFileSuccess, imageUrl, uploadingField, dispatch]);

    const handleFileChange = (event, fieldName) => {
        const file = event.currentTarget.files[0];
        if (file) {
            setUploadingField(fieldName);
            console.log(file, "filedasdffsf")
            formik.setFieldValue("lessee_share_certificate_doc", file.name);
            const data = { doc: file, docName: fieldName };
            dispatch(fileDataUpload(data));
            if (fieldName === 'lessee_share_certificate_doc') {
                if (SupportedFormats.DocsFormats.includes(file.type)) {
                    switch (file.type) {
                        case "image/jpeg":
                        case "image/png":
                            setIsImageA(true);
                            break;
                        case "application/pdf":
                        default:
                            setIsImageA(false);
                    }
                }
                setApprovalPlanAPreview(URL.createObjectURL(file));

            }
        }
    };

    useEffect(() => {
        if (data?.lessee_share_certificate_doc) {
            setUploaded(true);
        }
    }, [data]);

    const handleCheckboxChange = (event) => {
        const { checked, name } = event.target;
        formik.setFieldValue(name, checked);  // Directly set boolean value
    };

    useEffect(() => {
        if (isFileSuccess) {
            setUploaded(true);
        }
    }, [isFileSuccess]);

    return (
        <>
         {isErrorLeeseeDetail && (
                  <AlertBox severity="error">{errorMsgLeeseeDetail}</AlertBox>
                )}
                  { isFileError && (
                  <AlertBox severity="error">{fileErrorMessage}</AlertBox>
                )}
       
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        id="lessee_name"
                        name="lessee_name"
                        label="Lessee"
                        value={formik.values.lessee_name}
                        onChange={formik.handleChange}
                        error={formik.touched.lessee_name && Boolean(formik.errors.lessee_name)}
                        helperText={formik.touched.lessee_name && formik.errors.lessee_name}
                        required
                        variant="outlined"
                        margin="dense"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        id="sub_lessee_name"
                        name="sub_lessee_name"
                        label="Sub-Lessee Mortgagor (If Applicable)"
                        value={formik.values.sub_lessee_name}
                        onChange={formik.handleChange}
                        variant="outlined"
                        margin="dense"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        id="lessee_certificate_no"
                        name="lessee_certificate_no"
                        label="Lessee Certificate No."
                        inputProps={{
                            maxLength: 20,
                          }}
                        value={formik.values.lessee_certificate_no}
                        error={formik.touched.lessee_certificate_no && Boolean(formik.errors.lessee_certificate_no)}
                        helperText={formik.touched.lessee_certificate_no && formik.errors.lessee_certificate_no}
                       required
                        onChange={formik.handleChange}
                        variant="outlined"
                        margin="dense"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        id="lessee_pan_no"
                        name="lessee_pan_no"
                        label="Leese PAN Card No."
                        value={formik.values.lessee_pan_no}
                        onChange={formik.handleChange}
                        variant="outlined"
                        margin="dense"
                        required
                        inputProps={{
                          maxLength: 10,
                        }}
                        autoFocus={true}
                        onInput={(e) =>
                          (e.target.value = ("" + e.target.value).toUpperCase())
                        }
                        error={formik.touched.lessee_pan_no && Boolean(formik.errors.lessee_pan_no)}
                            helperText={formik.touched.lessee_pan_no && formik.errors.lessee_pan_no}
                    />
                </Grid>
                <Grid item xs={6}>
                    <input
                        ref={fileInputRefA}
                        style={{ display: 'none' }}
                        type="file"
                        accept="image/*, application/pdf"
                        id="lessee_share_certificate_doc"
                        name="lessee_share_certificate_doc"
                        onChange={(event) => handleFileChange(event, 'lessee_share_certificate_doc')}
                    />
                    <TextField
                        fullWidth
                        label='Upload Lessee Share Certificate'
                        variant="outlined"
                        margin="dense"
                        disabled
                        value={formik.values.lessee_share_certificate_doc}
                        
                        InputProps={{
                            endAdornment: (
                                <Button
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                    onClick={() => fileInputRefA.current.click()}
                                >
                                    Browse
                                </Button>
                            ),
                        }}
                    />
                    {approvalPlanAPreview && (
                        <Box>
                            <Typography className={classes.kycCaption} variant="subtitle1">
                                Preview Lessee Share Certificate
                            </Typography>
                            {isImageA ? (
                                <Box className={classes.panCardPreviewCard}>
                                    <img
                                        className={classes.panPreviewImg}
                                        src={approvalPlanAPreview}
                                        alt="uploaded successfully"
                                    />
                                </Box>
                            ) : (
                                <Box textAlign="center" className={classes.panCardPreviewCard}>
                                    <img className={classes.panPreviewImg} src={UploadedKyc} alt="uploaded successfully" />
                                    <Typography className={classes.kycCaption}>
                                        {t("kycBank.formControl.upload.success")}
                                    </Typography>
                                </Box>
                            )}
                            <Box textAlign="left" marginTop={2}>
                                <Typography className={classes.kycCaption} variant="subtitle1">
                                    Uploaded Lessee Share Certificate
                                </Typography>
                            </Box>
                            <Box className={classes.fileViewArea}>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item xs="auto">
                                        <FileUploadIcon color="primary" />
                                    </Grid>
                                    <Grid item xs zeroMinWidth>
                                        <Typography variant="body2" className={classes.fileUrlPreview}>
                                            {approvedPlanAUrl || "--"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs="auto">
                                        <Button
                                            variant="text"
                                            size="small"
                                            color="primary"
                                            onClick={() => onReUpload('lessee_share_certificate_doc')}
                                        >
                                            {t("kycBank.formControl.upload.reupload")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    )}
                </Grid>
                <Grid item xs={6}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="lessee_society_form"
                                name="lessee_society_form"
                                checked={formik.values.lessee_society_form}
                                onChange={handleCheckboxChange}
                            />
                        }
                        label="Society is formed"
                        margin="dense"
                        
                    />
                     {formik.touched.lessee_society_form && formik.errors.lessee_society_form && (
                            <Typography color="error" variant="body2">
                                {formik.errors.lessee_society_form}
                            </Typography>
                        )}
                </Grid>
            </Grid>
            <Grid container alignItems="flex-end" style={{ marginTop: 5 }} justifyContent="flex-end">
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    disabled={isFetchingLeeseeDetail}
                >
                    {isFetchingLeeseeDetail && <CircularProgress size={20} style={{ marginRight: "10px" }} />}
                    {isFetchingLeeseeDetail && 'Saving...'}
                    {!isFetchingLeeseeDetail && 'Save & Continue'}
                </Button>
            </Grid>
        </form>
        </>
    );
};

export default LesseeDetails;
