import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Typography,
} from "@material-ui/core";
import React from "react";
import { DownloadPrimaryIcon } from "../../../atoms/SvgIcons/SvgIcons";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    dialogBoxTitle: {
        padding: theme.spacing(1.5),
        display: "flex",
        justifyContent: "space-between",
        "& .MuiDialogTitle-root": {
            padding: 0,
        },
        "& h2": {
            color: "#007AE7",
            fontSize: "1.5rem",
            fontWeight: "bold",
        },
    },
}));

export default function BankLeadDocumentDialog(props) {
    const { open, data, onClose } = props;
    const classes = useStyles();

    const documentMockData = [
        {
            docName: "Aadhaar card",
            url: "https://research.google.com/pubs/archive/44678.pdf",
            type: "pdf",
        },
        {
            docName: "PAN card",
            url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrdTMW7HPUopz4MBK6oNs7cg3g055l65ZDmQ&usqp=CAU",
            type: "image",
        },
    ];

    const downloadDoc = (doc) => {
        window.open(doc.url);
    };

    return (
        <>
            <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
                <DialogTitle style={{ padding: 0 }}>
                    <Grid className={classes.dialogBoxTitle}>
                        <Grid item>
                            {data.name && (
                                <Typography variant="body2">
                                    Name : <strong>{data.name}</strong>
                                </Typography>
                            )}
                            {data.mobileNo && (
                                <Typography variant="body2">
                                    Mobile No. : <strong>{data.mobileNo}</strong>
                                </Typography>
                            )}
                        </Grid>
                        <DialogTitle id="alert-dialog-title">
                            Customer Documents
                        </DialogTitle>
                        <IconButton onClick={onClose} size="samll">
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container justifyContent="space-between" alignItems="center">
                        {documentMockData.map((doc) => (
                            <Grid xs={5}>
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <strong>{doc.docName}</strong>
                                    <Button
                                        size="small"
                                        startIcon={<DownloadPrimaryIcon />}
                                        color="primary"
                                        onClick={() => downloadDoc(doc)}
                                    >
                                        Download
                                    </Button>
                                </Box>
                                {doc.type === "image" ? (
                                    <img src={doc.url} alt="document" height="250px" />
                                ) : (
                                    <iframe
                                        src={`${doc.url}#toolbar=0`}
                                        width="100%"
                                        height="250px"
                                        type="application/pdf"
                                        title="document pdf"
                                    />
                                )}
                            </Grid>
                        ))}
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
}
