import React from "react";
import ProfileLayout from "../Layout/ProfileLayout";
import MyAllotmentLetter from "../../organisms/ProfilePageComponents/MyAllotmentLetter/MyAllotmentLetter";

function MyAllotmentLetterPage() {
    return (
        <ProfileLayout isStepper={false} noScrollIs={true}>
            <MyAllotmentLetter />
        </ProfileLayout>
    );
}

export default MyAllotmentLetterPage;
