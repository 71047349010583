import React, { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import withWidth from "@material-ui/core/withWidth";
import Typography from "@material-ui/core/Typography";
import FormCard from "../../../molecules/Cards/FormCard/FormCard";
import ImportantDates from "../ImportantDates/ImportantDates";
import RecentPosts from "../RecentPosts/RecentPosts";
import Notifications from "../Notifications/Notifications";
import UserProjects from "../UserProjects/UserProjects";
import Loading from "../../../atoms/Loading/Loading";
import AlertBox from "../../../atoms/AlertBox/AlertBox";
import { DashboardStyles } from "./Dashboard.styles";
import {
  getApplicant,
  applicantSelector,
  clearApplicantState,
} from "../../../../redux/features/applicant/ApplicantSlice";
import {
  getReservationCategories,
  masterDataSelector,
} from "../../../../redux/features/masterdata/MasterDataSlice";
import {
  docDeclarationSelector,
  getDocumentsList,
  getUploadDocumentsList,
  clearDocDeclarationState,
  clearDocDeclarationtData
} from "../../../../redux/features/file/DocDeclarationSlice";
import { getStepperDetails } from "../../../../redux/features/stepper/StepperSlice";
import { ApplicantProgressSelector } from "../../../../redux/features/ApplicantStepper/ApplicantStepperSlice";

const Dashboard = (props) => {
  const { width } = props;
  const { t } = useTranslation("DashboardPageTrans");
  const classes = DashboardStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [documentUploaded, setDocumentUploaded] = useState([]);
  // const [isPendingDocUpload, setIsPendingDocUpload] = useState(false);
  const {
    applicantData,
    isSuccessResApplicantGet,
    isFetchingApplicantGet,
    isErrorApplicantGet,
    errorMessageGet
  } = useSelector(applicantSelector);

  const { isFetchingStepper } = useSelector(ApplicantProgressSelector);
  const {
    isFetchingGetDocsList,
    isSuccessResGetDocsList,
    isErrorGetDocsList,
    errorMsgGetDocsList,
    getDocsListData
  } = useSelector(docDeclarationSelector);

  const {
    isFetchingGetUploadList,
    isSuccessResUploadList,
    isErrorGetUploadList,
    errorMsgGetUploadList,
    getUploadListData,
  } = useSelector(docDeclarationSelector);

  const { allCategory, castCategory } = useSelector(masterDataSelector);
  const { stepperData, isSuccessResStepper } = useSelector(
    (state) => state.stepper
  );

  useEffect(() => {
   // dispatch(getStepperDetails());
    dispatch(getReservationCategories())
    dispatch(getApplicant());
    let sendData = {
      ApplicantId: localStorage.getItem("applicantId"),
      Lang: localStorage.getItem("i18nextLng"),
    };
    dispatch(getUploadDocumentsList(sendData))
  }, [dispatch, t]);




  useEffect(() => {
    if (isSuccessResApplicantGet && applicantData) {
      let sendData = {
        ApplicantId: applicantData.ApplicantId,
        CasteCatId: applicantData.CasteCatId,
        ReservationCatIds: applicantData.RservationCatIds,
        IncomeGroup: applicantData.IncomeGroup,
      };
      // dispatch(getDocumentsList(sendData));
    }
  }, [isSuccessResApplicantGet, applicantData]);

  useEffect(() => {
    if (isSuccessResUploadList && getUploadListData) {
      setDocumentUploaded(getUploadListData.DocumentDetails);
    }
  }, [isSuccessResUploadList, getUploadListData]);





  return (
    <div className={classes.mainRoot}>
      {(isFetchingApplicantGet || isFetchingGetDocsList || isFetchingStepper) && <Loading isOpen={isFetchingApplicantGet || isFetchingGetDocsList || isFetchingStepper} />}
      {/* <Typography variant="h4" className={classes.pageInfoText}>{t("dashboard.titleTxt1")} <strong>{applicantData?.FirstName}<Hidden smDown>,</Hidden></strong> {t("dashboard.titleTxt2")}</Typography> */}
      <FormCard>
        {isErrorApplicantGet && (
          <AlertBox severity="error">{errorMessageGet}</AlertBox>
        )}
        <Box className={classes.topTabSection}>
          {/* isPendingDocUpload={isPendingDocUpload} */}
          <UserProjects applicantData={applicantData} reservationCategory={allCategory} doclist={documentUploaded} isDataSuccess={isSuccessResApplicantGet} />
        </Box>
        {/* <Box className={classes.latestUpdatesSection}>
          <Typography variant="h4" className={classes.sectionTitle}>{t("dashboard.latestUpdatesTitleTxt")}</Typography>
          <Grid container>
            <Grid item md={8} className={classes.impDatesSec}>
              <ImportantDates />
            </Grid>
            <Grid item md={4}>
              <Notifications />
            </Grid>
          </Grid>
        </Box> */}
        {/* <Box className={classes.recentPostsSection}>
          <Typography variant="h4" className={`${classes.sectionTitle} rcntPost`}>{t("dashboard.recentPostTitleTxt")}</Typography>
          <RecentPosts />
        </Box> */}
      </FormCard>
    </div>
  );
};

export default withWidth()(Dashboard);
