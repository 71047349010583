

import React, { createRef, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import DateRangeIcon from "@material-ui/icons/DateRange";
import RefreshIcon from '@material-ui/icons/Refresh';
import {
  Stepper,
  Step,
  StepLabel,
  Card,
  Box,
  Typography,
  CardContent,
  Button,
  Divider,
  Popover,
  CircularProgress,
  ListItem,
  Grid,
  List,
  Avatar,
  withStyles, Collapse, Chip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton,
  CardHeader
} from "@material-ui/core";
import {
  ColorlibConnector,
  useColorlibStepIconStyles,
  useStyles,
} from "./UserProgressStepperStyles";
import { getSteps, icons, checkicons } from "./mockdata";
import { useHistory } from "react-router-dom";

import CheckIcon from "@material-ui/icons/Check";

import {
  addEditApplicantProgress, ApplicantProgressSelector, ApplicantStepperActiveStep, clearApplicantStepper, getApplicantProgress
} from "../../../../../redux/features/ApplicantStepper/ApplicantStepperSlice";
import { applicantSelector, clearApplicantState, getApplicant } from "../../../../../redux/features/applicant/ApplicantSlice";

import { useTranslation } from "react-i18next";
import { Alert, AlertTitle } from "@material-ui/lab";
import WarningIcon from '@material-ui/icons/Warning';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Loading from "../../../../atoms/Loading/Loading";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { VerifiedDocIconGreen } from "../../../../atoms/SvgIcons/SvgIcons";
import { getChallanDetails, PmayNonPmaySelector } from "../../../../../redux/features/pmayNonPmay/pmayNonPmaySlice";
import { clearImageUrl } from "../../../../../redux/features/file/FileUploadSlice";
import { ApiEndPoint } from "../../../../../utils/Common";
import moment from "moment";
import barcode from '../../../../../assets/barcode.png';
import GetAppIcon from '@material-ui/icons/GetApp';


const ColorButton = withStyles((theme) => ({
  root: {
    color: "white",
    backgroundColor: "#fa8231",
    '&:hover': {
      backgroundColor: "#d35400",
    },
  },
}))(Button);

const UserProgressStepper = (props) => {
  const { doclist, reservationCategory, savedProjectsGroupList } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("Apply New");
  const sectionRefs = useRef([]);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(location.search);
  const step = queryParams.get('step');
  const { t } = useTranslation("DashboardPageTrans");
  const [openSection, setOpenSection] = useState(step || 1);
  const [pdfLoading, setPdfLoading] = useState(false);

  useEffect(() => {
    if (step) {
      setOpenSection(step);
    }
  }, [step]);
  useEffect(() => {
    dispatch(getChallanDetails());
    dispatch(clearImageUrl());
  }, [])

  const downloadPdf = (FireApplicationId) => {
    setPdfLoading(true);
    let fileUrl = ApiEndPoint + "/FireNoc/downloadNoc/" + FireApplicationId

    fetch(fileUrl, {
      method: "GET",
      headers: {
        Authorization: localStorage.getItem("jwtToken"),
      },
    }).then((response) => response.blob()).then((blob) => {
      setPdfLoading(false);
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      let today = new Date();
      const dateTime = moment(new Date(today.getFullYear(), today.getMonth() + 1, 0)).format('YYYY-MM-DD-h:mm');
      link.download = `${localStorage.getItem("applicantId")} -${dateTime}`;
      document.body.append(link);
      link.click();
      link.remove();
      // in case the Blob uses a lot of memory
      setTimeout(() => URL.revokeObjectURL(link.href), 300);
    }).catch(function (error) {
      setPdfLoading(false);
    });
  };

  
  useEffect(() => {
    if (step && sectionRefs.current[step - 1]) {
      sectionRefs.current[step - 1].current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'center'
      });
    }
  }, [step]);

  const handleToggle = (section) => {
    setOpenSection((prevOpenSection) => (prevOpenSection === section ? null : section));
    const activeSectionRef = sectionRefs.current[section - 1];
    if (activeSectionRef && activeSectionRef.current) {
      activeSectionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
    }
  };

  useEffect(() => {
    sectionRefs.current = Array(10).fill().map((_, i) => sectionRefs.current[i] || createRef());
  }, []);

  const preventCollapseClosing = (event) => {
    event.stopPropagation();
  };


  const {
    isSuccessResApplicantGet,
    isFetchingApplicantGet,
    isErrorApplicantGet,
    errorMessageGet,
    applicantData
  } = useSelector(applicantSelector);
  const { challanData, isSuccessResChallan } = useSelector(PmayNonPmaySelector);
  const { ApplicantStepperData, isSuccessProgressResStepper, LastUpdatedDate, superActiveStep, superStepper, documentPostVerificationData, documentPreVerificationData } = useSelector(ApplicantProgressSelector);

  return (
    <div className={classes.mainRoot}>
      <Grid container xs={12} className={classes.secTitle} justifyContent="space-between">
        <Grid item>
          <Typography variant="h4" className={classes.secText} >{t('dashboard.myApplicationTxt')} </Typography>
          <span>{t('dashboard.trackAppTxt')}</span>
        </Grid>
        <Grid className={classes.updatedDate}>
          <DateRangeIcon />
          <Typography style={{ fontWeight: "800" }}>
            <span>{t('dashboard.lastUpdateTxt')} </span>{LastUpdatedDate}
          </Typography>
        </Grid>
      </Grid>

      <Card className={classes.applicantCard} elevation={0} >
        <Grid container justifyContent="space-between" alignItems="center" direction="row" style={{ padding: "10px", }}>
          {applicantData && <Grid container xs={6} alignItems="center">

            <Grid item style={{ marginLeft: "10px" }}>
              <Typography className={classes.applicantName}>{applicantData.FirstName}</Typography>
              <span style={{ color: "#65707D", fontSize: "12px" }}>{'Mobile'}: 9897465743 |</span>
              <span style={{ color: "#65707D", fontSize: "12px" }}> Email : Kam@gmail.com </span>
            </Grid>
            {(applicantData.ApplicationNumBarcode && applicantData.ApplicationNo) && <>
              <Divider orientation="vertical" flexItem variant="middle" />
              <Grid item style={{ maxWidth: 115 }}>
                <Typography style={{ fontSize: "0.8rem", lineHeight: 1.2, marginLeft: 10 }}>{t("userjourney.ApplNoLbl")}</Typography>
                {/* <img src={applicantData.ApplicationNumBarcode} alt="barcode" /> */}
                <img width={150} height={100} src={barcode} alt='barcode' />
                <Typography style={{ fontSize: "0.8rem", lineHeight: 0.8, marginLeft: 12, letterSpacing: "1.5px" }}>{applicantData.ApplicationNo}</Typography>
              </Grid></>}

          </Grid>}
          <Grid item xs={6}>

            <ColorButton
              varient="contained"
              onClick={() => history.push("/add-new")}
              className={classes.continueBtn}
            >
              {title}
            </ColorButton>

          </Grid>
        </Grid>
      </Card>

      {isSuccessResChallan && challanData?.map((item, index) => (
        <Card key={index} className={classes.card1}>
          {item.challan_expired === 0 && <Alert elevation={1} style={{ marginBottom: 12 }} className={classes.alert} severity="warning" action={
            <Button color="inherit" variant="outlined" size="small" onClick={() => history.push('/upload-challanreceipt')}>
              {t("Upload")}
            </Button>}>
            <AlertTitle>
              Please download the challan and pay the amount before the due date. Upload the bank receipt after payment.
            </AlertTitle>

          </Alert>}

          {item.noc_generated == "1" && <Alert elevation={1} style={{ marginBottom: 12 }} severity="success" action={
            <Button
              color="inherit"
              variant="outlined"
              size="small"
              onClick={() => downloadPdf(item.FireApplicationId)}
            >
              {t("DownLoad NOC")}
            </Button>}>
            <AlertTitle>
              Fire NOC Generated SuccessFully . You Can View And Download Your Fire NOC Now
            </AlertTitle>

          </Alert>}

          <CardHeader className={classes.collapseButton} onClick={() => handleToggle(index + 1)}
            title={
              <Typography variant="subtitle1" className={classes.cardTitle}>

                <Typography style={{ fontSize: "0.9rem", lineHeight: 1.2, marginLeft: 8 }}>App no: {item?.ApplicationNo}</Typography>
                <img width={150} height={75} src={barcode} alt='barcode' />
              </Typography>
            }
            action={<div>


              <Chip
                color="secondary"
                className={`${classes.progressChip} done`}
                label={`Applied On : ${item.added_date}`}
                icon={<VerifiedDocIconGreen />}
              />


              <IconButton>
                {openSection === index + 1 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </div>}
          />
          <Collapse in={openSection === index + 1} timeout="auto" unmountOnExit>
            <Card className={classes.card}>
              <TableContainer component={Paper}>
                <Table className={classes.table}>
                  <TableHead style={{ backgroundColor: "rgb(29 255 113 / 45%) !important"  }}>
                    <TableRow >
                      <TableCell className={classes.tableHeader}>NOC Type</TableCell>
                      <TableCell className={classes.tableHeader}>Allotee Name</TableCell>
                      <TableCell className={classes.tableHeader}>CC Date</TableCell>
                      <TableCell className={classes.tableHeader}>OC Date</TableCell>
                      <TableCell className={classes.tableHeader}>Node </TableCell>
                      <TableCell className={classes.tableHeader}>Sector</TableCell>
                      <TableCell className={classes.tableHeader}>Plot No</TableCell>
                      <TableCell className={classes.tableHeader}>Plot Area</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>{item.property_type}</TableCell>
                      <TableCell>{item.allottee_name}</TableCell>
                      <TableCell>
                        {item.commencement_date}
                      </TableCell>
                      <TableCell>{item.occupancy_date}</TableCell>
                      <TableCell>
                        {item.pro_node}
                      </TableCell>
                      <TableCell>
                        {item.pro_sector}
                      </TableCell>
                      <TableCell>
                        {item.pro_plot_no}
                      </TableCell>
                      <TableCell>
                        {item.pro_plot_area}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Divider/>
              {item.challanFile && item.challanFile !== "" && (
                <TableContainer component={Paper}>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                
                        <TableCell className={classes.tableHeader}>Payable Amount</TableCell>
                        <TableCell className={classes.tableHeader}>Download Challan</TableCell>
                        <TableCell className={classes.tableHeader}>Due Date</TableCell>
                        <TableCell className={classes.tableHeader}>Status</TableCell>
                        <TableCell className={classes.tableHeader}>Remark</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        
                        <TableCell>{item.challan_amount_due}</TableCell>
                        <TableCell>
                      
                          <Button
                
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => {
                    window.open(item.challanFile, '_blank');
                  }}
                  endIcon={<GetAppIcon  />}
                >
                 DownLoad Challan
                </Button>
                        </TableCell>
                        <TableCell>{item.challan_amount_due_date}</TableCell>
                        <TableCell>
                          {item.challan_expired === 0 ? "Challan Payment Due" : item.challan_expired === 1 ? "Challan Expired" : "Paid"}
                        </TableCell>
                        <TableCell>
                          {item.challan_receipt_uploaded === "0" ? "Upload Challan Receipt" : item.challan_receipt_uploaded === "1" ? "Receipt Uploaded" : ""}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              )}

            </Card>
          </Collapse>
        </Card>
      ))}


    </div>
  );
};



export default UserProgressStepper;

