import React from "react";
import AgentDashboard from "../../organisms/AgentDashboardPageComponents/AgentDashboard/AgentDashboard";
import AgentLayout from "../AgentLayout/AgentLayout";

const AgentDashboardPage = () => {
  return (
    <AgentLayout>
      <AgentDashboard />
    </AgentLayout>
  );
};

export default AgentDashboardPage;
