import React from "react";
import Layout from "../Layout/Layout";
import AddNew from "../../organisms/SchedulerPageComponents/AddNew";


function AddNewPage() {
    return (
        <Layout isStepper={false} noScrollIs={true}>
         <AddNew/>
        </Layout>
    );
}

export default AddNewPage;
